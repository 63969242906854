import { Router } from '@angular/router';
import { UpdateStatusService } from '../../services/update-status.service';
import { GenericCRUDService } from '../../services/generic.service';
import { ToastLuncherService } from '../../services/toast-luncher.nb.service';
import { IfeatureItem, IUpdatablefeatureItem } from '../interface/IfeatureItem';
import { UpdateModeTypes, UpdateTypes } from '../types';
import * as $ from 'jquery';
import { NgxImageCompressService } from 'ngx-image-compress';
import { areValidImages } from '../../lib/functions';
import { ACCEPTED_IMAGES } from '../../config';
export class GenericCreateFeature<T extends IfeatureItem> {
  ACCEPTED_IMAGES = ACCEPTED_IMAGES;

  mode: UpdateModeTypes;
  isLoading: boolean = false;
  item: T = null;

  constructor(
    protected featureName: string,
    protected _toastLuncher: ToastLuncherService,
    protected _service: GenericCRUDService<T>,
    protected router: Router,
  ) {}

  protected formIsValid(): boolean {
    return true;
  }

  protected beforeSubmit(item: T): T {
    return item;
  }

  submit() {
    if (!this.formIsValid()) return;
    this.isLoading = true;
    this._service.post(this.beforeSubmit(this.item)).subscribe(
      (data) => {
        this.isLoading = false;
        this._toastLuncher.success('تمت الإضافة بنجاح !');
        this.router.navigate([`/pages${this.featureName}/liste`]);
      },
      (error) => {
        this.isLoading = false;
        if (error.status === 400) throw new Error('الرجاء التثبت من المعطيات المدرجة');
        if (error.status === 409) throw new Error('يوجد ملف اخر يحمل نفس المعطيات');
        if (error.status === 500) throw new Error('لم نتمكن من الاتصال بالمنصة، ارجاء المحاولة لاحقاً.');
        else throw new Error('لم نتمكن من الإضافة');
      },
    );
  }
}

export class GenericCreateWithImage<T extends IfeatureItem> extends GenericCreateFeature<T> {
  hasImage = false;
  isFileSizePassed = true;

  constructor(
    protected featureName: string,
    protected _toastLuncher: ToastLuncherService,
    protected _service: GenericCRUDService<T>,
    protected router: Router,
    protected imageCompress: NgxImageCompressService,
  ) {
    super(featureName, _toastLuncher, _service, router);
  }

  onChangeImagefile({ target: { files } }, ...args) {
    const fileError = areValidImages(files);
    if (fileError.error) throw new Error(fileError.errorMessage);

    const base = this;
    [].forEach.call(files, function (file) {
      base.handelFile(file);
    });
  }

  handelFile(file: any, ...args) {
    this.hasImage = false;
    const reader2 = new FileReader();
    let src;
    reader2.onload = (e) => {
      src = reader2.result;
      this.imageCompress
        .compressFile(src, 75, 75) // 75% ratio, 75% quality
        .then((compressedImage) => {
          this.onImageLoad(e, compressedImage, ...args);
        });
    };
    if (file.size < 5300000 * 2) {
      this.isFileSizePassed = true;
      reader2.readAsDataURL(file);
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = false;
      throw new Error('الرجاء ادخال صورة لا تتجاوز 10MB');
    }
  }

  import() {
    document.getElementById('file_to_upload').click();
  }

  onImageLoad($event, compressedImage, ...args) {
    undefined;
  }
}

export class GenericCreateWithFirstUpdate<T extends IUpdatablefeatureItem> extends GenericCreateFeature<T> {
  constructor(
    protected featureName: string,
    protected _toastLuncher: ToastLuncherService,
    protected _service: GenericCRUDService<T>,
    protected router: Router,
    protected updateStatusService: UpdateStatusService,
  ) {
    super(featureName, _toastLuncher, _service, router);
  }

  submit() {
    if (!this.formIsValid()) return;
    this.isLoading = true;
    this._service.post(this.beforeSubmit(this.item)).subscribe(
      (data) => {
        if (this.item.updates[0].status !== UpdateTypes.RECEIVED && data.id && this.mode?.length) {
          this.updateStatusService
            .updateStatus(this.mode, parseInt(data.id.toString()), this.item.updates[0])
            .subscribe((update) => {
              this._toastLuncher.success('تم تسجيل التحديث الأولي');
              this.isLoading = false;
              this._toastLuncher.success('تمت الإضافة بنجاح !');
              this.router.navigate([`/pages${this.featureName}/liste`]);
            });
        } else {
          this.isLoading = false;
          this._toastLuncher.success('تمت الإضافة بنجاح !');
          this.router.navigate([`/pages${this.featureName}/liste`]);
        }
      },
      (error) => {
        this.isLoading = false;
        if (error.status === 400) throw new Error('الرجاء التثبت من المعطيات المدرجة');
        if (error.status === 409) throw new Error('يوجد ملف اخر يحمل نفس المعطيات');
        if (error.status === 500) throw new Error('لم نتمكن من الاتصال بالمنصة، ارجاء المحاولة لاحقاً.');
        else throw new Error('لم نتمكن من الإضافة');
      },
    );
  }
}
