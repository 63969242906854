import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { Notification } from '../models/class/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(public http: HttpClient) {
    super();
    this.prefix = '/notifications';
  }

  getNotifications(): Observable<NotificationResponse> {
    return this.http.get(BaseService.url + this.prefix, {
      headers: BaseService.getHeaders(),
    }) as Observable<NotificationResponse>;
  }

  markAsRead(id: string): Observable<any> {
    return this.http.post(
      BaseService.url + this.prefix + '/' + id + '/mark-as-read',
      {},
      { headers: BaseService.getHeaders() },
    );
  }

  markAllAsRead(): Observable<any> {
    return this.http.post(BaseService.url + this.prefix + '/mark-as-read', {}, { headers: BaseService.getHeaders() });
  }
}

export class NotificationResponse {
  notifications: Notification[];
}
