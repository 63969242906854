import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
  NbChatModule,
} from '@nebular/theme';
import { NgxEchartsModule } from 'ngx-echarts';

import { ThemeModule } from '../../@theme/theme.module';
import { DashboardComponent } from './dashboard.component';
import { FormsModule } from '@angular/forms';
import { CardStatComponent } from './card-stat/card-stat.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChatComponent } from './chat/chat.component';
import { OurCommonModule } from '../../core/OurCommon.module';
import { ReducePipe } from '../../pipes/reduce.pipe';

@NgModule({
  imports: [
    FormsModule,
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbTabsetModule,
    NbActionsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbButtonModule,
    NbChatModule,
    NgxEchartsModule,
    NgxChartsModule,
    RouterModule,
    NgxSpinnerModule,
    OurCommonModule,
  ],
  declarations: [DashboardComponent, CardStatComponent, ChatComponent, ReducePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule { }
