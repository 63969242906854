import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isActiveState } from '../../../core/models/types';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

import { Agency } from '../../../core/models/class/agency';
import { AgenciesService } from '../../../services/agencies.service';
@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Agency> implements OnInit {
  item = new Agency();
  agencyState = isActiveState;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected _agenciesService: AgenciesService,
  ) {
    super('id', _agenciesService.prefix, _toastLuncher, _agenciesService, router, route);
  }

  ngOnInit() {}

  getCurrentPos() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.item.longitude = pos.coords.longitude;
          this.item.latitude = pos.coords.latitude;
        },
        () => {},
      );
    }
  }

  protected beforeSubmit(item: Agency): Agency {
    if (!item.saturday_first_start) delete item.saturday_first_start;
    if (!item.saturday_first_end) delete item.saturday_first_end;

    if (!item.saturday_second_end) delete item.saturday_second_end;
    if (!item.saturday_second_start) delete item.saturday_second_start;
    return item;
  }
}
