import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Email } from '../core/models/interface/email';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class MailingListService extends GenericCRUDService<Email> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/emails';
  }
}
