import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '../core/services/base.service';
import { Observable } from 'rxjs';
import Backend from '../core/backend';
import { MunisFacade } from '../core/store';
@Injectable({
  providedIn: 'root',
})
export class KpiService extends BaseService {
  baseUrl = Backend.baseUrl;

  constructor(protected http: HttpClient) {
    super();
  }

  getUrl(param: string = '') {
    return `${BaseService.url}/stats`;
  }

  public getMetabaseDashboard(dashboard_id: number, filters: any): Observable<{ link: string }> {
    return this.http.post(this.getUrl() + `/dashboard/${dashboard_id}`, filters) as Observable<{ link: string }>;
  }

  public getMainKpi(): Observable<KPI.IMainKpi> {
    return this.http.get(this.getUrl() + `/municipalities/${MunisFacade.getMunicipalityId()}`, {
      headers: BaseService.getHeaders(),
    }) as Observable<KPI.IMainKpi>;
  }
}

namespace KPI {
  interface Complaint {
    status: string;
    total: number;
  }

  interface Dossier {
    status: string;
    total: number;
  }

  interface SubjectAccessRequest {
    status: string;
    total: number;
  }

  interface Forum {
    status: string;
    total: number;
  }

  interface Events {
    upcoming: number;
    done: number;
  }

  interface News {
    category: string;
    total: number;
  }

  interface Report {
    committee_id: number;
    total: number;
  }

  export interface IMainKpi {
    complaints: Complaint[];
    dossiers: Dossier[];
    subject_access_requests: SubjectAccessRequest[];
    forum: Forum[];
    events: Events;
    news: News[];
    reports: Report[];
  }
}
