import { HttpClient } from '@angular/common/http';
import { Committee } from '../core/models/class/committee';
import { Injectable } from '@angular/core';

import { GenericCRUDService } from '../core/services/generic.service';
@Injectable({
  providedIn: 'root',
})
export class CommitteeService extends GenericCRUDService<Committee> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/committees';
  }
}
