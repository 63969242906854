import { NgModule } from '@angular/core';
import { MailingListRoutingModule } from './mailing-list-routing.module';
import { MailingListComponent } from './mailing-list.component';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { ThemeModule } from '../../@theme/theme.module';
import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import {
  NbTreeGridModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { HistoriqueModule } from '../../components/historique/historique.module';
import { MailingListService } from '../../services/mailing-list.service';
import { ManagersService } from '../../services/managers.service';
@NgModule({
  imports: [
    ThemeModule,
    MailingListRoutingModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    HistoriqueModule,
    SpinnerModule,
  ],
  declarations: [MailingListComponent, ListeComponent, EditComponent],
  providers: [MailingListService, ManagersService],
})
export class MailingListModule {}
