import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { selectAllMunis, selectedMuni, getLoadingStatus } from '..';
import * as MunisActions from './municipality.action';
import { MunisState } from './municipality.reducer';
import { UiFacade } from '../ui/ui.facade';
import { Router } from '@angular/router';
import { formatMunicipalityName } from '../../../lib/functions';
import { getItem, setItem, StorageItem } from '../../../lib/local-storage.utils';
import { Municipality } from '../../../models/class/municipality';

@Injectable({
  providedIn: 'root',
})
export class MunisFacade {
  munisList$ = this.store.pipe(select(selectAllMunis));
  isLoading$ = this.store.pipe(select(getLoadingStatus));
  selectedMuni$ = this.store.pipe(select(selectedMuni));

  constructor(private store: Store<MunisState>, private _router: Router) {}

  selectMuni(municipality: Municipality) {
    // currently setting local cache to avoid breaking everything
    // TODO : subscribe to selected Muni in all components that use it.
    setItem(StorageItem.municipality, municipality.id.toString());
    setItem(StorageItem.municipality_name, municipality.name_fr);
    setItem(StorageItem.municipality_ar, municipality.name);
    setItem(StorageItem.municipality_route_name, formatMunicipalityName(municipality.route_name));
    setItem(StorageItem.municipality_logo, municipality.logo);
    setItem(StorageItem.muni_website, municipality.facebook_url);
    this.store.dispatch(new MunisActions.LoadMuni(municipality.id));
  }
  /**
   * set selected municipality from id
   */
  setMuniFromId(id: number) {
    this.munisList$.subscribe((list) => {
      if (list) {
        const selectedMuni = list.find((muni) => muni?.id === id);
        if (selectedMuni) this.selectMuni(selectedMuni);
        else throw new Error('لم نتمكن من الحصول على بيانات البلدية الخاصة بك');
      }
    });
  }

  /**
   * set selected muni from id and redirect to route_name
   */
  redirectToSelectedMuni() {
    this.selectedMuni$.subscribe((selected) => {
      if (selected) {
        this._router.navigate([selected.route_name]);
      }
    });
  }

  loadMunis() {
    this.store.dispatch(new MunisActions.LoadMunis());
  }

  /**
   *
   * @returns selected municipality id (sync)
   */
  static getMunicipalityId(): string | null {
    const id = getItem(StorageItem.municipality);
    return typeof id === 'string' ? id : null;
  }
}
