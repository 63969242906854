import { Component, OnInit } from '@angular/core';
import Backend from '../../../core/backend';
import { Course } from '../../../core/models/class/course';
import { CoursesService } from '../../../services/courses.service';
import { Router, ActivatedRoute } from '@angular/router';
import { defaultAnimation } from '../../../core';
import { CourseUtility } from '..';

@Component({
  selector: 'ng-courses-list',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css', '../elearning.component.scss'],
  ...defaultAnimation,
})
export class CoursesComponent extends CourseUtility implements OnInit {
  Courses: Array<Course> = [];
  baseUrl = Backend.baseUrl;

  constructor(protected _courseService: CoursesService, protected router: Router) {
    super(_courseService, router);
  }

  ngOnInit() {
    this._courseService.get().subscribe((data) => (this.Courses = data));
  }
}
