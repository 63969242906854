import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Topic } from '../core/models/class/topic';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class TopicsService extends GenericCRUDService<Topic> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/topics';
  }
}
