import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { HttpClient } from '@angular/common/http';
import { MailingListService } from '../../../services/mailing-list.service';
import { ManagersService } from '../../../services/managers.service';
import { Manager } from '../../../core/models/class/manager';
import { getItem, StorageItem } from '../../../core';
import { Email } from '../../../core/models/interface/email';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    protected _toastLuncher: ToastLuncherService,
    public mailingListService: MailingListService,
    public managersService: ManagersService,
  ) {}

  mail_id: string;
  disabled: Boolean = false;
  emails: Array<Email> = [];
  managers: Array<Manager> = [];
  managersEmails = [];
  emailArray = [];
  emailString;
  singleEmail: Email;
  disableSaveButton: boolean = false;
  propositionOpened = false;
  toPropose = null;
  toProposeIndex = null;
  emailWrongFormat: boolean = false;
  isLoading: boolean = false;

  ngOnInit() {
    this.readParamUrl();
  }

  readParamUrl() {
    this.route.params.subscribe((params) => {
      this.mail_id = params['id'];
      this.getEmail();
    });
  }

  getEmail() {
    this.isLoading = true;
    if (this.mail_id === '-1') {
      this.mailingListService.get().subscribe((data) => {
        this.emails = data;
        this.emailString = '';
      });
      this.getManagers();
    } else {
      this.mailingListService.getById(this.mail_id).subscribe((data) => {
        this.singleEmail = data;
        this.emailString = data.email;
      });
      this.isLoading = false;
    }
  }

  getManagers() {
    this.managersService.get().subscribe((res) => {
      this.managers = res.filter((item) => item.id.toString() !== getItem(StorageItem.connected_user_id));
      this.managers.map((manager, index) => {
        this.emails.map((email) => {
          if (manager.email === email.email) {
            this.managers.splice(index, 1);
          }
        });
      });
      this.managers.map((manager) => {
        if (manager.email) this.managersEmails.push(manager.email);
      });
      this.toPropose = this.managersEmails.length ? this.managersEmails[0] : null;
      this.toProposeIndex = this.managersEmails.length ? 0 : null;
    });
    this.isLoading = false;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  cancelEdit() {
    this.router.navigate(['/pages/emails/liste']);
  }

  sendEdit() {
    this.emailWrongFormat = false;
    if (this.mail_id !== '-1') {
      if (!this.validateEmail(this.emailString)) {
        this.emailWrongFormat = true;
        throw new Error('الرجاء إدخال بريد الكتروني صحيح');
        return;
      } else {
        this.singleEmail.email = this.emailString;
        this.mailingListService.put(this.mail_id, this.singleEmail).subscribe(
          (data) => {
            this._toastLuncher.success('تمت العملية بنجاح');
            this.router.navigate(['/pages/emails/liste']);
          },
          (error) => {
            throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
          },
        );
      }
    } else {
      this.emailArray = this.emailString.split(' ');
      this.emailArray = this.emailArray.filter((item) => item !== '');
      this.emailArray.map((email, i) => {
        if (email === ' ' || email === '') return;

        if (!this.validateEmail(email)) {
          this.emailWrongFormat = true;
          throw new Error('الرجاء إدخال بريد الكتروني صحيح');
        }

        if (this.emails.filter((em) => em.email === email).length === 0) {
          this.mailingListService.post({ email: email } as Email).subscribe((data) => {});
        }

        if (i + 1 === this.emailArray.length && !this.emailWrongFormat) {
          this._toastLuncher.success('تم الإضافة بنجاح');
          this.router.navigate(['/pages/emails/liste']);
          return;
        }
      });
      this.mailingListService.get().subscribe((data) => {
        this.emails = data;
      });
    }
  }

  nextProposition() {
    this.toPropose =
      this.managersEmails.length === this.toProposeIndex + 1 ? null : this.managersEmails[this.toProposeIndex + 1];
    this.toProposeIndex++;
  }
  addProposition() {
    if (!this.emailString.includes(this.toPropose)) this.emailString = this.emailString + this.toPropose + ' ';
    this.nextProposition();
  }
}
