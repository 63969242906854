import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NbLayoutScrollService } from '@nebular/theme';

import { PollsService } from '../../../services/polls.service';
import { PollsChoiceService } from '../../../services/polls-choice.service';
import { getPollStatus } from '../../../core';
import { PollStatusPipe } from '../../../core/pipes/poll-status.pipe';
import { PollKindTypes } from '../../../core/models/types';
import { Poll } from '../../../core/models/class/polls';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Poll> implements OnInit {
  DELETE_TEXT = 'تم حذف سبر الآرء بنجاح';
  constructor(
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    public _choiceService: PollsChoiceService,
    public datePipe: DatePipe,
    protected scroll: NbLayoutScrollService,
    private _pollsService: PollsService,
  ) {
    super(_pollsService.prefix, scroll, _pollsService, router, _toastLuncher);
  }

  settings = {
    mode: 'external',
    pager: { display: false },
    noDataMessage: 'لا يوجد ﺳﺒﺮ ارﺍء',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    columns: {
      created_at: {
        title: 'نشر في',
        type: 'string',
        valuePrepareFunction: (created_at) => {
          return new DatePipe('en-US').transform(created_at, 'M/d/yyyy');
        },
      },
      title: {
        title: 'عنوان سبر الآرء',
        type: 'string',
      },
      text: {
        title: 'التفاصيل',
        type: 'html',
      },
      kind: {
        title: 'النوع',
        type: 'string',
        valuePrepareFunction: (kind) => {
          return kind === PollKindTypes.MULTI_CHOICE ? 'يمكن التصويت على أكثر من خيار' : 'إختيار واحد';
        },
      },
      status: {
        title: 'الحالة',
        type: 'string',
        valuePrepareFunction: (status) => {
          return new PollStatusPipe().transform(status);
        },
      },
      total_votes: {
        title: 'عدد المشاركين',
        type: 'string',
        valuePrepareFunction: (total_votes) => {
          return typeof total_votes === 'string' ? 'غير متاح' : total_votes;
        },
      },
      starts_at: {
        title: 'تاريخ إنطلاق التصويت',
        type: 'date',
      },
      ends_at: {
        title: 'تاريخ غلق التصويت',
        type: 'date',
      },
    },
  };

  ngOnInit() {}

  preSavingList(data: Poll[]) {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    data.forEach((item) => {
      item.starts_at = this.datePipe.transform(item.starts_at, 'yyyy-MM-dd');
      item.ends_at = this.datePipe.transform(item.ends_at, 'yyyy-MM-dd');
      // item.published_at = GeneralService.timeAgo((Date.now() - Date.parse(item.published_at)) / 1000);
      item.status = getPollStatus(item.starts_at, item.ends_at);
    });
    return data;
  }
}
