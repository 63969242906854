import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getItem } from '../core';

@Injectable({
  providedIn: 'root',
})
export class CacheResponsesService {
  private seperator = ',';
  // on => (/pages/subject-access-requests/edit/59) : cacheKey would be = subject-access-requests
  private cacheKey = '__' + this.router.url.split('/')[2];

  constructor(private router: Router) {}
  getCachedList(): string[] {
    const oldValues: string = ',' + localStorage.getItem(this.cacheKey);
    const returnArr = oldValues.split(this.seperator);
    return returnArr.filter((item) => item !== 'null').reverse() || [];
  }
  addToCachedList(value: string) {
    const oldValues = localStorage.getItem(this.cacheKey);
    localStorage.setItem(this.cacheKey, oldValues + this.seperator + value);
  }
}
