import { ActivatedRoute } from '@angular/router';
import Backend from '../../backend';
import { GenericCRUDService } from '../../services/generic.service';

export class GenericSingleView<T> {
  isLoading: boolean = true;
  item: T;
  item_id = '';
  baseUrl = Backend.baseUrl;

  constructor(
    public item_param_name: string,
    protected _service: GenericCRUDService<T>,
    protected activeRoute: ActivatedRoute,
  ) {
    this.getItem();
  }

  protected normalizeAfterGetItem(item: T): T {
    return item;
  }

  getItem(): void {
    this.activeRoute.params.subscribe((params) => {
      this.item_id = params[this.item_param_name];
      this._service.getById(this.item_id.toString()).subscribe(
        (data) => {
          this.item = this.normalizeAfterGetItem(data);
          this.isLoading = false;
        },
        (error) => {
          throw new Error('لم نتمكن من العثور على المعلومات المطلوبة');
        },
      );
    });
  }
}
