import { IfeatureItem } from '../interface/IfeatureItem';

export class Agency implements IfeatureItem {
  id: string;
  is_open: boolean;
  has_eticket: boolean;
  name: string;
  is_active: boolean = false;
  latitude: number | string = 36.797423;
  longitude: number | string = 10.165894;
  weekday_first_start: string = '08:00:00';
  weekday_first_end: string = '12:00:00';
  weekday_second_start: string = '13:30:00';
  weekday_second_end: string = '17:00:00';
  saturday_first_start?: any;
  saturday_first_end?: any;
  saturday_second_start?: any;
  saturday_second_end?: any;
  created_at: Date;
  updated_at: Date;
  municipality: number;
  created_by: number;
}
