import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-card-stat',
  templateUrl: './card-stat.component.html',
  styleUrls: ['./card-stat.component.scss'],
})
export class CardStatComponent implements OnInit {
  @Input() name: string;
  @Input() route: string;
  @Input() description: string;
  @Input() stat: number;

  constructor() {}

  ngOnInit() {}
}
