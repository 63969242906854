import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export default class Backend {
  // 'https://backend.elbaladiya.tn' : 'http://62.171.172.30:1337';
  static baseUrl = environment.apiBaseUrl;
  static url = environment.apiUrl;
  static frontUrl = environment.production ? 'https://idara.elbaladiya.tn/' : 'https://dev-idara.elbaladiya.tn/';
  static VAPID_PUBLIC_KEY = '';
  constructor(public router: Router) {}

  navigateTo = (path: string, id?: string): void => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    path = id === undefined ? path : path + id;
    if (isMobile || !environment.production) this.router.navigate([path]);
    else window.open(path);
  };
}
