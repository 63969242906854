import { Manager } from '../core/models/class/manager';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GenericCRUDService } from '../core/services/generic.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { BaseService } from '../core/services/base.service';
@Injectable({
  providedIn: 'root',
})
export class ManagersService extends GenericCRUDService<Manager> {
  prefix = '/managers';
  constructor(http: HttpClient) {
    super(http);
  }

  public delete(id: string | number): Observable<Manager> {
    return (
      this.http.delete(`${this.getUrl()}/${id}/delete-account/`, {
        headers: BaseService.getHeaders(),
      }) as Observable<Manager>
    ).pipe(first());
  }
}
