import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { ElearningRoutingModule } from './elearning-routing.module';
import { ECourseLearningComponent } from './elearning.component';
import { NbAccordionModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDialogService, NbRadioModule, NbSpinnerModule, NbStepperModule } from '@nebular/theme';
import { CoursesService } from '../../services/courses.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CourseDetail } from './course-detail/courseDetail.component';
import { NgbModule, NgbRadioGroup } from '@ng-bootstrap/ng-bootstrap';
import { CoursesComponent } from './courses/courses.component';
import { MyCoursesComponent } from './mycourses/mycourses.component';
import { CourseLearningComponent } from './course-learning/course-learning.component';
import { FormsModule } from '@angular/forms';
import { AchievementComponent } from './achievements/achievement.component';

@NgModule({
  imports: [
    ElearningRoutingModule,
    NbCardModule,
    CommonModule,
    Ng2SmartTableModule,
    NbButtonModule,
    NbSpinnerModule,
    NbAccordionModule,
    NgbModule,
    NbStepperModule,
    NbCheckboxModule,
    NbRadioModule,
    FormsModule,
  ],
  exports: [],
  declarations: [HomeComponent, ECourseLearningComponent, CoursesComponent, MyCoursesComponent, CourseDetail,CourseLearningComponent,AchievementComponent],
  providers: [CoursesService,NbDialogService,DatePipe],
})
export class ElearningModule { }
