import Backend from '../backend';
import { features } from '../config';
import { Category } from '../models/class/category';
import { MunicipalityGlobal } from '../models/class/municipality';
import { SubCategory } from '../models/class/sub-category';
import { DeviceDataCollected } from '../models/class/user';
import { CommentTypes, PollStatusTypes, UpdateTypes } from '../models/types';
import { ArabicTimeAgoPipe } from '../pipes/arabic-time-ago.pipe';
import { StatusColorPipe } from '../pipes/status-color.pipe';
import { UpdateTypeToArabicPipe } from '../pipes/update-type-to-arabic.pipe';
import { notificationRoutes } from './const';
import { StorageItem, getItem, setItem } from './local-storage.utils';

/**
 *
 * @param municipalities
 * @param key wanted to be extracted from an array of objects.
 * @returns Set of values plucked from array
 */
export const getStates = (municipalities: Array<MunicipalityGlobal>, key: string = 'city'): Set<any> => {
  return new Set(municipalities.map((value) => value[key]));
};

// group them by city or key in a Map
export const mapMunicipalities = (
  municipalities: Array<MunicipalityGlobal>,
  key: string = 'city',
): Map<string, any[]> => {
  const mappedMunicipalities = new Map();
  const states = getStates(municipalities);
  states.forEach((state) => {
    mappedMunicipalities.set(
      state,
      municipalities.filter((value) => value[key] === state),
    );
  });
  return mappedMunicipalities;
};

export const formatMunicipalityName = (mun: string): string => (mun ? mun.toLowerCase().split(' ').join('-') : '');

/**
 *
 * @param input string to check if valid
 * @param length the required length of that string
 * @returns boolean
 */
const isValidStringOfNumbers = (input: string, length: number) => {
  const output = input.toString();
  const validStringOfNumbers = new RegExp('^[0-9]+$');
  return validStringOfNumbers.test(output) && output.length === length;
};

export const validateEmail = (emailAdress: string) => {
  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailAdress.match(regexEmail);
};

export const isValidPhoneNumber = (phone_number: string | number) => {
  phone_number = phone_number.toString().replace(/\s/g, ''); // remove whitespaces;

  if (phone_number.includes('+216')) phone_number = phone_number.replace('+216', '');

  return isValidStringOfNumbers(phone_number, 8);
};
/**
 *
 * @param id a unique identifier for a permit request ...
 * @returns boolean
 */
export const isValidIdentifier = (input: string, minLength: number = 3, maxLength: number = 20) => {
  const output = input;
  return typeof input === 'string' && output?.length >= minLength && output?.length <= maxLength;
};

/**
 *
 * @param id a tunisian citizen id
 * @returns boolean
 */
export const isValidCitizenId = (num: string | number) => {
  return isValidStringOfNumbers(num.toString(), 3);
};

const timePipe = new ArabicTimeAgoPipe();

export const timeAgo = (date: string | number | Date): string => {
  return timePipe.transform(date?.toString());
};

export const openLink = (link: string) => {
  if (typeof link === 'string') window.open(Backend.baseUrl + link, '_blank');
};

export const getPageTitle = (pathParam: string = ''): string => {
  const pageName = features.find((f) => f.route === pathParam)?.documentName;
  if (pageName) return pageName;
  // default
  return features[features.length - 1].documentName;
};

export const ItemIsOpenForProcessing = (item: any) =>
  item.status === UpdateTypes.RECEIVED || item.status === UpdateTypes.PROCESSING;

export const getPollStatus = (start: Date | string, end: Date | string) => {
  const today = new Date();
  if (today < new Date(start)) return PollStatusTypes.ENDED;
  if (today > new Date(start) && today < new Date(end)) return PollStatusTypes.IN_PROGRESS;
  return PollStatusTypes.ENDED;
};

export const commentTypeToArabic = (value: CommentTypes): string => {
  switch (value) {
    case CommentTypes.REMARK:
      return 'ملاحظة';
    case CommentTypes.QUESTION:
      return 'سؤال';
    // default to SUGGESTION
    default:
      return 'مقترح';
  }
};

const statusPipe = new UpdateTypeToArabicPipe();
export const toArabicStatus = (status: string): string => {
  return statusPipe.transform(status);
};

const statusColorPipe = new StatusColorPipe();
export const statusColor = (input: string): string => {
  return statusColorPipe.transform(input);
};

export const toArabicDate = (date: string) => {
  return new Date(Date.parse(date)).toLocaleDateString('ar-tn', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const getTime = (time: string) => {
  if (!time) return new Date();
  const timeString = time.split(':');
  const hours = parseInt(timeString[0], 10);
  const minutes = parseInt(timeString[1], 10);
  const seconds = parseInt(timeString[2].split('.')[0], 10);
  const milli = timeString[2].split('.').length > 1 ? parseInt(timeString[2].split('.')[1], 10) : 0;
  const d = new Date();
  d.setHours(hours);
  d.setSeconds(seconds);
  d.setMinutes(minutes);
  d.setMilliseconds(milli);
  return d;
};

/**
 *
 * @returns error = true, if one the files uploaded doesn't correspond the formats/contrains we use.
 */
export const areValidImages = (files: FileList): { error: boolean; errorMessage?: string } => {
  const supportedTypes: string[] = ['png', 'jpg', 'jpeg', 'gif'];

  for (let idx = 0; idx < files.length; idx++) {
    const file = files[idx];
    if (!file.type || !supportedTypes.includes(file.type.replace('image/', '')))
      return { error: true, errorMessage: supportedTypes.join(' - ') + ' الرجاء ادخال صورة صحيحة' };

    if (!file.name || file.name.length > 100)
      return { error: true, errorMessage: 'اسم الملف طويل جدًا ، يرجى تغييره والمحاولة مرة أخرى' };
  }

  return { error: false };
};

export const isBase64 = (str: string): boolean => {
  if (typeof str !== 'string' || str === '' || str.trim() === '') {
    return false;
  }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
};

/**
 *
 * @param input string containing markup
 * @returns same string after removing extra white spaces from the end if they exist
 */
export const removeMarkupSpaces = (input: string): string => {
  const replacements = ['\n<p>&nbsp;</p>\n', '\n\n<p>&nbsp;</p>'];
  let output = `${input}`;

  replacements.forEach((replacement) => {
    while (output.slice(output.length - replacement.length) === replacement) {
      output = output.slice(0, -replacement.length);
    }
  });
  return output;
};

export const getSubCategories = (categories: Category[]): SubCategory[] => {
  let allSubCategories = [];
  categories.forEach((c) => {
    if (c?.sub_categories?.length) {
      allSubCategories = [...allSubCategories, ...c.sub_categories];
    }
  });
  return allSubCategories;
};

export const diffrenceInDays = (d1: Date, d2: Date): number => (d1.getTime() - d2.getTime()) / (1000 * 60 * 60 * 24); // return float

export const subjectAccessRemainingDays = (created_at: Date): string => {
  const days = Math.trunc(diffrenceInDays(new Date(), created_at));
  return days <= 30 ? days.toString() : 'انقضى الحد الاقصى (30 يوم)';
};

export const normalizedPDF = (result: string | ArrayBuffer): string => {
  return result?.toString().split(',').pop();
};

/**
 * check refresh token is expiring in {hours}
 */
export const expiringIn = (hours, tokenExpirationDate): boolean =>
  tokenExpirationDate - new Date().getTime() < hours * 60 * 60 * 1000;

function generateDeviceID(): string {
  let machineId: string | null = getItem(StorageItem.unique_device_id);
  if (!machineId) {
    machineId = crypto.randomUUID();
    setItem(StorageItem.unique_device_id, machineId);
  }
  return machineId;
}

function getDeviceModel(userAgent: string): string {
  const match = userAgent.match(/(iPhone|iPad|iPod|Android|Windows Phone|Windows NT)[\s/]?([0-9A-Za-z]+)/i);
  return match ? match[0] : 'Unknown';
}

export function getDeviceData(): DeviceDataCollected {
  const deviceUniqueID = generateDeviceID(); // function that generates a unique device ID
  const userAgent = navigator.userAgent;
  const os = 'OTHER';
  const osVersion = 'Unknown';
  const lastVersion = 'Unknown';
  const model = getDeviceModel(userAgent);

  return {
    device_unique_id: deviceUniqueID,
    os: os,
    os_version: osVersion,
    last_version: lastVersion,
    model: model,
  };
}
export const navigateNotification = (model: string): string => {
  const route = notificationRoutes.find((route) => route.model === model);
  return route !== undefined && typeof route?.destination === 'string' ? route.destination : '/';
};
