import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbLayoutScrollService } from '@nebular/theme';
import tableSettings from './list.config';

import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { AgenciesService } from '../../../services/agencies.service';
import { Agency } from '../../../core/models/class/agency';
import { setItem, StorageItem } from '../../../core';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Agency> implements OnInit {
  constructor(
    public router: Router,
    protected scroll: NbLayoutScrollService,
    protected _toastLuncher: ToastLuncherService,
    protected _agenciesService: AgenciesService,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(_agenciesService.prefix, scroll, _agenciesService, router, _toastLuncher);
  }

  settings = tableSettings;

  ngOnInit() {}

  preSavingList(data: Agency[]) {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    return data;
  }

  setAgency($event) {
    setItem(StorageItem.agencyId, $event.data.id);
    setItem(StorageItem.agencyName, $event.data.name);
  }
}
