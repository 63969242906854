import { Component, Input, OnInit } from '@angular/core';

import { Update } from '../../core/models/class/update';
import { UpdateTypes } from '../../core/models/types';
import { defaultAnimation } from '../../core';
import { CacheResponsesService } from '../../services/cache-responses.service';

@Component({
  selector: 'ngx-update-on-creation',
  templateUrl: './update-on-creation.component.html',
  styleUrls: ['./update-on-creation.component.scss'],
  ...defaultAnimation,
})
export class UpdateOnCreationComponent implements OnInit {
  @Input() updates: Update[] = [new Update()];
  @Input() type: string; // second param used for forum
  UpdateTypes: string[] = Object.keys(UpdateTypes).filter((i) => i !== UpdateTypes.RECEIVED);

  cachedList = this.cacheResponses.getCachedList();

  constructor(public cacheResponses: CacheResponsesService) {}

  ngOnInit() {}
}
