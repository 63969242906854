import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ECourseLearningComponent } from './elearning.component';
import { HomeComponent } from './home/home.component';
import { CourseDetail } from './course-detail/courseDetail.component';
import { CoursesComponent } from './courses/courses.component';
import { MyCoursesComponent } from './mycourses/mycourses.component';
import { ElearningGuard } from './elearning.guard';
import { CourseLearningComponent } from './course-learning/course-learning.component';
import { AchievementComponent } from './achievements/achievement.component';

const routes: Routes = [
  {
    path: '',
    component: ECourseLearningComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'courses',
        component: CoursesComponent,
      },
      {
        path: 'mycourses',
        component: MyCoursesComponent,
      },
      {
        path: 'course-detail/:id',
        component: CourseDetail,
      },
      {
        path: 'course-learning/:id',
        component: CourseLearningComponent,
        canActivate: [ElearningGuard],
      },
      {
        path:'achievements',
        component:AchievementComponent
      }
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ElearningRoutingModule { }
