import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GenericCreateFeature, GenericCreateWithImage } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { Event } from '../../../core/models/class/event';
import { EventsService } from '../../../services/events.service';
import * as $ from 'jquery';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateWithImage<Event> implements OnInit {
  item = new Event();
  hasImage = false;
  isFileSizePassed = true;
  manyDaysEvent = false;

  constructor(
    protected datePipe: DatePipe,
    protected _eventsService: EventsService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected imageCompress: NgxImageCompressService,
  ) {
    super(_eventsService.prefix, _toastLuncher, _eventsService, router, imageCompress);
  }

  ngOnInit() {}

  formIsValid(): boolean {
    if (!this.item.title || !this.item.description || !this.item.starting_date) {
      throw new Error('الرجاء تعمير بقية الخانات');
    }
    return true;
  }

  beforeSubmit(event: Event): Event {
    event.date = this.datePipe.transform(event.starting_date, 'yyyy-MM-dd');
    event.starting_date = this.datePipe.transform(event.starting_date, 'yyyy-MM-dd');
    event.ending_date = this.datePipe.transform(event.ending_date, 'yyyy-MM-dd');
    event.starting_time = this.formatTime(event.starting_time);
    event.ending_time = this.formatTime(event.ending_time);

    if (!this.manyDaysEvent) {
      event.ending_date = event.starting_date;
    }

    if (!event?.image) delete event.image;
    return event;
  }

  formatTime(time: any) {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    return hours + ':' + minutes + ':' + seconds;
  }

  onImageLoad($event, compressedImage, ...args) {
    this.item.image = compressedImage;
  }

  handlePickerOpen() {
    const butt = document.getElementsByClassName('owl-dt-control-content owl-dt-control-button-content');
    butt[0].innerHTML = 'غلق';
    butt[1].innerHTML = 'اختيار';
  }
}
