import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { dossierTypes, setItem, StorageItem } from '../../../core';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { PollsService } from '../../../services/polls.service';
import { Choice, Poll } from '../../../core/models/class/polls';
import * as $ from 'jquery';
import { PollsChoiceService } from '../../../services/polls-choice.service';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateFeature<Poll> implements OnInit {
  item = new Poll();
  types = dossierTypes;
  isFileSizePassed = true;
  hasImage = false;

  constructor(
    private _choicesService: PollsChoiceService,
    private _pollsService: PollsService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super(_pollsService.prefix, _toastLuncher, _pollsService, router);
  }

  ngOnInit() {
    this.item.choices.push(new Choice());
  }
  private _startDate: Date;
  private _time: string;
  private _endDate: Date;
  private _endTime: string;

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;
    this.updateEndsAt();
  }

  get endTime(): string {
    return this._endTime;
  }

  set endTime(value: string) {
    this._endTime = value;
    this.updateEndsAt();
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
    this.updateStartsAt();
  }

  get time(): string {
    return this._time;
  }

  set time(value: string) {
    this._time = value;
    this.updateStartsAt();
  }

  updateStartsAt() {
    if (this.startDate && this.time) {
      const startsAtDate = new Date(this.startDate);
      const [hours, minutes] = this.time.split(':');
      startsAtDate.setHours(Number(hours));
      startsAtDate.setMinutes(Number(minutes));

      const dateTimeString = startsAtDate.toISOString().split('.')[0] + 'Z';
      this.item.starts_at = dateTimeString;
    }
  }

  updateEndsAt() {
    if (this.endDate && this.endTime) {
      const endsAtDate = new Date(this.endDate);
      const [hours, minutes] = this.endTime.split(':');
      endsAtDate.setHours(Number(hours));
      endsAtDate.setMinutes(Number(minutes));

      const dateTimeString = endsAtDate.toISOString().split('.')[0] + 'Z';
      this.item.ends_at = dateTimeString;
    }
  }

  formIsValid(): boolean {
   // verifiying all choices has text
   this.item.choices.map((choice,index)=>{
    // not considering the choice we added by nginit
    if(index!=this.item.choices.length-1)
      if(!choice.text)
            throw new Error('الرجاء التثبت من نص الخيار');

    }) 
    if (!this.item.text || !this.item.title || !this.item.starts_at || !this.item.ends_at)
      throw new Error('الرجاء ملء كل الفراغات');
    if (this.item.choices.length < 3)
      // 2 choices by the user + 1 added by us on ngOnInit
      throw new Error('يجب أن يحتوي الاستطلاع على أكثر من خيارين');
    return true;
  }

  submit() {
    if (!this.formIsValid()) return;
    this.isLoading = true;
    this.item.choices.splice(-1, 1);
    this._service.post(this.item).subscribe(
      (data) => {
        setItem(StorageItem.poll_id, data.id.toString());
        for (const c of this.item.choices) {
          this._choicesService.post(c).subscribe((res) => {
            this.isLoading = false;
            this._toastLuncher.success('تمت الإضافة بنجاح !');
            this.router.navigate([`/pages${this.featureName}/liste`]);
          });
        }
      },
      (error) => {
        this.isLoading = false;
        if (error.status === 400) throw new Error('الرجاء التثبت من المعطيات المدرجة');
        if (error.status === 409) throw new Error('يوجد ملف اخر يحمل نفس المعطيات');
        if (error.status === 500) throw new Error('لم نتمكن من الاتصال بالمنصة، ارجاء المحاولة لاحقاً.');
        else throw new Error('لم نتمكن من الإضافة');
      },
    );
  }

  import(id: string) {
    document.getElementById(id).click();
  }

  addchoice() {
    this.item.choices.push(new Choice());
  }

  removeChoice(index: number) {
    this.item.choices.splice(index, 1);
  }

  onChangeImagefile(fileInput, index: number) {
    this.hasImage = false;
    const reader2 = new FileReader();
    let src;
    reader2.onload = (e) => {
      src = reader2.result;
      if (index !== null) this.item.choices[index].picture = src;
      else this.item.picture = src;
    };
    if (fileInput.target.files[0].size < 5300000 * 2) {
      this.isFileSizePassed = true;
      reader2.readAsDataURL(fileInput.target.files[0]);
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = false;
      throw new Error('الرجاء ادخال صورة لا تتجاوز 10MB');
    }
  }

  onImagesChanged(images) {
    this.item.picture = images;
  }
}
