import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-elearning',
  template: `<router-outlet></router-outlet>`,
})
export class ECourseLearningComponent implements OnInit {
  constructor() { }
  ngOnInit() { }
}
