import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Report } from '../models/class/report';
import { ManagerFacade, MunisFacade } from '../store';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class GenericCRUDService<T> extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  getUrl(): string {
    return BaseService.url + '/municipalities/' + MunisFacade.getMunicipalityId() + this.prefix;
  }

  public get(): Observable<T[]> {
    return (
      this.http.get(this.getUrl() + this.suffix + '?per_page=150', {
        headers: BaseService.getHeaders(),
      }) as Observable<T[]>
    ).pipe(first());
  }

  public getPages(page: number, perPage: number): Observable<T[]> {
    if (page === 0 && perPage === 0) return this.get();
    return (
      this.http.get(this.getUrl() + this.suffix + '?page=' + page + '&per_page=' + perPage, {
        headers: BaseService.getHeaders(),
      }) as Observable<T[]>
    ).pipe(first());
  }

  public getById(id: string | number): Observable<T> {
    return (
      this.http.get(this.getUrl() + '/' + id + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<T>
    ).pipe(first());
  }

  public post(body: T): Observable<T> {
    return this.http.post(this.getUrl() + this.suffix, body, {
      headers: BaseService.getHeaders(),
    }) as Observable<T>;
  }

  public put(id: string | number, body: T): Observable<T> {
    return (
      this.http.put(this.getUrl() + '/' + id + this.suffix, body, {
        headers: BaseService.getHeaders(),
      }) as Observable<T>
    ).pipe(first());
  }

  public delete(id: string | number): Observable<T> {
    return (
      this.http.delete(this.getUrl() + '/' + id + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<T>
    ).pipe(first());
  }

  /**
   * opens a new window to a PDF report about the < Type >
   *
   * example on Complaint:
   *
   * api/reports/municipalities/216/reports/complaints-report?status=PROCESSING,REJECTED&category=&start=&end=&bearer=<acces-token>
   */
  public getReport(startDate: String, endDate: String, status: String, category?: String) {
    window.open(
      `${BaseService.url}/reports/municipalities/${MunisFacade.getMunicipalityId()}${this.prefix}-report` +
        `?status=${status}` +
        (category ? `&category=${category}` : '') +
        `&start=${startDate}` +
        `&end=${endDate}` +
        `&bearer=${ManagerFacade.getToken()}`,
      '_blank',
    );
  }

  public getDocX(id: number) {
    return this.http.get(`${this.getUrl()}/${id}/export-to-docx/`, {
      headers: BaseService.getHeaders(),
      responseType: 'blob' as 'json',
    }) as Observable<Blob>;
  }
}
