import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { CreateComponent } from './create/create.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTimepickerModule,
  NbToggleModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { PollsRoutingModule } from './polls-routing.module';
import { PollsComponent } from './polls.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { CommitteeService } from '../../services/committee.service';
import { PollsService } from '../../services/polls.service';
import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import { ImageSliderModule } from '../../components/image-slider/image-slider.module';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { OurCommonModule } from '../../core/OurCommon.module';
import { ShareSingleViewModule } from '../../components/share-single-view/share-single-view.module';

@NgModule({
  declarations: [ListeComponent, EditComponent, PollsComponent, CreateComponent],
  imports: [
    CommonModule,
    PollsRoutingModule,
    ThemeModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbProgressBarModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    CKEditorModule,
    NbToggleModule,
    SpinnerModule,
    ImageSliderModule,
    Ng2CarouselamosModule,
    NbTimepickerModule.forRoot(),
    ShareSingleViewModule,
    NbSpinnerModule,
    OurCommonModule,
  ],
  providers: [CommitteeService, PollsService],
})
export class PollsModule {}
