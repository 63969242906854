import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComplaintsService } from '../../../services/complaints.service';
import { CategoryService } from '../../../services/category.service';
import { MunicipalityService } from '../../../services/municipality.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateModeTypes } from '../../../core/models/types';
import { Municipality } from '../../../core/models/class/municipality';
import { Complaint } from '../../../core/models/class/complaint';
import { Category } from '../../../core/models/class/category';
import { complaintTypes, toArabicDate } from '../../../core';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { MunisFacade } from '../../../core/store';
import Backend from '../../../core/backend';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Complaint> implements OnInit {
  modeInput = UpdateModeTypes.MODE_COMPLAINT;
  municipality: Municipality;
  categories: Array<Category> = [];

  baseUrl = Backend.baseUrl;
  longitude: number;
  latitude: number;
  types = complaintTypes;
  googleMapLink: string = null;

  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected _muniFacade: MunisFacade,
    private _complaintsService: ComplaintsService,
    private categoryService: CategoryService,
    private modalService: NgbModal,
  ) {
    super('id', _complaintsService.prefix, _toastLuncher, _complaintsService, router, route);
    this.item = new Complaint();
    this.getCategoriesAndReclamations();
    this.getMunicipality();
  }

  ngOnInit() {}

  protected normalizeAfterGetItem(item) {
    if (item.longitude) this.longitude = Math.min(parseFloat(item.latitude), parseFloat(item.longitude));
    if (item.latitude) this.latitude = Math.max(parseFloat(item.latitude), parseFloat(item.longitude));
    if (item.longitude && item.latitude)
      this.googleMapLink = `https://google.com/maps/?q=${item.latitude},${item.longitude}`;
    else if (item.address) this.googleMapLink = 'https://www.google.com/maps/search/' + item.address;
    else this.googleMapLink = null;
    item.created_at = toArabicDate(item.created_at);
    return item;
  }

  protected beforeSubmit(item) {
    item = {
      category: this.item.category_obj ? this.item.category_obj.name : this.item.category,
      sub_category: this.item.sub_category,
    };
    if (!item.sub_category) delete item.sub_category;
    return item;
  }

  onCategoryChanged() {
    this.item.sub_category = null;
    this.item.category_obj =
      this.categories.filter((item) => item.name === this.item.category)[0] || this.categories[0];
  }

  openModalImage(modal) {
    this.modalService.open(modal, { size: 'md' });
  }

  closeModalImage() {
    this.modalService.dismissAll();
  }

  getCategoriesAndReclamations() {
    this.categoryService.get().subscribe((data) => {
      this.categories = data;
      if (this.categories.length) {
        const cats = this.categories.filter((item) => item.name === this.item.category);
        this.item.category_obj = cats[0] || this.categories[0];
      }
      if (this.item.category === null) {
        this.item.category = this.categories[0].name;
      }
    });
  }

  getMunicipality() {
    this._muniFacade.selectedMuni$.subscribe((data) => {
      this.municipality = data;
      if (this.item.region === null && this.municipality.regions[0]) {
        this.item.region = this.municipality.regions[0].name;
      }
    });
  }

  saveRegion() {
    this._complaintsService.put(this.item.id.toString(), { region: this.item.region } as Complaint).subscribe(() => {
      this._toastLuncher.success('تم تعديل المنطقة بنجاح');
    });
  }
}
