/**
 * THIS IS USED TO DETERMINE PAGE NAME on each feature
 */
export const features = [
  {
    route: 'dashboard',
    documentName: 'لوحة القيادة',
  },
  {
    route: 'dossiers',
    documentName: 'مطالب الرخص',
  },
  {
    route: 'complaints',
    documentName: 'التشكيات',
  },
  {
    route: 'subject-access-requests',
    documentName: 'مطالب النفاذ إلى المعلومة',
  },
  {
    route: 'procedures',
    documentName: 'دليل الخدمات',
  },
  {
    route: 'polls',
    documentName: 'ﺳﺒﺮ اﻟﺂرﺍء',
  },
  {
    route: 'forums',
    documentName: 'فضاء الآراء و التساؤلات',
  },
  {
    route: 'news',
    documentName: 'المستجدات البلدية',
  },
  {
    route: 'events',
    documentName: 'رزنامة المواعيد و الأنشطة',
  },
  {
    route: 'reports',
    documentName: 'الاخبار',
  },
  {
    route: 'committees',
    documentName: 'اللجان البلدية',
  },
  {
    route: 'managers',
    documentName: 'المتصرفين في المنصة',
  },
  {
    route: 'emails',
    documentName: 'قائمة الإمايلات',
  },
  {
    route: 'account-data',
    documentName: 'معطيات الحساب',
  },
  {
    route: 'contact',
    documentName: 'مساعدة',
  },
  {
    // this is default
    route: '',
    documentName: 'elBaladiya.tn',
  },
];

export const ACCEPTED_IMAGES = 'image/x-png,image/gif,image/jpeg,image/png';
