import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { News, NewsTags } from '../core/models/class/news';
import { GenericCRUDService } from '../core/services/generic.service';
import { BaseService } from '../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends GenericCRUDService<News> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/news';
  }

  public getNewsTags() {
    return this.http.get(BaseService.url + '/news-tags', {
      headers: BaseService.getHeaders(),
    }) as Observable<NewsTags[]>;
  }
}
