import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Municipality } from '../core/models/class/municipality';
import { BaseService } from '../core/services/base.service';
import { GenericCRUDService } from '../core/services/generic.service';
@Injectable({
  providedIn: 'root',
})
export class MunicipalityService extends GenericCRUDService<Municipality> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/municipalities';
  }

  getUrl() {
    return BaseService.url + this.prefix;
  }

  getMunicipalities(activeOnly = false, showDetails = false): Observable<Municipality[]> {
    let params = new HttpParams();
    if (showDetails) {
      params = params.append('show_details', 'True');
    }
    if (activeOnly) {
      params = params.append('is_active', 'True');
    }
    return this.http.get(BaseService.url + this.prefix, {
      params,
    }) as Observable<Municipality[]>;
  }

  getById(id: string, showDetails = false): Observable<Municipality> {
    let params = new HttpParams();
    if (showDetails) {
      params = params.append('show_details', 'True');
    }

    return this.http.get(BaseService.url + this.prefix + '/' + id, {
      params,
    }) as Observable<Municipality>;
  }
}
