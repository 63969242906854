export enum StorageItem {
  // user related
  connected_user_id = 'connected_user_id',
  token = 'token',
  refresh_token = 'refresh_token',
  first_login = 'first_login',
  phone_number = 'phone_number',
  unique_device_id = 'unique_device_id',
  // officer related
  admin_name = 'admin_name',
  refresh_token_officer = 'refresh_token_officer',
  permissions = 'permissions',
  complaint_categories = 'complaint_categories',
  THEME = 'THEME',
  // app related
  municipality = 'municipality',
  municipality_ar = 'municipality_ar',
  municipality_name = 'municipality_name',
  municipality_logo = 'municipality_logo',
  muni_website = 'muni_website',
  // routing related
  municipality_route_name = 'municipality_route_name',
  poll_id = 'poll_id',
  agencyId = 'agencyId',
  agencyName = 'agencyName',
  toFilter = 'filterClosed',
}

export const getItem = (itemName: StorageItem): string | null => {
  const item = localStorage.getItem(itemName);
  return item ? item : null;
};

export const setItem = (itemName: StorageItem, value: string): void => {
  localStorage.setItem(itemName, value);
};

export const removeItem = (itemName: StorageItem): void => {
  localStorage.removeItem(itemName);
};

export const clearItems = (): void => {
  // should keep the device id
  const unique_device_id = getItem(StorageItem.unique_device_id);
  localStorage.clear();
  if (unique_device_id != null) setItem(StorageItem.unique_device_id, unique_device_id);
};

export enum permissionKeys {
  manage_committees = 'manage_committees',
  manage_forum = 'manage_forum',
  manage_polls = 'manage_polls',
  manage_complaints = 'manage_complaints',
  manage_events = 'manage_events',
  manage_news = 'manage_news',
  manage_eticket = 'manage_eticket',
  manage_procedures = 'manage_procedures',
  manage_reports = 'manage_reports',
  manage_subject_access_requests = 'manage_subject_access_requests',
  manage_dossiers = 'manage_dossiers',
  manage_permissions = 'manage_permissions',
}
