import { IfeatureItem } from '../interface/IfeatureItem';
import { ProcedureTypes } from '../types';

export class Procedure implements IfeatureItem {
  municipality_id: number;
  id: number;
  title: string;
  body: string;
  excerpt: string;
  type: ProcedureTypes;
  display_order: number = 1;
}
