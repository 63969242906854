import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { getItem, StorageItem } from '../lib/local-storage.utils';
import { Manager } from '../models/class/manager';
import { LoginCredentials, LoginResponse, LoginResponseManager } from '../models/class/user';
import { IOtpVerify } from '../models/interface/opt-verify';
import { IManagerPasswordReset } from '../models/interface/user-interface';

import { BaseService } from './base.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ManagerService extends UserService {
  readonly loginManagerUrl = BaseService.url + '/municipalities/manage/login';
  readonly resetPasswordUrl = BaseService.url + '/municipalities/manage/reset-password';

  constructor(protected http: HttpClient, public jwtHelper: JwtHelperService, public router: Router) {
    super(http);
    this.prefix = '/managers';
  }
  public loginOnlyPhone(credentials: LoginCredentials): Observable<LoginResponseManager> {
    if (credentials.fcm_token === null) delete credentials.fcm_token;
    return this.http.post(this.loginManagerUrl, credentials, {
      headers: BaseService.getHeaders(),
    }) as Observable<LoginResponseManager>;
  }

  /**
   * previously named getManager
   */
  public getUser(): Observable<Manager> {
    const userId = this.jwtHelper.decodeToken(getItem(StorageItem.token)).user_id;
    return this.http.get(this.getUrl() + '/' + userId, {
      headers: BaseService.getHeaders(),
    }) as Observable<Manager>;
  }

  public resetPasswordManager(passwords: IManagerPasswordReset): Observable<any> {
    return this.http.post(
      this.getUrl() + '/' + getItem(StorageItem.connected_user_id) + '/change-password',
      passwords,
      {
        headers: BaseService.getHeaders(),
      },
    );
  }

  public verifyResetPasswordOTP(phone: number | string, otp: number): Observable<IOtpVerify> {
    return this.http.post(this.resetPasswordUrl + '/verify', {
      otp: otp,
      phone_number: phone,
    }) as Observable<IOtpVerify>;
  }
}
