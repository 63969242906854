import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgenciesComponent } from './agencies.component';
import { AgenciesRoutingModule } from './agencies-routing.module';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbTreeGridModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbSpinnerModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { AngularOpenlayersModule } from 'ngx-openlayers';

import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import { AgenciesService } from '../../services/agencies.service';
import { CreateComponent } from './create/create.component';
@NgModule({
  declarations: [AgenciesComponent, ListeComponent, EditComponent, CreateComponent],
  imports: [
    CommonModule,
    AgenciesRoutingModule,
    ThemeModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    AngularOpenlayersModule,
    SpinnerModule,
    NbSpinnerModule,
  ],
  providers: [AgenciesService],
})
export class AgenciesModule {}
