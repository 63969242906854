import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  messages: any[] = [];
  isOpen: boolean = true;
  constructor() {}

  ngOnInit() {}
  toggleChat() {
    this.isOpen = !this.isOpen;
  }

  sendMessage(event: any, userName: string, avatar: string, reply: boolean) {
    this.messages.push({
      text: event.message,
      date: new Date(),
      reply: reply,
      user: {
        name: userName,
        avatar: avatar,
      },
    });
  }
}
