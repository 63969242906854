import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EventsService } from '../../../services/events.service';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { GenericEditWithImage } from '../../../core/models/class/GenericEditFeature';
import { Event } from '../../../core/models/class/event';
import * as $ from 'jquery';
import Backend from '../../../core/backend';
import { NgxImageCompressService } from 'ngx-image-compress';
import { isBase64, removeMarkupSpaces } from '../../../core';
@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditWithImage<Event> implements OnInit {
  manyDaysEvent = false;
  hasImage = false;
  baseUrl = Backend.baseUrl;
  isFileSizePassed = true;

  constructor(
    protected datePipe: DatePipe,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected _eventsService: EventsService,
    protected imageCompress: NgxImageCompressService,
  ) {
    super('id', _eventsService.prefix, _toastLuncher, _eventsService, router, route, imageCompress);
    this.item = new Event();
  }

  ngOnInit() {}

  protected normalizeAfterGetItem(event: Event): Event {
    event.starting_time = this.getTime(event.starting_time).toString();
    event.ending_time = this.getTime(event.ending_time).toString();
    event.date = new Date(Date.parse(event.date)).toString();
    event.starting_date = new Date(Date.parse(event.starting_date)).toString();
    event.ending_date = new Date(Date.parse(event.ending_date)).toString();
    if (event.starting_date === event.ending_date) {
      this.manyDaysEvent = false;
    }
    if (event?.image) this.hasImage = true;
    return event;
  }

  protected formIsValid(): boolean {
    if (!this.item.title || !this.item.description || !this.item.starting_date) {
      throw new Error('الرجاء تعمير بقية الخانات');
    }
    return true;
  }

  protected beforeSubmit(event: Event): Event {
    event.description = removeMarkupSpaces(event.description);
    if (!event.structure) event.structure = new Date().toString();
    event.starting_date = this.datePipe.transform(event.starting_date, 'yyyy-MM-dd');
    event.ending_date = this.datePipe.transform(event.ending_date, 'yyyy-MM-dd');
    event.date = event.starting_date;

    event.starting_time = this.formatTime(new Date(event.starting_time));
    event.ending_time = this.formatTime(new Date(event.ending_time));
    if (!this.manyDaysEvent) {
      event.ending_date = event.starting_date;
    }

    if (!event?.image || !isBase64(event.image)) delete event.image;

    return event;
  }

  handlePickerOpen() {
    const butt = document.getElementsByClassName('owl-dt-control-content owl-dt-control-button-content');
    butt[0].innerHTML = 'غلق';
    butt[1].innerHTML = 'اختيار';
  }

  getTime(time: string) {
    const timeString = time.split(':');
    const hours = parseInt(timeString[0], 10);
    const minutes = parseInt(timeString[1], 10);
    const seconds = parseInt(timeString[2].split('.')[0], 10);
    const d = new Date();
    d.setHours(hours);
    d.setSeconds(seconds);
    d.setMinutes(minutes);
    return d;
  }

  formatTime(time: Date) {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    return hours + ':' + minutes + ':' + seconds;
  }

  onImageLoad($event, compressedImage, ...args) {
    this.item.image = compressedImage;
  }
}
