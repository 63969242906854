// @ts-nocheck
import { PERMISSIONS, PermissionsEnum } from '../types';
import { User } from './user';

export class Manager extends User {
  id: number;
  phone_number: number;
  manager_id: number;
  email: string;
  name: string;
  title: string;
  is_active: boolean;
  is_deleted: boolean;
  complaint_categories: string[] = [];
  permissions: string[] = [];
  manage_dossiers: boolean = false;
  manage_procedures: boolean = false;
  manage_complaints: boolean = false;
  manage_forum: boolean = false;
  manage_events: boolean = false;
  manage_reports: boolean = false;
  manage_subject_access_requests: boolean = false;
  manage_committees: boolean = false;
  manage_news: boolean = false;
  manage_polls: boolean = false;
  manage_eticket: boolean = false;
  manage_permissions: boolean = false;
  password: string;
}
