import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Lightbox, LIGHTBOX_EVENT, LightboxEvent } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import Backend from '../../backend';

declare let $: any;
@Component({
  selector: 'app-imgloader',
  template:
    '<img (click)="open($event)" id="IMAGE_MODAL" style="object-fit : cover"   [class]="class" [style]="style" [attr.width]="width" [attr.height]="height" [src]="src" *ngIf="!hideIfInvalid || this.id !== \'0\' && this.id !== null && this.id !== \'\'"/>',
})
export class ImgloaderComponent implements OnChanges {
  @Input() id: string;
  @Input() class: string;
  @Input() style: string;
  @Input() height: number;
  @Input() hideIfInvalid = false;

  private album: any = [];
  @Input() width = 100;
  src: any;
  private subscription: Subscription;

  constructor(private lightbox: Lightbox, private lightboxEvent: LightboxEvent) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.id) {
      this.src = '../../assets/images/dropzone/placeholder_elbaladiya.jpg';
    } else {
      this.src = Backend.baseUrl + this.id;
    }
    const image = {
      src: this.src,
      caption: '',
      thumn: '',
    };
    this.album.push(image);
  }

  open(e: any): void {
    // open lightbox
    e.stopPropagation();
    $('.modal-backdrop').remove();
    $('.modal').modal('hide');
    this.lightbox.open(this.album, 0);
    // blur background without header and sidebar
    Array.from(document.getElementsByClassName('container') as HTMLCollectionOf<HTMLElement>).map(
      (el) => (el.style.filter = 'blur(3px)'),
    );
    // TO DO : still not working, remove black overlay
    // Array.from(document.getElementsByClassName("lightboxOverlay") as HTMLCollectionOf<HTMLElement>).map((el) => el.style.background = "#0000");

    // register your subscription and callback whe open lightbox is fired
    this.subscription = this.lightboxEvent.lightboxEvent$.subscribe((event) => this.onReceivedEvent(event));
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  private onReceivedEvent(event: any): void {
    // remember to unsubscribe the event when lightbox is closed
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      // remove blur
      Array.from(document.getElementsByClassName('container') as HTMLCollectionOf<HTMLElement>).map(
        (el) => (el.style.filter = 'blur(0px)'),
      );
      this.subscription.unsubscribe();
    }
  }
}
