import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { Topic } from '../../../core/models/class/topic';
import { TopicsService } from '../../../services/topics.service';
import { TopicStatesTypes } from '../../../core/models/types';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateFeature<Topic> implements OnInit {
  item = new Topic();
  topicStates = [
    {
      value: TopicStatesTypes.ACTIVATED,
      text: 'مفتوح للعموم',
    },
    {
      value: TopicStatesTypes.HIDDEN,
      text: 'مغلق',
    },
  ];

  constructor(
    public datePipe: DatePipe,
    private _topicsService: TopicsService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super(_topicsService.prefix, _toastLuncher, _topicsService, router);
    this.item.state = this.topicStates[0].value;
  }

  ngOnInit() {}

  formIsValid(): boolean {
    return true;
  }

  beforeSubmit(item: Topic): Topic {
    return item;
  }
}
