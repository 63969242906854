import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { getItem, StorageItem } from '../lib/local-storage.utils';
import { Registration } from '../models/class/registration';
import { LoginCredentials, LoginResponse, LoginResponseManager, User } from '../models/class/user';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  readonly registerUrl = BaseService.url + '/register';

  readonly loginUrl = BaseService.url + '/login';

  readonly profileUrl = BaseService.url + '/profile';

  readonly refreshTokenUrl = BaseService.url + '/token/refresh';

  readonly changePasswordUrl = BaseService.url + '/change-password';

  readonly resetPasswordUrl = BaseService.url + '/reset-password';

  constructor(protected http: HttpClient) {
    super();
  }

  public register(registration: Registration): Observable<any> {
    return this.http.post(this.registerUrl, registration) as Observable<any>;
  }

  public verifyOTP(phone: string, otp: string): Observable<any> {
    return this.http.post(this.registerUrl + '/verify-otp', {
      phone_number: phone,
      otp: otp,
    });
  }

  public login(
    phone: number,
    password: string,
    os: string,
    osVersion: string,
    model: string,
    devUUID: string,
  ): Observable<LoginResponse> {
    return this.http.post(this.loginUrl, {
      device_unique_id: devUUID,
      phone_number: phone,
      password: password,
      os: os,
      os_version: osVersion,
      model: model,
    }) as Observable<LoginResponse>;
  }

  public loginOnlyPhone(credentials: LoginCredentials): Observable<LoginResponse | LoginResponseManager> {
    return this.http.post(this.loginUrl, {
      ...credentials,
    }) as Observable<LoginResponse>;
  }

  public getUser(): Observable<User> {
    return (
      this.http.get(this.profileUrl, {
        headers: BaseService.getHeaders(),
      }) as Observable<User>
    ).pipe(first());
  }

  public refreshToken(): Observable<{ access: string }> {
    return this.http.post(this.refreshTokenUrl, {
      refresh: getItem(StorageItem.refresh_token),
    }) as Observable<{ access: string }>;
  }

  public editUser(user: User | { preferred_municipality_id: number }): Observable<User> {
    return this.http.put(this.profileUrl, user, {
      headers: BaseService.getHeaders(),
    }) as Observable<User>;
  }

  public getResetPasswordOTP(phone: number | string): Observable<any> {
    return this.http.post(this.resetPasswordUrl, {
      type: 'SMS',
      phone_number: phone.toString(),
    });
  }

  public verifyResetPasswordOTP(phone: number | string, otp: number): Observable<any> {
    return this.http.post(this.resetPasswordUrl + '/verify', {
      otp: otp,
      phone_number: phone,
    });
  }

  public resetPassword(password: string, token: string): Observable<any> {
    return this.http.post(
      this.changePasswordUrl,
      { new_password: password },
      { headers: BaseService.getHeadersFromToken(token) },
    );
  }
}
