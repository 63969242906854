import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../../../core/auth/auth-guard.service.nb';
import { ManagersService } from '../../../services/managers.service';
import { Manager } from '../../../core/models/class/manager';
import { setItem, StorageItem } from '../../../core';
import { ManagerFacade } from '../../../core/store';
import { ManagerService } from '../../../core/services/manager.service';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  newpassword: string;
  passwordAgain: string;
  passwordOld: string;
  manager: Manager = new Manager();
  isLoading: boolean = true;

  constructor(
    protected _toastLuncher: ToastLuncherService,
    public managersService: ManagersService,
    public _managerService: ManagerService,
    public authgardService: AuthGuardService,
    private _managerFacade: ManagerFacade,
  ) {
    this._managerFacade.loggedInUser$.subscribe((data) => {
      this.manager = data as Manager;
      this.isLoading = false;
    });
  }

  ngOnInit() {}

  isPermissionGranted(permission) {
    return this.manager.permissions.includes(permission);
  }

  updateprofile() {
    this.managersService.put(this.manager.id, this.manager).subscribe(
      (data) => {
        setItem(StorageItem.admin_name, this.manager.name);
        this._toastLuncher.success('تم حفظ البيانات بنجاح');
        setTimeout(() => {
          location.reload();
        }, 1500);
      },
      (error) => this.onSubmitError(error),
    );
  }

  updatepassword() {
    if (this.newpassword === this.passwordAgain && this.passwordOld?.length)
      this._managerService
        .resetPasswordManager({
          old_password: this.passwordOld,
          new_password: this.newpassword,
        })
        .subscribe(
          (res) => {
            this._toastLuncher.success('تم حفظ البيانات بنجاح');
          },
          (error) => this.onSubmitError(error),
        );
  }

  onSubmitError(error) {
    this.isLoading = false;
    if (error.status === 400) throw new Error('الرجاء التثبت من المعطيات المدرجة');
    if (error.status === 409) throw new Error('يوجد ملف اخر يحمل نفس المعطيات');
    if (error.status === 500) throw new Error('!لم نتمكن من الاتصال بالمنصة، ارجاء المحاولة لاحقاً.');
    else throw new Error('لم نتمكن من التعديل');
  }

  changeTheme(name: string) {
    setItem(StorageItem.THEME, name);
    location.reload();
  }
}
