import { Component, OnInit } from '@angular/core';
import { StaticTextsService } from '../../../services/static-texts.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent implements OnInit {
  constructor(public staticText: StaticTextsService) {
    this.getStaticText();
  }
  videosUrl;
  bookUrl;
  ngOnInit() {}
  getStaticText() {
    this.staticText.getStaticText('Admin Book Url').subscribe((data) => {
      this.bookUrl = data.body;
    });
    this.staticText.getStaticText('Admin Videos Url').subscribe((data) => {
      this.videosUrl = data.body;
    });
  }
  toBookUrl() {
    window.open(this.bookUrl, '_blank');
  }
  toVideosUrl() {
    window.open(this.videosUrl, '_blank');
  }
}
