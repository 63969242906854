import { Pipe, PipeTransform } from '@angular/core';
import { logTypes } from '../pages/batch-upload/batch-upload.types';

@Pipe({
  name: 'logColor',
})
export class LogColorPipe implements PipeTransform {
  transform(value: logTypes): string {
    switch (value) {
      case logTypes.ERROR:
        return 'red';
      case logTypes.SUCCESS:
        return 'green';
      default:
        return 'black';
    }
  }
}
