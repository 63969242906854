import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, StorageItem } from '../core';
import { Service } from '../core/models/class/services';
import { BaseService } from '../core/services/base.service';

import { GenericCRUDService } from '../core/services/generic.service';
import { MunisFacade } from '../core/store';
@Injectable({
  providedIn: 'root',
})
export class ServicesService extends GenericCRUDService<Service> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/agencies/';
    this.suffix = '/';
  }

  getUrl() {
    return (
      BaseService.url +
      '/municipalities/' +
      MunisFacade.getMunicipalityId() +
      this.prefix +
      getItem(StorageItem.agencyId) +
      '/services'
    );
  }
}
