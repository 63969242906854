import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoriqueComponent } from './historique.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbTreeGridModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbSpinnerModule,
} from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '../loading-spinner/spinner.module';
import { OurCommonModule } from '../../core/OurCommon.module';

@NgModule({
  declarations: [HistoriqueComponent],
  imports: [
    NbTreeGridModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    SpinnerModule,
    NbUserModule,
    CommonModule,
    FormsModule,
    ThemeModule,
    NbSpinnerModule,
    OurCommonModule,
  ],
  exports: [HistoriqueComponent],
})
export class HistoriqueModule {}
