import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { dossierTypes, normalizedPDF } from '../../../core';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { Report } from '../../../core/models/class/report';
import { CommitteeService } from '../../../services/committee.service';
import { ReportService } from '../../../services/report.service';
import { Committee } from '../../../core/models/class/committee';
import { BaseService } from '../../../core/services/base.service';
import { MunisFacade } from '../../../core/store';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateFeature<Report> implements OnInit {
  item = new Report();
  committees: Committee[] = [];
  types = dossierTypes;

  selectedFileName: string;
  fileSize: number;
  isFilePDF: boolean = true;
  isFileSizeValid: boolean = true;
  isUploading = false;

  constructor(
    public datePipe: DatePipe,
    protected _reportService: ReportService,
    private _committeeService: CommitteeService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super(_reportService.prefix, _toastLuncher, _reportService, router);
    this.getCommittees();
  }

  ngOnInit() {}

  formIsValid(): boolean {
    if (this.item.title === '' && this.item.committee_id === 0) throw new Error('الرجاء ملء كل الفراغات');
    return true;
  }

  beforeSubmit(item: Report) {
    item.date = this.datePipe.transform(item.date, 'yyyy-MM-dd');
    return item;
  }

  getCommittees() {
    this._committeeService.get().subscribe((data) => {
      this.committees = data;
      this.item.committee_id = data[0].id;
      this.item.municipality_id = MunisFacade.getMunicipalityId();
    });
  }

  openSelectFileDialog() {
    document.getElementById('input_file').click();
  }

  onFileChange(fileInput) {
    const reader2 = new FileReader();
    reader2.onload = (e) => {
      this.item.attachment = normalizedPDF(reader2.result);
      this.selectedFileName = fileInput.target.files[0].name;
      this.fileSize = fileInput.target.files[0].size;
    };
    if (fileInput.target.files.length) {
      if (fileInput.target.files[0].size < 5300000 * 2 && fileInput.target.files[0].type.match('application/pdf')) {
        this.isFileSizeValid = true;
        this.isFilePDF = true;
        this.isLoading = false;
        reader2.readAsDataURL(fileInput.target.files[0]);
      } else if (!fileInput.target.files[0].type.match('application/pdf')) {
        this.isLoading = true;
        this.isFilePDF = false;
        this.isFileSizeValid = true;
        this.selectedFileName = 'Only PDF Files could be read';
        throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
      } else {
        $('#helpModal').modal('toggle');
        this.isLoading = true;
        this.isFileSizeValid = false;
        this.selectedFileName = 'Maximum file size is 10MB';
        throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
      }
    } else {
      this.selectedFileName = 'No files selected';
      this.isFilePDF = true;
    }
  }
}
