export default {
  actions: false,
  columns: {
    title: {
      title: 'العنوان',
      filter: false,
      valuePrepareFunction: (cell, row) => {
        return row.course.title;
      },
    },
    status: {
      title: 'الحالة',
      filter: false,
      valuePrepareFunction: (cell, row) => {
        return 'غير مكتمل';
      },
    },
    enrollDate: {
      title: 'تاريخ الاشتراك',
      filter: false,
      valuePrepareFunction: (cell, row) => {
        const formattedDate = new Date(row.course.created_at);
        return formattedDate.toLocaleDateString();
      },
    },
  },
};
