import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbLayoutScrollService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import { TopicsService } from '../../../services/topics.service';
import tableSettings from './liste.config';
import { Topic } from '../../../core/models/class/topic';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Topic> implements OnInit {
  constructor(
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    public datePipe: DatePipe,
    protected scroll: NbLayoutScrollService,
    private _topicsService: TopicsService,
  ) {
    super(_topicsService.prefix, scroll, _topicsService, router, _toastLuncher);
  }
  settings = tableSettings;

  ngOnInit() {
    this.settings.columns.state.filter.config.list = [
      { value: 'HIDDEN', title: 'مفتوح للعموم' },
      { value: 'ACTIVATED', title: 'مغلق' },
    ];
  }

  preSavingList(data: Topic[]) {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    data.forEach((item) => {
      item.created_at = this.datePipe.transform(item.created_at, 'yyyy-MM-dd');
      // item.published_at = GeneralService.timeAgo((Date.now() - Date.parse(item.published_at)) / 1000);
    });
    return data;
  }

  goToForum() {
    this.router.navigate(['pages/forums/liste']);
  }
}
