import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getItem, StorageItem } from '../../core';
import Backend from '../../core/backend';

@Component({
  selector: 'ngx-share-single-view',
  templateUrl: './share-single-view.component.html',
  styleUrls: ['./share-single-view.component.css'],
})
export class ShareSingleViewComponent implements OnInit {
  @Input() feature: string = '';
  id: string;
  link: string;

  constructor(private route: ActivatedRoute) {
    const allParams = this.route.snapshot.params; // allParams is an object
    this.id = Object.values(allParams)[0];
  }
  ngOnInit(): void {}
  shareToFacebook() {
    // WORKAROUND to getting base citizen URL
    const front = Backend.frontUrl.includes('dev') ? 'https://dev-citizen.elbaladiya.tn/' : 'https://elbaladiya.tn/';
    this.link = `${front}/${getItem(StorageItem.municipality_route_name)}/${this.feature}/${this.id}`;
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + this.link,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes',
    );
  }
}
