import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { MailingListService } from '../../../services/mailing-list.service';
import { NbLayoutScrollService } from '@nebular/theme';
import { Email } from '../../../core/models/interface/email';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Email> implements OnInit {
  constructor(
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected scroll: NbLayoutScrollService,
    private _mailingListService: MailingListService,
  ) {
    super(_mailingListService.prefix, scroll, _mailingListService, router, _toastLuncher);
  }
  settings = {
    mode: 'external',
    pager: { display: false },
    noDataMessage: 'لا يوجد ايمايلات (برد الكترونية)',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-compose"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      enum: {
        title: '#',
        type: 'number',
        width: '10%',
      },
      email: {
        title: 'البريد الالكتروني',
        type: 'string',
      },
    },
  };

  ngOnInit() {}

  addRow() {
    this.navigateTo(`/pages/${this.route}/edit/-1`);
  }
}
