import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagersService } from '../../../services/managers.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Manager } from '../../../core/models/class/manager';
import { defaultAnimation, getItem, isValidPhoneNumber, StorageItem, validateEmail } from '../../../core';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { PermissionsEnum } from '../../../core/models/types';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  ...defaultAnimation,
})
export class EditComponent extends GenericEditFeature<Manager> implements OnInit {
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: true,
    idField: 'id',
    textField: 'title',
    selectAllText: 'الكل',
    searchPlaceholderText: 'البحث',
    unSelectAllText: 'الكل',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  complaint_categories: string[] = getItem(StorageItem.complaint_categories)?.split(',');

  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected _managersService: ManagersService,
  ) {
    super('id', _managersService.prefix, _toastLuncher, _managersService, router, route);
  }

  ngOnInit() {}

  protected formIsValid(): boolean {
    const permissions =
      this.item.manage_committees ||
      this.item.manage_forum ||
      this.item.manage_eticket ||
      this.item.manage_polls ||
      this.item.manage_complaints ||
      this.item.manage_events ||
      this.item.manage_news ||
      this.item.manage_procedures ||
      this.item.manage_reports ||
      this.item.manage_subject_access_requests ||
      this.item.manage_permissions ||
      this.item.manage_dossiers;

    if (!this.item.phone_number || !isValidPhoneNumber(this.item.phone_number))
      throw new Error(' الرجاء إدخال رقم هاتف صحيح ');

    if (!validateEmail(this.item.email)) throw new Error('ارجاء إدخال بريد إلكتروني صالح');

    if (!this.item.title && !this.item.name) throw new Error('ارجاء إدخال إسم و صفة');

    if (!permissions) throw new Error('ارجاء منح صلاحية واحدة على أقل');

    return true;
  }

  enablePermission(permission: PermissionsEnum) {
    this.item[permission] = !this.item[permission];
  }

  protected normalizeAfterGetItem(item: Manager): Manager {
    item.manage_dossiers = item.permissions.includes(PermissionsEnum.MANAGE_DOSSIERS);
    item.manage_procedures = item.permissions.includes(PermissionsEnum.MANAGE_PROCEDURES);
    item.manage_complaints = item.permissions.includes(PermissionsEnum.MANAGE_COMPLAINTS);
    item.manage_forum = item.permissions.includes(PermissionsEnum.MANAGE_FORUM);
    item.manage_events = item.permissions.includes(PermissionsEnum.MANAGE_EVENTS);
    item.manage_reports = item.permissions.includes(PermissionsEnum.MANAGE_REPORTS);
    item.manage_subject_access_requests = item.permissions.includes(PermissionsEnum.MANAGE_SUBJECT_ACCESS_REQUESTS);
    item.manage_committees = item.permissions.includes(PermissionsEnum.MANAGE_COMMITTEES);
    item.manage_news = item.permissions.includes(PermissionsEnum.MANAGE_NEWS);
    item.manage_polls = item.permissions.includes(PermissionsEnum.MANAGE_POLLS);
    item.manage_permissions = item.permissions.includes(PermissionsEnum.MANAGE_PERMISSIONS);
    item.manage_eticket = item.permissions.includes(PermissionsEnum.MANAGE_ETICKET);
    return item;
  }

  onManagePermissionsClick() {
    if (window.confirm('هل تريد فعلا تغير حقوق التصرف في كل المتصرفين في المنصة ؟\n'))
      this.item.manage_permissions = !this.item.manage_permissions;
  }
}
