import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '../../../services/events.service';
import { NbLayoutScrollService } from '@nebular/theme';

import { Event } from '../../../core/models/class/event';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getTime } from '../../../core';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Event> implements OnInit {
  constructor(
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected scroll: NbLayoutScrollService,
    private _eventsService: EventsService,
    private modalService: NgbModal,
  ) {
    super(_eventsService.prefix, scroll, _eventsService, router, _toastLuncher);
  }

  settings = {
    mode: 'external',
    pager: { display: false },
    noDataMessage: 'لا يوجد مواعيد و أنشطة',

    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      title: {
        title: 'العنوان',
        type: 'string',
      },
      description: {
        title: 'الوصف',
        type: 'html',
        width: '18%',
        valuePrepareFunction: (description: string) => {
          const maxLength = 200;
          return description?.length < maxLength ? description : description.substring(0, maxLength) + ' ... ';
        },
      },
      location: {
        title: 'المكان',
        type: 'string',
      },
      starting_date: {
        title: 'تاريخ البداية',
        type: 'date',
      },
      ending_date: {
        title: 'تاريخ النهاية',
        type: 'date',
      },
      starting_time: {
        title: 'ساعة البداية',
        type: 'string',
        valuePrepareFunction: (starting_time: string) => {
          return getTime(starting_time).toLocaleTimeString();
        },
      },
      ending_time: {
        title: 'ساعة النهاية',
        type: 'string',
        valuePrepareFunction: (ending_time: string) => {
          return getTime(ending_time).toLocaleTimeString();
        },
      },
      number_of_interested_citizen: {
        title: 'عدد المهتمين',
        type: 'number',
        width: '3%',
      },
      number_of_participants: {
        title: 'عدد المشاركين',
        type: 'number',
        width: '3%',
      },
    },
  };

  ngOnInit() {}

  openModal(modal) {
    this.modalService.open(modal, { size: 'md' });
  }
}
