import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { DossiersService } from '../../../services/dossiers.service';

import {
  defaultAnimation,
  dossierTypes,
  isValidCitizenId,
  isValidIdentifier,
  isValidPhoneNumber,
  ItemIsOpenForProcessing,
  timeAgo,
  toArabicStatus,
} from '../../../core';
import { UpdateTypes } from '../../../core/models/types';
import { GenericListFeatureWithStatus } from '../../../core/models/class/GenericListFeature';
import { Dossier } from '../../../core/models/class/dossier';
import { NbLayoutScrollService } from '@nebular/theme';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
  ...defaultAnimation,
})
export class ListeComponent extends GenericListFeatureWithStatus<Dossier> implements OnInit {
  DELETE_TEXT = 'تم حذف المطلب بنجاح';
  filteredData;
  filterClosed = false;
  settings = {
    mode: 'external',
    pager: { display: false },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    noDataMessage: 'لا يوجد مطالب رخص',
    columns: {
      unique_identifier: {
        title: 'الرقم الوحيد',
        type: 'string',
      },
      name: {
        title: 'إسم صاحب المطلب',
        type: 'string',
      },
      cin_number: {
        title: 'رقم بطاقة التعريف',
        type: 'number',
      },
      status: {
        title: 'الحالة',
        type: 'string',
        filter: {
          type: 'list',
          config: {
            selectText: 'الكل',
            list: GeneralService.filterLabels,
          },
        },
        valuePrepareFunction: (status) => {
          return toArabicStatus(status);
        },
      },

      type: {
        title: 'نوع المطلب',
        type: 'string',
        filter: {
          type: 'list',
          config: {
            selectText: 'الكل',
            list: dossierTypes.map((type) => {
              return { value: type.displayName, title: type.displayName };
            }),
          },
        },
      },
      deposit_date: {
        title: 'تاريخ الإيداع',
        type: 'string',
      },
      last_traitement_date: {
        title: 'اخر تعديل',
        type: 'string',
      },
      followings_state: {
        title: 'متابعة',
        type: 'string',
        width: '5%',
      },
    },
  };

  // file headers
  batchKeys: string[] = ['name', 'unique_identifier', 'cin_number', 'phone_number', 'type', 'deposit_date'];
  // values to translate
  batchValues: string[][] = [dossierTypes.map((i) => i.displayName), dossierTypes.map((i) => i.name)];

  constructor(
    public router: Router,
    protected scroll: NbLayoutScrollService,
    protected _toastLuncher: ToastLuncherService,
    private dossiersService: DossiersService,
    private modalService: NgbModal,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(dossiersService.prefix, scroll, dossiersService, router, _toastLuncher, activatedRoute);
  }

  ngOnInit() {}

  handleFilterClosedItems(event) {
    if (this.filterClosed === true) {
      this.filteredData = this.data.filter(ItemIsOpenForProcessing);
      this.load(this.filteredData);
    } else {
      this.load(this.data);
    }
  }

  preSavingList(data: Dossier[]) {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    data.forEach((item: any) => {
      item.status = item.updates[0].status || UpdateTypes.PROCESSING;
      item.type = dossierTypes.filter((type) => type.name === item.type || type.name === 'OTHER')[0].displayName;
      item.last_traitement_date = timeAgo(item.updates[0].date);
      item.followings_state = item.followers?.length > 0 ? 'متابع' : 'غير متابع';

      return item;
    });
    return data;
  }

  formIsValid(item: Dossier, throws = false): boolean {
    if (!item.phone_number || !isValidPhoneNumber(item.phone_number)) {
      if (throws) throw new Error('رقم الهاتف غير صالح');
      return false;
    }

    if (!item.unique_identifier || !isValidIdentifier(item.unique_identifier.toString())) {
      if (throws) throw new Error('المعرّف الوحيد غير صالح');
      return false;
    }

    if (!item.cin_number || !isValidCitizenId(item.cin_number)) {
      if (throws) throw new Error('رقم الهوية غير صالح');
      return false;
    }
    if (!item.name || !item.name.length) {
      if (throws) throw new Error('الاسم غير صحيح');
      return false;
    }

    return true;
  }

  openModal(modal) {
    this.modalService.open(modal, { size: 'md' });
  }
}
