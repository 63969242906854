import { Component, OnInit } from '@angular/core';
import { Course } from '../../../core/models/class/course';
import { Router } from '@angular/router';
import { CoursesService } from '../../../services/courses.service';
import Backend from '../../../core/backend';
import tableSettings from './home.config';
import { defaultAnimation } from '../../../core';
import { CourseUtility } from '..';

@Component({
  selector: 'ngx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../elearning.component.scss'],
  ...defaultAnimation,
})
export class HomeComponent extends CourseUtility implements OnInit {
  recentCourses: Array<Course> = [];
  mostSubscribedCourses: Array<Course> = [];
  baseUrl = Backend.baseUrl;
  settings = tableSettings;
  data: Array<Course> = [];

  constructor(protected _courseService: CoursesService, protected router: Router) {
    super(_courseService, router);
  }
  ngOnInit() {
    this._courseService.get().subscribe((data) => (this.recentCourses = data.slice(0, 3)));
    this._courseService.getMostSubscribed().subscribe((data) => (this.mostSubscribedCourses = data.slice(0, 3)));
    this._courseService
      .getLatestSubscribed()
      .subscribe(
        (data) => (this.data = data.filter((data) => data.status === 'NOT_STARTED' || data.status === 'LEARNING')),
      );
  }
}
