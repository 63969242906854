import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map, tap } from 'rxjs/operators';
import { getItem, setItem, StorageItem } from '../../../index';
import { LoginResponse, LoginResponseManager } from '../../../models/class/user';

import {
  LoginStart,
  UserActionTypes,
  LoginSuccess,
  LoginFailure,
  LoadUser,
  UserLoaded,
  RefreshTokenStart,
  RefreshTokenDone,
} from './user.action';
import { UserState } from './user.reducer';
import { ManagerService } from '../../../services/manager.service';
import { IUserEffect } from '../../../models/interface/user.effect.interface';
import { Manager } from '../../../models/class/manager';
import { Router } from '@angular/router';
import { ManagerFacade } from './manager.facade';
import { MunisFacade } from '../municipality/municipality.facade';

@Injectable({ providedIn: 'root' })
export class ManagerEffects implements IUserEffect {
  constructor(
    private actions$: Actions,
    private router: Router,
    protected _managerFacade: ManagerFacade,
    private _managerService: ManagerService,
    public _munisFacade: MunisFacade,
    private dataPersistence: DataPersistence<UserState>,
  ) {}

  @Effect()
  login$ = this.dataPersistence.fetch(UserActionTypes.LoginManagerStart, {
    run: (action: LoginStart, state: UserState) => {
      return this._managerService.loginOnlyPhone(action.payload).pipe(
        map((res: LoginResponseManager) => {
          setItem(StorageItem.token, res.access);
          setItem(StorageItem.refresh_token, res.refresh);
          setItem(StorageItem.municipality, res.municipality_id.toString());
          setItem(StorageItem.connected_user_id, new JwtHelperService().decodeToken(res.access).user_id);
          this._munisFacade.setMuniFromId(res.municipality_id);
          return new LoginSuccess(res);
        }),
        tap((action) => {
          this._managerFacade.loadUser();
        }),
      );
    },

    onError: (action: LoginStart, error) => {
      this._managerFacade.loginFailure();
      this._managerFacade.logout();
      this.router.navigate([`/login`]);
      throw new Error('لم نتمكن من تسجيل الدخول بنجاح');
    },
  });

  @Effect()
  loadUser$ = this.dataPersistence.fetch(UserActionTypes.LoadManager, {
    run: (action: LoadUser, state: UserState) => {
      return this._managerService.getUser().pipe(
        map((manager: Manager) => {
          setItem(StorageItem.admin_name, manager.name);
          setItem(StorageItem.permissions, manager.permissions.join());
          setItem(StorageItem.complaint_categories, manager.complaint_categories.join());
          const hasMunicipality = getItem(StorageItem.municipality);
          if (hasMunicipality !== null) this._munisFacade.setMuniFromId(parseInt(hasMunicipality));
          return new UserLoaded(manager);
        }),
        tap((action) => {
          const str = window.location.href;
          if (str.split('/')?.includes('login')) this.router.navigate([`/pages/dashboard`]);
        }),
      );
    },
    onError: (action: LoadUser, error) => {
      this._managerFacade.logout();
      this.router.navigate([`/login`]);
      throw new Error('لم يتم العثور على مستخدم');
    },
  });

  @Effect()
  refreshToken$ = this.dataPersistence.fetch(UserActionTypes.RefreshTokenStart, {
    run: (action: RefreshTokenStart, state: UserState) => {
      return this._managerService.refreshToken().pipe(
        map((res: { access: string }) => {
          setItem(StorageItem.token, res.access);
          return new RefreshTokenDone(res.access);
        }),
      );
    },

    onError: (action: RefreshTokenStart, error) => {
      this._managerFacade.logout();
      this.router.navigate([`/login`]);
      throw new Error('لا يمكن استخدام البيانات القديمة الرجاء تسجيل الدخول إلى حسابك');
    },
  });
}
