import { UpdateTypes } from '../types';

export class Update {
  id: number;
  date: string;
  created_by: number;
  created_by_name: string;
  note: string = '';
  status: UpdateTypes = UpdateTypes.RECEIVED;
  image: string;
  attachment?: string;
}
