import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { CoursesService } from '../../services/courses.service';

@Injectable({
  providedIn: 'root',
})
export class ElearningGuard {
  constructor(public router: Router, private _courseService: CoursesService) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const id = route.paramMap.get('id');
    const data = await this._courseService.checkEnrollment(id).toPromise();;
    if (!data.enrolled) {
      this.router.navigate(['/pages/course-detail', id]);
      return false;
    }
    return true;
  }
}
