export default {
  actions: false,
  columns: {
    title: {
      title: 'العنوان',
      filter: false,
    },
    status: {
      title: 'الحالة',
      filter: false,
      valuePrepareFunction: (cell, row) => {
        switch (row.status) {
          case 'NOT_STARTED':
            return 'الدورة التدريبية لم تبدأ بعد';
          case 'LEARNING':
            return 'غير مكتمل';
          default:
            'مكتمل';
        }
      },
    },
    enrollDate: {
      title: 'تاريخ الاشتراك',
      filter: false,
      valuePrepareFunction: (cell, row) => {
        const formattedDate = new Date(row.created_at);
        return formattedDate.toLocaleDateString();
      },
    },
  },
};
