import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { defaultAnimation } from '../../core';
import { GenericCRUDService } from '../../core/services/generic.service';
import { ToastLuncherService } from '../../core/services/toast-luncher.nb.service';
import { intructions } from './batch-upload.data';
import { IlogItem, logTypes } from './batch-upload.types';
import { finalize } from 'rxjs/operators';

const TIME_VALUES = ['created_at', 'deposit_date'];
@Component({
  selector: 'ngx-batch-upload',
  templateUrl: './batch-upload.component.html',
  styleUrls: ['./batch-upload.component.css'],
  ...defaultAnimation,
})
export class BatchUploadComponent<T> implements OnInit {
  @Input() _service: GenericCRUDService<T>;
  @Input() ItemIsvalid: (item: T) => boolean;
  @Input() XLSX_templateLink: string;
  @Input() translateKeys: string[] = [];
  @Input() translateValues: string[] = [];

  showInfoPanel: boolean = false;
  listToUpload: T[] = [];
  selectedFileName: string;
  intructions = intructions;

  logs: IlogItem[] = [];
  constructor(protected _toastLuncher: ToastLuncherService, private modalService: NgbModal) {}

  ngOnInit(): void {}

  onFileChange(ev: any) {
    this.listToUpload = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.selectedFileName = ev.target.files[0].name;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      for (const [key, value] of Object.entries(jsonData)) this.listToUpload = this.listToUpload.concat(value as T);

      if (this.listToUpload.length > 0) {
        this.batchSubmit(this.listToUpload.map((item) => this.serialized(item)));
      } else {
        throw new Error('الملف الذي تم تحميله فارغ');
      }
    };
    reader.readAsBinaryString(file);
  }

  serialized(obj: any): any {
    let newObj = {};
    const values = Object.values(obj);
    this.translateKeys.forEach((key, idx) => {
      // translate keys
      newObj[key] = values[idx];

      // translate if it's a dropdowns
      const occurence = this.translateValues[0].indexOf(newObj[key]);
      if (occurence != -1) newObj[key] = this.translateValues[1][occurence];

      // format time values: from excel format -> our backend format
      if (TIME_VALUES.includes(key)) newObj[key] = this.excelDateToJSDate(newObj[key]); // YYYY-MM-DD
    });

    return newObj;
  }

  excelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate()).toLocaleDateString('sv');
  }

  private batchSubmit(list: T[]) {
    list.forEach((item, i) => {
      if (this.ItemIsvalid(item))
        this._service
          .post(item)
          .pipe(
            finalize(() => {
              if (this.listToUpload?.length === this.logs?.length) {
                const rows = this.logs
                  .filter((item) => item.type === logTypes.ERROR)
                  .map((r) => r.id)
                  .toString();
                if (rows?.length)
                  this.logs.push({
                    type: logTypes.INFO,
                    text: ' إنهاء التحميل: لم نتمكن من إضافة الصفوف التالية ' + rows,
                    id: 0,
                  });
              }
            }),
          )
          .subscribe(
            (data) => {
              this._toastLuncher.success('تمت الإضافة الصف : ' + (i + 2) + ' بنجاح ');
              this.logs.push({
                type: logTypes.SUCCESS,
                text: 'تمت الإضافة الصف : ' + (i + 2) + ' بنجاح ',
                id: i + 2,
              });
            },
            (error) => {
              let message: string;
              if (error.status === 400) message = 'الرجاء التثبت من المعطيات المدرجة';
              else if (error.status === 409) message = 'يوجد ملف اخر يحمل نفس المعطيات';
              else if (error.status === 500) message = 'لم نتمكن من الاتصال بالمنصة، ارجاء المحاولة لاحقاً.';
              else message = 'لم نتمكن من إضافة';

              this.logs.push({
                type: logTypes.ERROR,
                text: message + ' الصف عدد ' + (i + 2),
                id: i + 2,
              });
            },
          );
      else
        this.logs.push({
          type: logTypes.ERROR,
          text: 'لم نتمكن من إضافة' + ' الصف عدد ' + (i + 2),
          id: i + 2,
        });
    });
  }

  cancelUpload() {
    // cancel upload or change excel file
    if (this.listToUpload?.length) {
      if (window.confirm('هل تريد فعلاً إيقاف عملية التحميل ؟')) this.listToUpload = [];
    } else {
      this.openUpload();
    }
  }

  openUpload() {
    document.getElementById('excelUpload').click();
  }

  closeModal(e) {
    e.stopPropagation;
    this.modalService.dismissAll();
  }
}
