import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../../../services/general.service';
import { DatePipe } from '@angular/common';
import { PollsService } from '../../../services/polls.service';
import { PollsChoiceService } from '../../../services/polls-choice.service';
import { ShareSingleViewComponent } from '../../../components/share-single-view/share-single-view.component';
import * as $ from 'jquery';
import { Poll } from '../../../core/models/class/polls';
import { getPollStatus, setItem, StorageItem } from '../../../core';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { PollKindTypes, PollStatusTypes } from '../../../core/models/types';
import Backend from '../../../core/backend';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Poll> implements OnInit {
  loadedImg = false;
  baseUrl = Backend.baseUrl;
  isFileSizePassed = true;
  allResults = false;
  hasImage = false;
  pollTypes = [
    { text: 'يمكن التصويت على أكثر من خيار', value: 'MULTI_CHOICE' },
    { text: 'إختيار واحد', value: 'SINGLE_CHOICE' },
  ];
  @ViewChild(ShareSingleViewComponent)
  shareSingleView: ShareSingleViewComponent;

  constructor(
    public router: Router,
    public datePipe: DatePipe,
    public route: ActivatedRoute,
    protected _toastLuncher: ToastLuncherService,
    private _choicesService: PollsChoiceService,
    private _pollsService: PollsService,
  ) {
    super('id', _pollsService.prefix, _toastLuncher, _pollsService, router, route);
    this.item = new Poll();
  }

  ngOnInit() {}
  private _startDate: Date;
  private _time: string;
  private _endDate: Date;
  private _endTime: string;

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;
    this.updateEndsAt();
  }

  get endTime(): string {
    return this._endTime;
  }

  set endTime(value: string) {
    this._endTime = value;
    this.updateEndsAt();
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
    this.updateStartsAt();
  }

  get time(): string {
    return this._time;
  }

  set time(value: string) {
    this._time = value;
    this.updateStartsAt();
  }
  updateStartsAt() {
    if (this.startDate && this.time) {
      const startsAtDate = new Date(this.startDate);
      const [hours, minutes] = this.time.split(':');
      startsAtDate.setHours(Number(hours));
      startsAtDate.setMinutes(Number(minutes));

      const dateTimeString = startsAtDate.toISOString().split('.')[0] + 'Z';
      this.item.starts_at = dateTimeString;
    }
  }
  updateEndsAt() {
    if (this.endDate && this.endTime) {
      const endsAtDate = new Date(this.endDate);
      const [hours, minutes] = this.endTime.split(':');
      endsAtDate.setHours(Number(hours));
      endsAtDate.setMinutes(Number(minutes));

      const dateTimeString = endsAtDate.toISOString().split('.')[0] + 'Z';
      this.item.ends_at = dateTimeString;
    }
  }

  protected normalizeAfterGetItem(item) {
    item.total_votes = typeof item.total_votes === 'string' ? 'غير متاح' : item.total_votes;
    this.getBase64ImageFromURL(this.baseUrl + item.picture);
    const status = getPollStatus(item.starts_at, item.ends_at);
    item.status = this.translateStatusEN(status) as any;
    item.kind = item.kind === 'MULTI_CHOICE' ? ' يمكن التصويت على أكثر من خيار' : 'إختيار واحد';
    item.choices.map((c) => {
      c.total_local_votes = typeof c.total_local_votes === 'string' ? 'غير متاح' : c.total_local_votes;
    });
    return item;
  }

  protected formIsValid(): boolean {
    if (!this.item.text || !this.item.title || !this.item.choices.length || !this.item.starts_at || !this.item.ends_at)
      throw new Error('الرجاء ملء كل الفراغات');

    return true;
  }

  protected beforeSubmit(item) {
    item = {
      ...item,
      kind: item.kind === 'يمكن التصويت على أكثر من خيار' ? PollKindTypes.MULTI_CHOICE : PollKindTypes.SINGLE_CHOICE,
      starts_at: new Date(item.starts_at).toISOString(),
      ends_at: new Date(item.ends_at).toISOString(),
    };
    if (!item.picture) delete item.picture;
    return item;
  }

  translateStatusEN(status) {
    switch (status) {
      case PollStatusTypes.IN_PROGRESS:
        return ' مفتـوح للتـصويت ';
      case PollStatusTypes.NOT_STARTED:
        return ' لم ينطلق التصـويت بعد ';
      // defaults to ENDED
      default:
        return ' ﺗﻢ ﻧﺸﺮ اﻟﻨﺘـﺎﺋﺞ ';
    }
  }

  import(id: string) {
    document.getElementById(id).click();
  }

  onChangeImagefile(fileInput, index: number) {
    this.hasImage = false;
    const reader2 = new FileReader();
    let src;
    reader2.onload = (e) => {
      src = reader2.result;
      // setTimeout(() => {
      //   const image = document.getElementById('image_500');
      //   const image1 = document.getElementById('image_500_1');
      //   if (image) image.setAttribute('src', src);
      //   if (image1) image1.setAttribute('src', src);
      // });
      // this.news.image = src;
      if (index !== null) this.item.choices[index].picture = src;
      else this.item.picture = src;
    };
    if (fileInput.target.files[0].size < 5300000 * 2) {
      this.isFileSizePassed = true;
      reader2.readAsDataURL(fileInput.target.files[0]);
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = false;
      throw new Error('الرجاء ادخال صورة لا تتجاوز 10MB');
    }
  }

  onImagesChanged(images) {
    this.item.picture = images;
  }

  getBase64ImageFromURL(url: string) {
    // create an image object
    const img = new Image();

    img.crossOrigin = 'Anonymous';
    img.src = url;
    if (!img.complete) {
      // This will call another method that will create image from url

      img.onload = () => {
        this.item.picture = this.getBase64Image(img);
      };
    } else {
      this.item.picture = this.getBase64Image(img);
    }
  }

  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    return canvas.toDataURL('image/jpeg');
  }
}
