import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import './ckeditor.loader';
import 'ckeditor';
import { CommitteeService } from '../../../services/committee.service';
import { NewsService } from '../../../services/news.service';
import { ImageSliderComponent } from '../../../components/image-slider/image-slider/image-slider.component';
import { ShareSingleViewComponent } from '../../../components/share-single-view/share-single-view.component';
import * as $ from 'jquery';
import { News } from '../../../core/models/class/news';
import { Committee } from '../../../core/models/class/committee';
import { getItem, newsCategories, StorageItem } from '../../../core';
import { GenericEditWithImage } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import Backend from '../../../core/backend';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditWithImage<News> implements OnInit {
  @ViewChild(ImageSliderComponent, { static: false })
  childC: ImageSliderComponent;
  @ViewChild(ShareSingleViewComponent)
  shareSingleView: ShareSingleViewComponent;

  item = new News();
  loadedImg = false;
  committees: Committee[] = [];
  baseUrl = Backend.baseUrl;
  muni_website = getItem(StorageItem.muni_website);
  tagsToAdd = '';
  newsCategories = newsCategories;
  newsTags = [];

  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected _committeeService: CommitteeService,
    protected _newsService: NewsService,
    protected imageCompress: NgxImageCompressService,
  ) {
    super('id', _newsService.prefix, _toastLuncher, _newsService, router, route, imageCompress);
    this.getCommittees();
    this.getNewsTags();
  }

  ngOnInit() {
    document.getElementById('fbfb').setAttribute('href', this.muni_website);
    document.getElementById('citee').setAttribute('cite', this.muni_website);
    document.getElementById('a-fb').setAttribute('href', this.muni_website);
  }

  private getCommittees() {
    this._committeeService.get().subscribe((data) => {
      this.committees = data;
    });
  }

  getNewsTags() {
    this._newsService.getNewsTags().subscribe((data) => {
      this.newsTags = data;
    });
  }

  protected normalizeAfterGetItem(item) {
    item.images.forEach((image, index) => {
      this.getBase64ImageFromURL(this.baseUrl + image, index);
    });
    return item;
  }

  protected formIsValid() {
    if (!this.item.title || !this.item.committee_id || !this.item.body) {
      throw new Error('الرجاء ملء كل الفراغات');
    }
    return true;
  }

  protected beforeSubmit(item: News): News {
    if (item.attachment === null) delete item.attachment;
    return item;
  }

  import() {
    document.getElementById('file_to_upload').click();
  }

  onImageLoad($event, compressedImage, ...args) {
    this.item.images.push(compressedImage);
    this.childC?.divideImageObject();
  }

  toggleTag(tag) {
    if (this.item.tags.includes(tag)) {
      this.item.tags.splice(this.item.tags.indexOf(tag), 1);
    } else {
      this.item.tags.push(tag);
    }
  }

  onImagesChanged(images) {
    this.item.images = images;
  }

  getBase64ImageFromURL(url: string, index: number) {
    // create an image object
    const img = new Image();

    img.crossOrigin = 'Anonymous';
    img.src = url;
    if (!img.complete) {
      // This will call another method that will create image from url

      img.onload = () => {
        this.item.images[index] = this.getBase64Image(img);
        this.childC?.divideImageObject();
      };
    } else {
      this.item.images[index] = this.getBase64Image(img);
    }
  }
}
