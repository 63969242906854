import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'ngx-acces-info',
  template: `<router-outlet></router-outlet>`,
})
export class AccesInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
