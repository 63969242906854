import { NgModule } from '@angular/core';
import { DossiersRoutingModule } from './dossiers-routing.module';
import { DossiersComponent } from './dossiers.component';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbTreeGridModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
  NbSpinnerModule,
  NbProgressBarModule,
  NbAccordionModule,
} from '@nebular/theme';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';

import { HistoriqueModule } from '../../components/historique/historique.module';
import { DossiersService } from '../../services/dossiers.service';
import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import { CreateComponent } from './create/create.component';
import { BatchUploadComponent } from '../batch-upload/batch-upload.component';
import { UpdateOnCreationModule } from '../update-on-creation/update-on-creation.module';
import { LogColorPipe } from '../../pipes/log-color.pipe';
import { OurCommonModule } from '../../core';

@NgModule({
  imports: [
    ThemeModule,
    DossiersRoutingModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    HistoriqueModule,
    SpinnerModule,
    NbSpinnerModule,
    InfiniteScrollModule,
    NbProgressBarModule,
    UpdateOnCreationModule,
    OurCommonModule,
    NbAccordionModule,
  ],
  declarations: [DossiersComponent, ListeComponent, EditComponent, CreateComponent, BatchUploadComponent, LogColorPipe],
  providers: [DossiersService],
})
export class DossiersModule {}
