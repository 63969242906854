import { timeAgo } from '../../../core';

export default {
  mode: 'external',
  pager: { display: false },
  noDataMessage: 'لا يوجد دوائر بلدية حالياً',
  add: {
    addButtonContent: '<i class="nb-plus"></i>',
    createButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
  },
  edit: {
    editButtonContent: '<i class="nb-compose"></i>',
  },
  actions: {
    columnTitle: '',
    delete: true,
  },
  delete: {
    deleteButtonContent: '<i class="nb-trash"></i>',
    confirmDelete: true,
  },
  columns: {
    id: {
      title: 'المعرف',
      type: 'number',
      width: '25%',
    },
    name: {
      title: 'الاسم',
      type: 'string',
      width: '25%',
    },
    is_active: {
      title: 'الحالة',
      type: 'string',
      width: '15%',
      valuePrepareFunction: (is_active) => {
        if (!is_active) return 'مغلق للعموم';
        return 'مفتوح للعموم';
      },
    },
    created_at: {
      title: 'أضيف في',
      type: 'string',
      width: '15%',
      valuePrepareFunction: (created_at) => {
        return timeAgo(created_at);
      },
    },
    navigate_edit: {
      filter: false,
      type: 'html',
      width: '20%',
      valuePrepareFunction: (cell, row) => {
        return '<a onclick="onCustom($event)" href="/pages/services/liste"><i class="fa fa-book"></i> تعديل الخدمات</a>';
      },
    },
  },
};
