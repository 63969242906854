import { Component, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { getItem, StorageItem } from '../core';
import { ManagerFacade } from '../core/store';
import { PagesMenu } from './pages-menu';
import { BasePushNotifications } from '../core/models/class/BasePushNotifications';
import { ToastLuncherService } from '../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu class="menu delete-print" [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent extends BasePushNotifications implements OnInit {
  menu: NbMenuItem[] = [];
  constructor(
    private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    protected _toastLuncher: ToastLuncherService,
    private _managerFacade: ManagerFacade,
  ) {
    super(_toastLuncher);
    this._managerFacade.loadUser();
    const permissions = getItem(StorageItem.permissions)?.split(',');
    this.menu = PagesMenu.makeMenuItems(permissions);
    this.menuService.onItemClick().subscribe((event) => {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        this.sidebarService.toggle(true, 'menu-sidebar');
      }
    });
  }

  ngOnInit(): void {
    this.listen();
  }
}
