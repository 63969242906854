import { Enrollement } from "../../core/models/class/course";
import { CoursesService } from "../../services/courses.service";
import { Router } from "@angular/router";


/*
 *
 *  this modules contains any utility classes/functions needed by "elearning"
 * 
*/

export class CourseUtility {
    constructor(protected _courseService: CoursesService, protected router: Router) { }

    openDetail($event) {
        this._courseService.checkEnrollment($event).subscribe((data) => {
            if (data.enrolled) return this.router.navigate(['/pages/elearning/course-learning', $event]);

            return this.router.navigate(['/pages/elearning/course-detail', $event]);
        },
            (err) => {
                // consider any error as not enrolled
                this.router.navigate(['/pages/elearning/course-detail', $event]);
            });

    }
}