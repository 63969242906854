import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesService } from '../../../services/courses.service';
import { Course } from '../../../core/models/class/course';
import Backend from '../../../core/backend';
import { CourseUtility } from '..';

@Component({
  selector: 'ngx-course-detail',
  templateUrl: './courseDetail.component.html',
  styleUrls: ['./courseDetail.component.scss'],
})
export class CourseDetail extends CourseUtility  implements OnInit {
  course: Course;
  baseUrl = Backend.baseUrl;
  isLoading = true;

  constructor(private route: ActivatedRoute, protected _courseService: CoursesService,protected router: Router) {
    super(_courseService, router);
  }

  ngOnInit(): void {
    const id: string = this.route.snapshot.paramMap.get('id');
    this._courseService.getById(id).subscribe((data) => {
      this.course = data;
      this.isLoading = false;
    });
  }
  subscribeToCourse() {
    this._courseService.subscribeToCourse(this.course.id).subscribe((data)=>{
      if(data)
      this.openDetail(this.course.id)
    })
  }
}
