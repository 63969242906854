import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Poll } from '../core/models/class/polls';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class PollsService extends GenericCRUDService<Poll> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/polls';
    this.suffix = '/';
  }
}
