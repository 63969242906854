import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if (environment.production) {
      if ('serviceWorker' in navigator) {
        console.log('updating app version...');
        navigator.serviceWorker.register('worker-basic.min.js');
        navigator.serviceWorker.ready.then((registration) => registration.update());
      }
    }
  })
  .catch((err) => console.error(err));
