import { Injectable } from '@angular/core';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';
import Backend from '../core/backend';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  constructor(private swUpdate: SwUpdate, private swPush: SwPush) {}

  /**
   *
   * @param hours  check for updates every 1/4 hours (default)
   */
  checkForUpdates(hours: number = 0.25) {
    interval(1000 * 60 * 60 * hours).subscribe(() => this.swUpdate.checkForUpdate());
  }

  subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: Backend.VAPID_PUBLIC_KEY,
      })
      .then((sub) => console.log(sub))
      .catch((err) => console.error('Could not subscribe to notifications', err));
  }

  forceUpdate() {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe((evt) => {
        // Reload the page to update to the latest version.
        document.location.reload();
      });
  }
}
