import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { tap } from 'rxjs/operators';
import './ckeditor.loader';
import 'ckeditor';

import { CommitteeService } from '../../../services/committee.service';
import { NewsService } from '../../../services/news.service';
import { ImageSliderComponent } from '../../../components/image-slider/image-slider/image-slider.component';
import { ShareSingleViewComponent } from '../../../components/share-single-view/share-single-view.component';
import { newsCategories } from '../../../core';
import { News, NewsTags } from '../../../core/models/class/news';
import { GenericCreateWithImage } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import Backend from '../../../core/backend';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent extends GenericCreateWithImage<News> implements OnInit {
  @ViewChild(ImageSliderComponent, { static: false })
  childC: ImageSliderComponent;
  @ViewChild(ShareSingleViewComponent)
  shareSingleView: ShareSingleViewComponent;

  committees$ = this._committeeService.get().pipe(
    tap((res) => {
      this.item.committee_id = res[0].id.toString();
      this.item.category = this.newsCategories[0].displayName;
    }),
  );

  loadedImg = false;
  baseUrl = Backend.baseUrl;
  tagsToAdd = '';
  newsCategories = newsCategories;
  newsTags: NewsTags[] = [];
  newsTagsFiltred: NewsTags[] = [];

  constructor(
    protected datePipe: DatePipe,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected _committeeService: CommitteeService,
    protected _newsService: NewsService,
    protected imageCompress: NgxImageCompressService,
  ) {
    super(_newsService.prefix, _toastLuncher, _newsService, router, imageCompress);
    this.item = new News();
    this.getNewsTags();
  }

  ngOnInit() {}

  getNewsTags() {
    this._newsService.getNewsTags().subscribe((res) => {
      this.newsTags = res;
      this.newsTagsFiltred = res;
    });
  }

  searchTags(e) {
    const filtredTags = this.newsTags.filter((i) => i.name.includes(e.target.value));
    this.newsTagsFiltred = filtredTags;
  }

  formIsValid(): boolean {
    if (!this.item.title || !this.item.committee_id || !this.item.body) throw new Error('الرجاء ملء كل الفراغات');
    return true;
  }

  beforeSubmit(item: News): News {
    if (item.attachment === null) delete item.attachment;
    return item;
  }

  onImageLoad($event, compressedImage, ...args) {
    this.item.images.push(compressedImage);
    this.childC?.divideImageObject();
  }

  toggleTag(tag) {
    if (this.item.tags.includes(tag)) {
      this.item.tags.splice(this.item.tags.indexOf(tag), 1);
    } else {
      this.item.tags.push(tag);
    }
  }

  onImagesChanged(images) {
    this.item.images = images;
  }

  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    return canvas.toDataURL('image/jpeg');
  }
}
