import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { UpdateModeTypes } from '../../../core/models/types';
import { DossiersService } from '../../../services/dossiers.service';
import { Dossier } from '../../../core/models/class/dossier';
import { dossierTypes, isValidCitizenId, isValidIdentifier, isValidPhoneNumber } from '../../../core';
import { GenericCreateWithFirstUpdate } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { UpdateStatusService } from '../../../core/services/update-status.service';
import { Update } from '../../../core/models/class/update';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateWithFirstUpdate<Dossier> implements OnInit {
  item = new Dossier();
  types = dossierTypes;
  mode = UpdateModeTypes.MODE_DOSSIER;

  constructor(
    public datePipe: DatePipe,
    protected _dossiersService: DossiersService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    updateStatusService: UpdateStatusService,
  ) {
    super(_dossiersService.prefix, _toastLuncher, _dossiersService, router, updateStatusService);
    this.item.updates = [new Update()];
  }

  ngOnInit() {}

  formIsValid(): boolean {
    if (!this.item.unique_identifier || !isValidIdentifier(this.item.unique_identifier)) {
      throw new Error('الرجاء إدخال المعرف الوحيد للملف');
    }

    if (!this.item.deposit_date) {
      throw new Error('الرجاء إدخال تاريخ الإيداع');
    }

    if (!this.item.cin_number || !isValidCitizenId(this.item.cin_number)) {
      throw new Error('الرجاء إدخال 3 أرقام فقط من بطاقات التعريف');
    }

    if (!this.item.phone_number || !isValidPhoneNumber(this.item.phone_number)) {
      throw new Error(' الرجاء إدخال رقم هاتف صحيح ');
    }
    return true;
  }

  beforeSubmit(item: Dossier): Dossier {
    item.deposit_date = this.datePipe.transform(item.deposit_date, 'yyyy-MM-dd');
    return item;
  }
}
