import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-reclamations',
  template: ` <router-outlet></router-outlet>`,
})
export class PlaintsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
