import { Component, OnInit, Input } from '@angular/core';
import { DossierUpdate } from '../../models/class/dossier';

import { Update } from '../../models/class/update';
import { openLink } from '../../lib/functions';
import { MunisFacade } from '../../store';
import Backend from '../../backend';
import { GenericHasImageUpload } from '../../models/class/GenericEditFeature';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastLuncherService } from '../../services/toast-luncher.nb.service';
import { UpdateStatusService } from '../../services/update-status.service';
import { NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'app-comment-status',
  templateUrl: './comment-status.component.html',
  styleUrls: ['./comment-status.component.css'],
})
export class CommentStatusComponent extends GenericHasImageUpload<Update | DossierUpdate> implements OnInit {
  @Input() update: Update | DossierUpdate;
  @Input() type: string;
  FILE_UPLOAD_NAME = 'file_to_upload:CommentStatusComponent';
  CONTEXT_MENU = 'my-context-menu';

  baseUrl = Backend.baseUrl;
  selectedMuni$ = this._munisFacade.selectedMuni$;
  attachment: string;
  editOperation = [
    {
      title: 'تعديل الصورة',
      icon: 'edit-outline',
      onClick: () => this.import(),
    },
    {
      title: 'حذف صورة',
      icon: 'trash-outline',
      onClick: () => this.onImageLoad({}, null),
    },
  ];
  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected imageCompress: NgxImageCompressService,
    protected _updateStatusService: UpdateStatusService,
    protected _munisFacade: MunisFacade,
    protected nbMenuService: NbMenuService,
  ) {
    super(_toastLuncher, imageCompress);
  }

  ngOnInit(): void {
    this.attachment = this.update?.attachment || '';
    if (this.update?.id) {
      this.CONTEXT_MENU = 'my-context-menu' + this.update.id;
      this.nbMenuService
        .onItemClick()
        .pipe(
          filter(({ tag }) => tag === this.CONTEXT_MENU),
          map(({ item: { title } }) => title),
        )
        .subscribe((title) => {
          this.editOperation.forEach((item) => {
            if (title === item.title) item.onClick();
          });
        });
    }
  }

  import() {
    document.getElementById(this.FILE_UPLOAD_NAME + this.update.id).click();
  }

  onImageLoad($event, compressedImage, ...args) {
    this.update.image = compressedImage;
    this._updateStatusService.putImage(this.update.id, this.update).subscribe(
      (res) => {
        this._toastLuncher.success('تم تحديث الصورة');
      },
      (err) => {
        throw new Error('لم نتمكن من تحديث الصورة');
      },
    );
  }

  openURL = () => openLink(this.attachment);
}
