import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import './ckeditor.loader';
import 'ckeditor';
import { ProceduresService } from '../../../services/procedures.service';
import { Procedure } from '../../../core/models/class/procedure';
import { proceduresCategories, removeMarkupSpaces } from '../../../core';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Procedure> implements OnInit {
  constructor(
    public route: ActivatedRoute,
    private _proceduresService: ProceduresService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super('id', _proceduresService.prefix, _toastLuncher, _proceduresService, router, route);
    this.item = new Procedure();
  }

  proceduresCategories = proceduresCategories;

  ngOnInit() {}

  protected formIsValid(): boolean {
    if (!this.item.title) throw new Error('العنوان مطلوب');
    if (!this.item.body) throw new Error('النص مطلوب');
    return true;
  }

  protected beforeSubmit(item) {
    item = {
      title: item.title,
      body: removeMarkupSpaces(item.body),
      type: item.type,
    };
    return item;
  }
}
