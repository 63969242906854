import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

import { Service } from '../../../core/models/class/services';
import { ServicesService } from '../../../services/services.service';
import { timeAgo } from '../../../core';
import { isActiveState } from '../../../core/models/types';
@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Service> implements OnInit {
  item = new Service();
  serviceState = isActiveState;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected _servicesService: ServicesService,
  ) {
    super('id', '/services', _toastLuncher, _servicesService, router, route);
  }

  ngOnInit() {}

  protected normalizeAfterGetItem(item: Service): Service {
    item.created_at = timeAgo(item.created_at);
    return item;
  }
}
