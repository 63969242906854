import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Backend from '../backend';

import { getItem, StorageItem } from '../lib/local-storage.utils';
import { MunisFacade } from '../store';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  static readonly url = Backend.url;

  public prefix = '';

  protected suffix = '';

  constructor() {}

  getUrl(param: string = '') {
    return BaseService.url + `${param}/municipalities/` + MunisFacade.getMunicipalityId() + this.prefix;
  }

  static getHeadersFromToken(token: string): HttpHeaders {
    const headers = new HttpHeaders();
    return token ? headers.append('Authorization', 'Bearer ' + token) : headers;
  }

  static getHeaders(): HttpHeaders {
    const token = getItem(StorageItem.token);
    return BaseService.getHeadersFromToken(token);
  }
}
