import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Manager } from '../../../models/class/manager';
import { LoginCredentials } from '../../../models/class/user';

import * as UserActions from './user.action';
import { UserFacade } from './user.facade';
import { UserState } from './user.reducer';

@Injectable({
  providedIn: 'root',
})
export class ManagerFacade extends UserFacade {
  loggedInUser$ = this.loggedInUser$ as Observable<Manager>;

  constructor(public store: Store<UserState>) {
    super(store);
  }

  startLogin(creds: LoginCredentials) {
    this.store.dispatch(new UserActions.LoginManagerStart(creds));
  }

  loadUser() {
    this.store.dispatch(new UserActions.LoadManager());
  }
}
