import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbLayoutScrollService } from '@nebular/theme';
import tableSettings from './list.config';

import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { ServicesService } from '../../../services/services.service';
import { Service } from '../../../core/models/class/services';
import { getItem, StorageItem } from '../../../core';
import { AgenciesService } from '../../../services/agencies.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Service> implements OnInit {
  agencyName = getItem(StorageItem.agencyName);
  slugToVerify: string = null;
  slugIsValid: boolean = null;

  constructor(
    public router: Router,
    protected scroll: NbLayoutScrollService,
    protected _toastLuncher: ToastLuncherService,
    protected _servicesService: ServicesService,
    protected _agenciesService: AgenciesService,
    protected activatedRoute: ActivatedRoute,
  ) {
    super('/services', scroll, _servicesService, router, _toastLuncher);
  }

  settings = tableSettings;

  ngOnInit() {}

  preSavingList(data: Service[]): Service[] {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    return data;
  }

  verify(slug: string = this.slugToVerify) {
    this._agenciesService.verify(getItem(StorageItem.agencyId), slug).subscribe(
      (res) => {
        this.slugIsValid = res;
      },
      (err) => {
        this.slugIsValid = false;
        throw new Error('لم نتمكن من العثور على حجز مطابق');
      },
    );
  }

  getValidityColor(value: boolean = this.slugIsValid) {
    switch (value) {
      case true:
        return 'success';
      case false:
        return 'danger';
      default:
        return 'basic';
    }
  }
}
