import { NbLayoutScrollService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { GenericCRUDService } from '../../services/generic.service';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastLuncherService } from '../../services/toast-luncher.nb.service';
import { IfeatureItem, IUpdatablefeatureItem } from '../interface/IfeatureItem';
import { UpdateTypes } from '../types';
import { ItemIsOpenForProcessing } from '../../lib/functions';
import { StorageItem, getItem, setItem } from '../../lib/local-storage.utils';
export class GenericListFeature<T extends IfeatureItem> {
  DELETE_TEXT = 'تم الحذف بنجاح';
  CONFIRM_DELETE_MESSAGE = 'هل تريد فعلا الحذف ؟';

  data: T[] = [];
  paging = 1;
  perPage = 10;
  isLoading: boolean = true;
  noMoreData: boolean = false;
  source: LocalDataSource = new LocalDataSource();
  settings = {};

  constructor(
    public route: string,
    protected scroll: NbLayoutScrollService,
    protected _service: GenericCRUDService<T>,
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
  ) {
    // WORK AROUND for something ? figure this out!
    if (route[0] === '/') this.route = route.replace('/', '');
    this.registerScrollListener();
    this.getPages();
  }

  protected getTableSettings(): any {
    undefined;
  }

  private registerScrollListener() {
    this.scroll.onScroll().subscribe((event) => {
      if (event.target.scrollTop + event.target.offsetHeight + 100 >= event.target.scrollHeight && !this.isLoading) {
        if (!this.noMoreData) {
          this.paging++;
          this.getPages();
        }
      }
    });
  }

  protected load(data: T[]) {
    this.source.load(data).then(() => {
      setTimeout(() => {
        $('.ng2-smart-action-edit-edit').on('mouseup', function (e) {
          if (!e.ctrlKey && e.which !== 1) {
            e.target.click();
          }
          e.preventDefault();
        });
      }, 500);
    });
  }

  /**
   *
   * @param data reponse returned from subscribing to the _service
   * @returns data to be saved to the component
   */
  protected preSavingList(data: T[]): T[] {
    return data;
  }

  protected refreshTableData(): void {
    this.settings = { ...this.settings, ...this.getTableSettings() };
  }

  protected formIsValid(item: T, throws = false): boolean {
    return true;
  }

  getPages(paging = this.paging, perPage = this.perPage, isAllData = false) {
    this.isLoading = true;
    this._service.getPages(paging, perPage).subscribe(
      (data) => {
        data = this.preSavingList(data);
        if (isAllData) {
          this.data = data;
          this.noMoreData = true;
        } else {
          if (!data?.length || this.idExists(data[0]?.id, this.data)) this.noMoreData = true;
          else this.data = this.data.concat(data);
        }

        this.refreshTableData();
        const toFilter = getItem(StorageItem.toFilter);
        if (toFilter == 'false' || this.route !== 'complaints') this.load(this.data);
        else this.load(this.data.filter(ItemIsOpenForProcessing));
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.noMoreData = true;
      },
    );
  }

  idExists(id: number | string, data: T[]) {
    /**
     * generic list endpoints tend to repeat the last page
     * if you have already went throught all pages in database
     */
    const ids = new Set(data.map((item) => item.id));
    return ids.has(id);
  }

  exportExcel(tableID: string) {
    /* table id is passed over here */
    const element = document.getElementById(tableID);
    let ws: XLSX.WorkSheet;
    const wscols = [
      { wch: 2 },
      { wch: 10 },
      { wch: 20 },
      { wch: 30 },
      { wch: 20 },
      { wch: 40 },
      { wch: 40 },
      { wch: 20 },
    ];

    const wsrows = [{ hidden: false }, { hidden: true }];

    ws = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = wscols;
    ws['!rows'] = wsrows;

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.route + '.xlsx');
  }

  addRow() {
    this.navigateTo(`/pages/${this.route.replace('/', '')}/create`);
  }

  editRow(event) {
    this.navigateTo(`/pages/${this.route.replace('/', '')}/edit/`, event.data.id);
  }

  deleteRow(event) {
    if (window.confirm(this.CONFIRM_DELETE_MESSAGE))
      this._service.delete(this.getDeleteIdentifier(event)).subscribe(
        (data) => {
          this.data = this.data.filter((item) => item.id !== event.data.id);
          this.source.load(this.data);
          this._toastLuncher.success(this.DELETE_TEXT);
        },
        (err) => {
          throw new Error('لم نتمكن من إجراء الحذف');
        },
      );
  }

  getDeleteIdentifier($event): number {
    return $event.data.id;
  }

  navigateTo = (path: string, id?: string): void => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    path = id === undefined ? path : path + id;
    if (isMobile || !environment.production) this.router.navigate([path]);
    else window.open(path);
  };
}

export class GenericListFeatureWithStatus<T extends IUpdatablefeatureItem> extends GenericListFeature<T> {
  params;

  constructor(
    public route: string,
    protected scroll: NbLayoutScrollService,
    protected _service: GenericCRUDService<T>,
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(route, scroll, _service, router, _toastLuncher);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (this.hasOperationUpdate(params)) {
        this.params = params;
        this.getPages(0, 0, true);
      }
    });
  }

  /**
   *
   * @param data array of table rows
   * @param params route params that can contain a status key
   */
  protected load(data: T[], params?) {
    if (this.params?.status && Object.keys(UpdateTypes).includes(this.params.status.toUpperCase()))
      this.source.load(data.filter((item) => item.updates[0].status === this.params.status.toUpperCase()));
    else this.source.load(data);
  }

  /**
   *
   * @param params route params passed to app
   * AKA object returned from ActivatedRoute.queryParams
   */
  hasOperationUpdate(params) {
    return params?.status && Object.keys(UpdateTypes).includes(params.status.toUpperCase());
  }
}
