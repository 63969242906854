import { IfeatureItem } from '../interface/IfeatureItem';
import { DossierTypes, UpdateTypes } from '../types';
import { Update } from './update';

export class DossierUpdate extends Update {
  note: string;
  id: number;
  attachment: string;
  department: number;
}
export class Dossier implements IfeatureItem {
  id: number;
  created_by: string;
  created_by_id: number;
  updates: Update[] = [];
  phone_number: string;
  followers: any[];
  followings_state: Boolean;
  name: string;
  type: string;
  unique_identifier: string;
  cin_number: string;
  deposit_date: string = new Date().toDateString(); // WORKAROUND batch upload
  created_at: Date;
  municipality: number;
  status = UpdateTypes.RECEIVED;
  attachments: string[] = [];
  building?: Building;
}

export class Building implements IfeatureItem {
  id: number;
  image: string;
  address: string;
  longitude: string;
  latitude: string;
  permit_reference: string;
  dossier: number;
}
