import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getDeviceData, isValidPhoneNumber, slideAnimation } from '../../core';
import { ManagerFacade } from '../../core/store';
import { BasePushNotifications } from '../../core/models/class/BasePushNotifications';
import { ToastLuncherService } from '../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  ...slideAnimation,
})
export class LoginComponent extends BasePushNotifications implements OnInit {
  isLoading$ = this._managerFacade.isLoading$;
  phone: number;
  password: string;

  constructor(
    public _router: Router,
    protected _toastLuncher: ToastLuncherService,
    private _managerFacade: ManagerFacade,
  ) {
    super(_toastLuncher);
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
    this.requestPermission();
  }

  ngOnInit() {}

  resetPassword() {
    this._router.navigate(['reset']);
  }

  login(phone_number, password) {
    if (!phone_number || !password || !isValidPhoneNumber(phone_number))
      throw new Error('الرجاء ملء كل الفراغات والتثبت من كلمة السر ورقم الهاتف');

    const fcm_token = this.fcm_token;
    this._managerFacade.startLogin({
      phone_number,
      password,
      fcm_token,
      ...getDeviceData(),
    });
  }
}
