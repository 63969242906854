import { Component, OnInit } from '@angular/core';
import { defaultAnimation, getItem, statusColor, StorageItem, toArabicStatus } from '../../core';
import { MunisFacade } from '../../core/store';
import { BehaviorSubject } from 'rxjs';
import { PermissionsEnum, UpdateTypes } from '../../core/models/types';
import { CommitteeService } from '../../services/committee.service';
import { Committee } from '../../core/models/class/committee';
import { Router } from '@angular/router';
import Backend from '../../core/backend';
import { KpiService } from '../../services/kpi.service';

class DashboardNgxChart {
  kpi = null;
  isLoading = true;
  view: number[] = [900, 200];
  colorScheme = {
    domain: ['#58C0E6', '#D48C2E', '#72A545', '#DF5050', '#ec6a37', '#ba0202'],
  };
  features: KpiForFeature[] = [
    {
      permission: PermissionsEnum.MANAGE_COMPLAINTS,
      key: 'complaints',
      hasOperationUpdate: true,
      name: 'التشكيات',
      route: '../complaints/liste',
      description: 'اضغط هنا لمتابعة التشكيات و الاجابة عن الملفات التي في الانتظار',
    },
    {
      permission: PermissionsEnum.MANAGE_DOSSIERS,
      key: 'dossiers',
      hasOperationUpdate: true,
      name: 'مطالب الرخص',
      route: '../dossiers/liste',
      description: 'اضغط هنا لمتابعة مطالب الرخص و الاجابة عن الملفات التي في الانتظار',
    },
    {
      permission: PermissionsEnum.MANAGE_SUBJECT_ACCESS_REQUESTS,
      hasOperationUpdate: true,
      key: 'subject_access_requests',
      name: 'مطالب النفاذ الى المعلومة',
      route: '../subject-access-requests/liste',
      description: 'اضغط هنا لمتابعة مطالب النفاذ الى المعلومة و الاجابة عن الملفات التي في الانتظار',
    },
    {
      permission: PermissionsEnum.MANAGE_COMPLAINTS,
      key: 'forum',
      hasOperationUpdate: true,
      name: 'فضاء الآراء و التساؤلات',
      route: '../forums/liste',
      description: 'انقر هنا لمتابعة تعليقات المواطنين',
    },

    {
      permission: PermissionsEnum.MANAGE_REPORTS,
      key: 'reports',
      hasOperationUpdate: false,
      name: 'التقارير',
      route: '../reports/liste',
      description: 'اضغط هنا لاضافة او معالجة تقارير اللجان',
    },
    {
      permission: PermissionsEnum.MANAGE_EVENTS,
      key: 'events',
      hasOperationUpdate: false,
      name: 'المواعيد و الأنشطة',
      route: '../events/liste',
      description: 'اضغط هنا لمتابعة رزنامة المواعيد و الأنشطة',
    },
    {
      permission: PermissionsEnum.MANAGE_NEWS,
      key: 'news',
      hasOperationUpdate: false,
      name: 'المستجدات البلدية',
      route: '../news/liste',
      description: 'انقر هنا لمتابعة أو اضافةمستجدات البلدية',
    },
  ];
  opsUpdateLabels = Object.keys(UpdateTypes)
    .reverse()
    .map((o) => {
      return {
        label: o,
        formattedLabel: toArabicStatus(o),
        color: statusColor(o),
      };
    });

  constructor() {
    this.view =
      innerWidth > 1200
        ? [innerWidth * 0.48, 200]
        : innerWidth > 768
        ? [innerWidth * 0.58, 200]
        : [innerWidth / 1.2, 200];
  }

  onResize(event) {
    this.view =
      event.target.innerWidth > 1200
        ? [event.target.innerWidth * 0.48, 200]
        : event.target.innerWidth > 768
        ? [event.target.innerWidth * 0.58, 200]
        : [event.target.innerWidth / 1.2, 200];
  }
}
@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
  ...defaultAnimation,
})
export class DashboardComponent extends DashboardNgxChart implements OnInit {
  baseUrl = Backend.baseUrl;

  permissions = getItem(StorageItem.permissions) || [];
  selectedMuni$ = this._munisFacade.selectedMuni$;
  commitees: Committee[] = [];

  withOpsUpdate$ = new BehaviorSubject<OperationUpdateStat[]>([]);
  reports$ = new BehaviorSubject<VisItem[]>([]);
  events$ = new BehaviorSubject<VisItem[]>([]);
  news$ = new BehaviorSubject<VisItem[]>([]);

  dashboards: IDashboard[] = [
    { id: 1, name: 'مـؤشـرات الـخـدمـات' },
    {
      id: 3,
      name: 'مـؤشـرات الـتـقـيـيـم',
      link: '',
      height: 140,
      filter: {
        municipality_id: getItem(StorageItem.municipality),
      },
    },
    {
      id: 11,
      name: 'المؤشرات الجملية',
      link: '',
      height: 80,
      filter: {
        municipality_name: getItem(StorageItem.municipality_ar),
      },
    },
    {
      id: 13,
      name: 'المؤشرات الجملية  و طبيعة العناصر',
      link: '',
      height: 160,
      filter: {
        municipality_name: getItem(StorageItem.municipality_ar),
      },
    },
    {
      id: 12,
      name: 'مؤشرات الشهر الحالي مقارنة بالشهر الماضي',
      link: '',
      height: 80,
      filter: {
        municipality_name: getItem(StorageItem.municipality_ar),
      },
    },
  ];
  selectedDashboard: IDashboard = this.dashboards[0];

  constructor(
    public _committeeService: CommitteeService,
    public _munisFacade: MunisFacade,
    public router: Router,
    private _KpiService: KpiService,
  ) {
    super();
    this.dashboards.forEach((value, index: number) => {
      if (index !== 0) {
        this._KpiService.getMetabaseDashboard(value.id, value.filter).subscribe((res) => {
          this.dashboards[index].link = res.link.replace('http', 'https');
        });
      }
    });
    this._KpiService.getMainKpi().subscribe((data) => {
      this.kpi = data;
      this.prepOpsUpdate();
      this.prepReports();
      this.prepEvents();
      this.prepNews();
      this.isLoading = false;
    });
  }

  setSelectedDashboard(dashboard: IDashboard) {
    this.selectedDashboard = dashboard;
    if (dashboard.id !== 0) this.isLoading = true;
  }

  ngOnInit(): void {}

  onMetabaseLoad() {
    console.log('ya bouheli');
  }
  prepOpsUpdate() {
    this.features
      .filter((f) => f.hasOperationUpdate)
      .forEach((feat) => {
        if (this.permissions.includes(feat.permission)) {
          this.withOpsUpdate$.next([
            ...this.withOpsUpdate$.value,
            {
              ...feat,
              total: this.kpi[feat.key].reduce((prev, cur) => cur.total + prev, 0),
              types: this.kpi[feat.key].map((type) => {
                return {
                  name: toArabicStatus(type.status),
                  value: type.total,
                };
              }),
            },
          ]);
        }
      });
  }

  prepReports() {
    this._committeeService.get().subscribe((all) => {
      this.commitees = all;
      this.reports$.next(
        this.kpi.reports.map((r) => {
          const match = this.commitees.find((c) => c.id === r.committee_id);
          return {
            name: match ? match.title : r.committee_id.toString(),
            value: r.total,
          };
        }),
      );
    });
  }

  prepEvents() {
    this.events$.next([
      { name: 'قادمة', value: this.kpi.events.upcoming },
      { name: 'تمت', value: this.kpi.events.done },
    ]);
  }

  prepNews() {
    this.news$.next(
      this.kpi.news.map((r) => {
        return {
          name: r.category,
          value: r.total,
        };
      }),
    );
  }

  navigateToStatusList(featureRoute: string, status: UpdateTypes) {
    const listRoute = featureRoute.split('/')[1];
    this.router.navigate([`pages/${listRoute}/liste`], { queryParams: { status } });
  }
}
interface KpiForFeature {
  name: string; // arabic name
  permission: string;
  key: string;
  description: string;
  route: string;
  hasOperationUpdate?: boolean;
}
interface OperationUpdateStat extends KpiForFeature {
  total: number;
  types: string;
}
interface VisItem {
  name: string;
  value: number;
}

interface IDashboard {
  id: number;
  name: string;
  link?: string;
  height?: number;
  filter?: {
    municipality_name?: string;
    municipality_id?: string;
  };
}
