import { AgencyInfo } from '../interface/ticket';
import { Association } from './association';

export class BaseMunicipality {
  name: string;
  name_fr: string;
  route_name: string;
  is_active: boolean;
  is_signed: boolean;
}
export class MunicipalitySummary extends BaseMunicipality {
  id: number;
  city: string;
  has_eticket: boolean;
  logo: string;
  longitude: number;
  latitude: number;
}
export class Municipality extends MunicipalitySummary {
  public is_selected = false;
  public website: string;
  public facebook_url: string;
  public partner_associations = new Array<Association>();
  public total_followers: number;
  public agency: AgencyInfo[];
  public regions: Region[];
}

export interface Region {
  id: number;
  name: string;
  municipality_id: number;
}

export class StateRow {
  stateName: string;
  munisCount: number;
  isEmpty: boolean;
  municipalities: BaseMunicipality[];
}

export type MunicipalityGlobal = Municipality | MunicipalitySummary | BaseMunicipality;
