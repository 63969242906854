import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { ForumService } from '../../../services/forum.service';
import { DatePipe } from '@angular/common';
import { NbLayoutScrollService } from '@nebular/theme';
import * as $ from 'jquery';
import { commentTypeToArabic, ItemIsOpenForProcessing, toArabicStatus } from '../../../core';
import { CommentTypes } from '../../../core/models/types';
import { GenericListFeatureWithStatus } from '../../../core/models/class/GenericListFeature';
import { Comment } from '../../../core/models/class/comment';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeatureWithStatus<Comment> implements OnInit {
  LastItem: any = [];
  status: string;
  filterClosed = false;
  filteredData;

  constructor(
    public router: Router,
    public datePipe: DatePipe,
    protected _toastLuncher: ToastLuncherService,
    public forumService: ForumService,
    protected scroll: NbLayoutScrollService,
    protected activatedRoute: ActivatedRoute,
  ) {
    super('forums', scroll, forumService, router, _toastLuncher, activatedRoute);
  }

  settings = {
    mode: 'external',
    pager: { display: false },
    add: {
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    noDataMessage: 'لا يوجد تعاليق ',
    columns: {
      /*
        id: {
          title: '#',
          type: 'number',
        },
        */
      created_at: {
        title: 'نشر في',
        type: 'Date',
        valuePrepareFunction: (lastLoginTime: any) => {
          return new DatePipe('en-US').transform(lastLoginTime, 'M/d/yyyy');
        },
      },
      type: {
        title: 'نوعية  المشاركة',
        type: 'string',
        valuePrepareFunction(type: CommentTypes) {
          return commentTypeToArabic(type);
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'الكل',
            list: [],
          },
        },
      },
      title: {
        title: 'عنوان المشاركة',
        type: 'string',
        width: '250px',
      },

      /*
        this is in case we wanted to add images inside tables in the future
        image: {
          title: '# ',
          type: 'html',
          valuePrepareFunction:
          (picture: string) => { return `<img width="50px" src="https://dev-backend.elbaladiya.tn${picture}"/>`; },

        },
         */

      committee_title: {
        title: 'الطرف المعني',
        type: 'string',
        width: '170px',
      },
      topic: {
        title: 'تصنيف ',
        type: 'html',
        valuePrepareFunction(topic) {
          return topic.label;
        },
      },
      status: {
        //updates is an array of objects; we should get the last object
        //and get the status from it
        title: 'الحالة',
        type: 'string',
        filter: {
          type: 'list',
          config: {
            selectText: 'الكل',
            list: [],
          },
        },
        valuePrepareFunction: (status) => {
          return toArabicStatus(status);
        },
      },
      lastTrait: {
        title: 'تاريخ اخر معالجة',
        type: 'string',
        width: '15%',
      },
    },
  };

  ngOnInit() {
    this.settings.columns.type.filter.config.list = [
      ...Object.keys(CommentTypes).map((type) => {
        return {
          value: type,
          title: commentTypeToArabic(type as CommentTypes),
        };
      }),
    ];
    this.settings.columns.status.filter.config.list = GeneralService.filterLabels;
  }

  preSavingList(data: Comment[]) {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    data.forEach((item: any) => {
      if (item.updates.length) {
        item.status = item.updates[0].status;
        item.lastTrait = this.datePipe.transform(item.updates[0].date, 'yyyy-MM-dd');
      }
    });
    return data;
  }

  handleFilterClosedItems(event) {
    if (this.filterClosed === true) {
      this.filteredData = this.data.filter(ItemIsOpenForProcessing);
      this.source.load(this.filteredData).then(() => {
        setTimeout(() => {
          $('.ng2-smart-action-edit-edit').on('mouseup', function (e) {
            if (!e.ctrlKey && e.which !== 1) {
              e.target.click();
            }
            e.preventDefault();
          });
        }, 500);
      });
    } else {
      this.source.load(this.data).then(() => {
        setTimeout(() => {
          $('.ng2-smart-action-edit-edit').on('mouseup', function (e) {
            if (!e.ctrlKey && e.which !== 1) {
              e.target.click();
            }
            e.preventDefault();
          });
        }, 500);
      });
    }
  }

  editTopics() {
    this.router.navigate(['pages/topics/liste']);
  }
}
