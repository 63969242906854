import { toArabicStatus } from '../../../core';

export default {
  mode: 'external',
  pager: { display: false },
  edit: {
    editButtonContent: '<i class="nb-compose"></i>',
  },
  actions: {
    columnTitle: '',
    delete: false,
    add: false,
  },
  noDataMessage: 'لا يوجد مطالب نفاذ إلى المعلومة',
  columns: {
    id: {
      title: 'عدد المطلب',
      type: 'number',
      width: '5%',
    },
    user_fullname: {
      title: 'اسم الطالب',
      type: 'string',
      width: '15%',
    },
    document: {
      title: 'الوثيقة',
      type: 'string',
      width: '25%',
    },
    status: {
      title: 'الحالة',
      type: 'string',
      width: '15%',
      filter: {
        type: 'list',
        config: {
          selectText: 'الكل',
          list: [],
        },
      },
      valuePrepareFunction: (status) => {
        return toArabicStatus(status);
      },
    },
    format: {
      title: 'الصيغة المطلوبة',
      type: 'string',
      width: '10%',
    },
    created_at: {
      title: 'تاريخ الطلب',
      type: 'string',
      width: '15%',
    },
    daysRemaining: {
      title: 'عدد الايام المتبقية لانقضاء الاجل القانوني',
      type: 'string',
      width: '15%',
    },
    lastTrait: {
      title: 'تاريخ اخر معالجة',
      type: 'string',
      width: '15%',
    },
  },
  rowClassFunction: (row) => {
    return 'status-success';
  },
};
