import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditComponent } from './edit/edit.component';
import { AccountDataComponent } from './account-data.component';
const routes: Routes = [
  {
    path: '',
    component: AccountDataComponent,
    children: [
      {
        path: 'edit',
        component: EditComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountDataRoutingModule {}
