import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { isActiveState } from '../../../core/models/types';
import { Service } from '../../../core/models/class/services';
import { ServicesService } from '../../../services/services.service';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateFeature<Service> implements OnInit {
  item = new Service();

  serviceState = isActiveState;

  constructor(
    protected _servicesService: ServicesService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super('/services', _toastLuncher, _servicesService, router);
  }

  ngOnInit() {}

  protected formIsValid(): boolean {
    if (!this.item.description?.length || !this.item.name) throw new Error('الرجاء ملء إسم الخدمة والوصف');
    return true;
  }
}
