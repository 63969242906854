import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';
import { AuthGuardService } from '../core/auth/auth-guard.service.nb';
import { PermissionsEnum } from '../core/models/types';
import { EditMunicipalityComponent } from '../components/edit-municipality/edit-municipality.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        component: DashboardComponent,
      },
      {
        path: 'procedures',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_PROCEDURES] },
        loadChildren: () => import('./procedures/procedures.module').then((m) => m.ProceduresModule),
      },
      {
        path: 'complaints',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_COMPLAINTS] },
        loadChildren: () => import('./plaints/plaints.module').then((m) => m.PlaintsModule),
      },
      {
        path: 'dossiers',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_DOSSIERS] },
        loadChildren: () => import('./dossiers/dossiers.module').then((m) => m.DossiersModule),
      },
      {
        path: 'events',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_EVENTS] },
        loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
      },
      {
        path: 'forums',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_FORUM] },
        loadChildren: () => import('./forum/forum.module').then((m) => m.ForumModule),
      },
      {
        path: 'subject-access-requests',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_SUBJECT_ACCESS_REQUESTS] },
        loadChildren: () => import('./acces-info/accesinfo.module').then((m) => m.AccesinfoModule),
      },
      {
        path: 'news',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_NEWS] },
        loadChildren: () => import('./news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'polls',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_POLLS] },
        loadChildren: () => import('./polls/polls.module').then((m) => m.PollsModule),
      },
      {
        path: 'reports',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_REPORTS] },
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'elearning',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [] },

        loadChildren: () => import('./elearning/elearning.module').then((m) => m.ElearningModule),
      },
      {
        path: 'managers',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_PERMISSIONS] },
        loadChildren: () => import('./managers/managers.module').then((m) => m.ManagersModule),
      },
      {
        path: 'committees',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_COMMITTEES] },
        loadChildren: () => import('./comites/comites.module').then((m) => m.ComitesModule),
      },
      {
        path: 'emails',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_PERMISSIONS] },
        loadChildren: () => import('./mailing-list/mailing-list.module').then((m) => m.MailingListModule),
      },
      {
        path: 'agencies',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_ETICKET] },
        loadChildren: () => import('./agencies/agencies.module').then((m) => m.AgenciesModule),
      },
      {
        path: 'services',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_ETICKET] },
        loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule),
      },
      {
        path: 'account-data',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [] },
        loadChildren: () => import('./account-data/account-data.module').then((m) => m.AccountDataModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'topics',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_COMPLAINTS] },
        loadChildren: () => import('./topics/topics.module').then((m) => m.TopicsModule),
      },
      {
        path: 'municipality/edit/:id',
        canActivate: [AuthGuardService],
        data: { permissionNeeded: [PermissionsEnum.MANAGE_PERMISSIONS] },
        component: EditMunicipalityComponent,
      },

      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
