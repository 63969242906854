import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { ProceduresService } from '../../../services/procedures.service';

import { Procedure } from '../../../core/models/class/procedure';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { NbLayoutScrollService } from '@nebular/theme';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Procedure> implements OnInit {
  DELETE_TEXT = 'تم حذف الخدمة بنجاح';

  constructor(
    public router: Router,
    public generalService: GeneralService,
    protected _toastLuncher: ToastLuncherService,
    protected scroll: NbLayoutScrollService,
    private proceduresService: ProceduresService,
  ) {
    super(proceduresService.prefix, scroll, proceduresService, router, _toastLuncher);
    this.settings = this.getTableSettings();
  }

  ngOnInit() {}

  getTableSettings() {
    return {
      mode: 'external',
      pager: { display: false },
      noDataMessage: 'لا يوجد خدمات',

      add: {
        addButtonContent: '<i class="nb-plus"></i>',
        createButtonContent: '<i class="nb-checkmark"></i>',
        cancelButtonContent: '<i class="nb-close"></i>',
      },
      edit: {
        editButtonContent: '<i class="nb-compose"></i>',
      },
      actions: {
        columnTitle: '',
        delete: true,
      },
      delete: {
        deleteButtonContent: '<i class="nb-trash"></i>',
        confirmDelete: true,
      },
      columns: {
        title: {
          title: 'العنوان',
          type: 'string',
        },
        type: {
          title: 'النوع',
          type: 'string',
          filter: {
            type: 'list',
            config: {
              selectText: 'الكل',
              list: this.generalService.distinct(this.data, 'type').map((type) => {
                return { value: type, title: type };
              }),
            },
          },
        },
        excerpt: {
          title: 'النص',
          type: 'html',
        },
      },
    };
  }

  preSavingList(data: Procedure[]) {
    data = data.sort((item1, item2) => (item1.display_order > item2.display_order ? 1 : -1));
    return data;
  }
}
