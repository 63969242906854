import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Backend from '../../../core/backend';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'ngx-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  @Input() images: string[] = [];
  @Output() imagesChanged = new EventEmitter<string[]>();
  imageObjectToShow: Array<any> = [];
  baseUrl = Backend.baseUrl;

  constructor(public generalService: GeneralService) {}

  ngOnInit() {
    this.divideImageObject();
  }

  divideImageObject() {
    const imageCopy = this.images.slice();
    this.imageObjectToShow = new Array(Math.ceil(imageCopy.length / 3))
      .fill(imageCopy)
      .map(() => imageCopy.splice(0, 3));
  }

  deletePhoto(i): void {
    this.images.splice(i, 1);
    this.imagesChanged.emit(this.images);
    this.divideImageObject();
  }
}
