import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Complaint } from '../core/models/class/complaint';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ComplaintsService extends GenericCRUDService<Complaint> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/complaints';
  }
}
