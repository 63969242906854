export class Screenshot {
  private static unwantedElements: NodeListOf<HTMLElement>;

  private static printReadyPage() {
    Screenshot.unwantedElements = document.querySelectorAll<HTMLElement>('.delete-print');
    Screenshot.unwantedElements.forEach((el) => (el.style.display = 'none'));
    document.getElementById('logo-print').style.display = 'revert';
    document.querySelector<HTMLElement>('.remove-padding-on-print').style.paddingTop = '0em';
  }
  static downloadScreenshot() {
    Screenshot.printReadyPage();
    window.print();
    location.reload();
  }
}
