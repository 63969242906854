import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Update } from '../models/class/update';
import { BaseService } from './base.service';
import { SubjectAccessRequest } from '../models/class/subject-access-request';
import { DossierAttachment } from '../models/interface/dossier-attachment';
import { DossierUpdate } from '../models/class/dossier';

@Injectable({
  providedIn: 'root',
})
export class UpdateStatusService extends BaseService {
  constructor(protected http: HttpClient) {
    super();
    this.suffix = '/update';
    this.prefix = '/';
  }

  public updateStatus(mode: string, id: number, newUpdate: Update): Observable<any> {
    this.prefix = '/' + mode;
    return this.http.post(this.getUrl() + '/' + id + '/update', newUpdate, {
      headers: BaseService.getHeaders(),
      reportProgress: true,
    }) as Observable<any>;
  }

  public putAttachment(
    endpoint: string,
    updatableAttachment: SubjectAccessRequest | DossierAttachment,
  ): Observable<any> {
    return this.http.put(this.getUrl() + endpoint, updatableAttachment, {
      headers: BaseService.getHeaders(),
      reportProgress: true,
      observe: 'events',
    }) as Observable<any>;
  }

  public postAttachment(endPoint: string, updatableFile: SubjectAccessRequest | DossierAttachment): Observable<any> {
    return this.http.post(this.getUrl() + endPoint, updatableFile, {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }

  public putImage(update_id: number, update: Update | DossierUpdate): Observable<any> {
    return this.http.put(this.getUrl() + 'operation-updates/' + update_id, update, {
      headers: BaseService.getHeaders(),
      reportProgress: true,
    }) as Observable<any>;
  }
}
