import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { getItem, getPageTitle, StorageItem } from './core';
import { MunisFacade } from './core/store';
import { PwaService } from './services/pwa.service';

@Component({
  selector: 'app-root',
  template: `
    <nb-layout>
      <nb-layout-column style="padding: 2em 0px 0px 0px; overflow:hidden;">
        <router-outlet></router-outlet>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class AppComponent implements OnInit {
  isLoginPage: boolean = true;
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;

  constructor(
    private _pwaService: PwaService,
    private themeService: NbThemeService,
    public _munisFacade: MunisFacade,
    private router: Router,
  ) {
    this.setUptheme();
    this._munisFacade.loadMunis();
    this._pwaService.forceUpdate();
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url === 'login' || event.url === 'reset';
        document.title = getPageTitle(location.pathname.split('/')[2]);
      }
    });
  }

  setUptheme() {
    const themes = ['cosmic', 'default', 'corporate', 'dark'];
    let theme = getItem(StorageItem.THEME);
    if (!themes.includes(theme)) theme = 'default';
    this.themeService.changeTheme(theme);
  }
}
