import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SubjectAccessRequest } from '../core/models/class/subject-access-request';
import { GenericCRUDService } from '../core/services/generic.service';
@Injectable({
  providedIn: 'root',
})
export class SubjectAccessRequestService extends GenericCRUDService<SubjectAccessRequest> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/subject-access-requests';
  }
}
