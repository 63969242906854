import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'ngx-account-data',
  template: `<router-outlet></router-outlet>`,
})
export class AccountDataComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
