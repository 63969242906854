import { IfeatureItem } from '../interface/IfeatureItem';
import { Category } from './category';
import { Update } from './update';

export class Complaint implements IfeatureItem {
  municipality_id: number;
  id: number;
  title: string;
  problem: string;
  image: string;
  solution: string;
  longitude: string;
  latitude: string;
  address: string;
  created_by: string;
  created_by_id: number;
  created_at: string;
  status: string;
  updates = new Array<Update>();
  liked: number;
  score: number;
  is_public = true;
  category: string;
  category_obj: Category;
  sub_category: string;
  region: string;
  contact_number: number;
}

export class ComplaintType {
  displayName: string;
  name: string;

  constructor(name: string, displayName: string) {
    this.name = name;
    this.displayName = displayName;
  }
}
