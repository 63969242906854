import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Report } from '../core/models/class/report';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends GenericCRUDService<Report> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/reports';
  }
}
