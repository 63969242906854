import { Component, OnInit } from '@angular/core';
import tableSettings from './mycourses.config';
import { Course } from '../../../core/models/class/course';
import { Router } from '@angular/router';
import { CoursesService } from '../../../services/courses.service';
import { CourseUtility } from '..';

@Component({
  selector: 'ng-mycourses',
  templateUrl: './mycourses.component.html',
})
export class MyCoursesComponent extends CourseUtility implements OnInit {
  constructor(protected _courseService: CoursesService, protected router: Router) {
    super(_courseService, router);
  }
  settings = tableSettings;
  data: Course[] = [];

  ngOnInit() {
    const subscribed_only = true;
    this._courseService.get(subscribed_only).subscribe((data) => (this.data = data));
  }
}
