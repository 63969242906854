import { IfeatureItem } from '../interface/IfeatureItem';

export class Service implements IfeatureItem {
  id: string;
  people_waiting: number;
  name: string;
  description: string;
  is_active: boolean;
  last_booked_ticket?: number;
  current_ticket: number;
  created_at: Date | string;
  updated_at: Date | string;
  created_by: number;
  agency: string;
}
