import { NbMenuItem } from '@nebular/theme';
import { PermissionsEnum } from '../core/models/types';
import { MunisFacade } from '../core/store';

export class PagesMenu {
  static makeMenuItems(permissions: Array<string> = []): NbMenuItem[] {
    return [
      {
        title: 'الـرئـيـسـيـة',
        icon: 'home-outline',
        link: '/pages/dashboard',
      },
      {
        title: 'خـدمـات إداريـة',
        group: true,
      },
      {
        title: 'مـطـالـب الـرخـص',
        icon: 'briefcase-outline',
        link: '/pages/dossiers/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_DOSSIERS),
      },
      {
        title: 'الإجـابـة عن الـتـشـكـيــات',
        icon: 'bell-outline',
        link: '/pages/complaints/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_COMPLAINTS),
      },
      {
        title: 'مـطــالب الـنـفـاذ إلى المـعـلومـة',
        icon: 'clipboard-outline',
        link: '/pages/subject-access-requests/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_SUBJECT_ACCESS_REQUESTS),
      },
      {
        title: 'دليـل الخـدمـات',
        icon: 'file-text-outline',
        link: '/pages/procedures/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_PROCEDURES),
      },
      {
        title: 'خـدمـات تـشـاركـيـة',
        group: true,
      },
      {
        title: 'ﺳـﺒـﺮ اﻟﺂراء',
        icon: 'bar-chart-2-outline',
        link: '/pages/polls/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_POLLS),
      },
      {
        title: 'فـضـاء الآراء و الـتـسـاؤلات',
        icon: 'clipboard-outline',
        link: '/pages/forums/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_FORUM),
      },
      {
        title: 'الـمـسـتـجـدات الـبـلـديـة',
        icon: 'menu-2-outline',
        link: '/pages/news/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_NEWS),
      },
      {
        title: 'رزنـامـة الـمـواعـيـد و الأنـشـطـة',
        icon: 'calendar-outline',
        link: '/pages/events/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_EVENTS),
      },
      {
        title: 'الـتـقـاريـر والـمحـاضـر',
        icon: 'attach-outline',
        link: '/pages/reports/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_REPORTS),
      },
      {
        title: 'قـائـمـة الـلجـان',
        icon: 'people-outline',
        link: '/pages/committees/liste',
        hidden: !permissions.includes(PermissionsEnum.MANAGE_COMMITTEES),
      },

      {
        title: 'التعليم الإلكتروني',
        group: true,
      },
      {
        title: 'الـرئـيـسـيـة',
        icon: 'home-outline',
        link: '/pages/elearning/home',
      },
      {
        title: 'الدورات التعليمية',
        icon: 'home-outline',
        link: '/pages/elearning/courses',
      },
      {
        title: 'دوراتي التعليمية',
        icon: 'home-outline',
        link: '/pages/elearning/mycourses',
      },
      {
        title: 'الانجازات',
        icon: 'home-outline',
        link: '/pages/elearning/achievements',
      },
      {
        title: 'الإعـدادات',
        group: true,
      },
      {
        title: 'إدارة الـمـنـصـة',
        icon: 'options-outline',
        selected: false,
        expanded: false,
        hidden: !permissions.includes(PermissionsEnum.MANAGE_PERMISSIONS),
        children: [
          {
            title: 'إدارة الـمـتـصـرفـيـن فـي الـمـنـصـة',
            icon: 'shield-outline',
            link: '/pages/managers/liste',
            hidden: !permissions.includes(PermissionsEnum.MANAGE_PERMISSIONS),
          },
          {
            title: 'بـيـانـات الـبـلـديـة',
            icon: 'brush-outline',
            link: '/pages/municipality/edit/' + MunisFacade.getMunicipalityId(),
            hidden: !permissions.includes(PermissionsEnum.MANAGE_PERMISSIONS),
          },
          {
            title: 'قـائـمـة إيـمايلات الإحـصـائـيـات',
            icon: 'at-outline',
            link: '/pages/emails/liste',
            hidden: !permissions.includes(PermissionsEnum.MANAGE_PERMISSIONS),
          },
          {
            title: 'قـائـمـة الـدوائـر الـبـلـديـة',
            icon: 'home-outline',
            link: '/pages/agencies/liste',
            hidden: !permissions.includes(PermissionsEnum.MANAGE_ETICKET),
          },
        ],
      },
    ] as NbMenuItem[];
  }
}
