import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ManagersService } from '../../../services/managers.service';
import { Manager } from '../../../core/models/class/manager';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { NbLayoutScrollService } from '@nebular/theme';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { getItem, StorageItem } from '../../../core';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Manager> implements OnInit {
  CONFIRM_DELETE_MESSAGE = 'هل تريد فعلا حذف هذا المتصرف؟';
  constructor(
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected scroll: NbLayoutScrollService,
    private _managersService: ManagersService,
  ) {
    super(_managersService.prefix, scroll, _managersService, router, _toastLuncher);
  }

  settings = {
    mode: 'external',
    pager: { display: false },
    noDataMessage: 'لا يوجد متصرفين في المنصة',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'المعرف الوحيد',
        type: 'string',
      },
      name: {
        title: 'الإسم و اللقب',
        type: 'string',
      },
      phone_number: {
        title: 'الهاتف',
        type: 'string',
      },
      title: {
        title: 'الصفة',
        type: 'string',
      },
      email: {
        title: 'البريد الالكتروني',
        type: 'string',
      },
    },
  };

  ngOnInit() {}

  protected preSavingList(data: Manager[]): Manager[] {
    // remove the logged in user from list
    // remove deleted managers from the list
    return data.filter((m) => m.id.toString() !== getItem(StorageItem.connected_user_id) && !m.is_deleted);
  }

  getDeleteIdentifier($event): number {
    return $event.data.manager_id;
  }
}
