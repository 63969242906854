import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { commentTypeToArabic, timeAgo } from '../../../core';
import Backend from '../../../core/backend';
import { Comment } from '../../../core/models/class/comment';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { CommentTypes, UpdateModeTypes } from '../../../core/models/types';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { ForumService } from '../../../services/forum.service';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Comment> implements OnInit {
  baseUrl = Backend.baseUrl;
  modeInput = UpdateModeTypes.MODE_FORUM;
  type: string;
  commentToAdd: Comment = new Comment();
  item: Comment = new Comment();

  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    public _forumService: ForumService,
  ) {
    super('id', _forumService.prefix, _toastLuncher, _forumService, router, route);
  }

  ngOnInit() {}

  protected normalizeAfterGetItem(item) {
    item.sub_comments = item.sub_comments.sort((item1, item2) => (item1.created_at < item2.created_at ? -1 : 1));
    this.type = item.type;
    item.type = this.getArabicType(item.type as CommentTypes);
    return item;
  }

  addComment() {
    if (!this.commentToAdd.body) {
      throw new Error('يجب أن يحتوي التعليق على محتوى');
    }
    this.commentToAdd.parent_comment_id = this.item.id;
    this.commentToAdd.title = 'subcomment';
    this._forumService.post(this.commentToAdd).subscribe((data) => {
      this.item.sub_comments.push(data);
      this.commentToAdd = new Comment();
    });
  }
  timeAgo(sec) {
    return timeAgo((Date.now() - Date.parse(sec)) / 1000);
  }

  getArabicType(value: CommentTypes): string {
    return commentTypeToArabic(value);
  }
}
