import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { PlaintsComponent } from './plaints.component';
const routes: Routes = [
  {
    path: '',
    component: PlaintsComponent,
    children: [
      {
        path: 'liste',
        component: ListeComponent,
      },
      {
        path: 'edit/:id',
        component: EditComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlaintsRoutingModule {}
