import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommitteeService } from '../../../services/committee.service';
import { Committee } from '../../../core/models/class/committee';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { NbLayoutScrollService } from '@nebular/theme';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Committee> implements OnInit {
  constructor(
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    public _committeeService: CommitteeService,
    protected scroll: NbLayoutScrollService,
  ) {
    super(_committeeService.prefix, scroll, _committeeService, router, _toastLuncher);
  }

  settings = {
    mode: 'external',
    pager: { display: false },
    noDataMessage: 'لا يوجد لجان',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'المعرف',
        type: 'number',
        filter: false,
        width: '7%',
      },
      /*
          id: {
            title: '#',
            type: 'number',
          },
          */
      title: {
        title: 'اللجنة',
        type: 'string',
        width: '150px',
      },
      body: {
        title: 'وصف اللجنة',
        type: 'html',
      },
    },
  };

  ngOnInit() {}
}
