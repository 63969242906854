import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduce',
})
export class ReducePipe implements PipeTransform {
  transform(arr: Obj[], key: string): number {
    const results = arr.reduce((prev, cur) => cur[key] + prev, 0);
    if (!isNaN(results)) return results;
    return 0;
  }
}

interface Obj {
  [key: string]: number;
}
