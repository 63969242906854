import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { CommitteeService } from '../../../services/committee.service';
import { Committee } from '../../../core/models/class/committee';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateFeature<Committee> implements OnInit {
  item = new Committee();

  constructor(
    private _committeeService: CommitteeService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super(_committeeService.prefix, _toastLuncher, _committeeService, router);
  }

  ngOnInit() {}
}
