export default {
  mode: 'external',
  pager: { display: false },
  noDataMessage: 'لا يوجد صنيفات',
  add: {
    addButtonContent: '<i class="nb-plus"></i>',
    createButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
  },
  delete: {
    deleteButtonContent: '<i class="nb-trash"></i>',
    confirmDelete: true,
  },
  edit: {
    editButtonContent: '<i class="nb-compose"></i>',
  },
  actions: {
    columnTitle: '',
    delete: true,
  },
  columns: {
    label: {
      title: 'العنوان',
      type: 'string',
    },
    description: {
      title: 'الوصف',
      type: 'html',
    },
    state: {
      title: 'الحالة',
      type: 'string',
      valuePrepareFunction(state) {
        if (state === 'HIDDEN') return 'مغلق';
        return 'مفتوح للعموم';
      },
      filter: {
        type: 'list',
        config: {
          selectText: 'الكل',
          list: [],
        },
      },
    },
    created_at: {
      title: 'تارخ الإضافة',
      type: 'date',
    },
  },
};
