import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Certificate, Course, Enrollement, Restored_progress, course_progress, quiz_result } from '../core/models/class/course';
import { Observable } from 'rxjs';
import Backend from '../core/backend';
import { BaseService } from '../core/services/base.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoursesService extends BaseService {
  prefix = '/elearning';
  url = Backend.url;
  constructor(private httpClient: HttpClient) {
    super();
  }

  get(subscribed_only: boolean = false): Observable<Course[]> {
    const endpoint = subscribed_only ? '/subscribed-courses/' : '/courses';
    return this.httpClient.get<Course[]>(this.url + this.prefix + endpoint, { headers: BaseService.getHeaders() });
  }
  getById(id: string): Observable<Course> {
    return this.httpClient.get<Course>(this.url + this.prefix + '/courses/' + id);
  }
  getMostSubscribed(): Observable<Course[]> {
    return this.httpClient.get<Course[]>(this.url + this.prefix + '/most-enrolled-courses');
  }
  getLatestSubscribed(): Observable<Course[]> {
    return this.httpClient.get<Course[]>(this.url + this.prefix + '/latest-subscribed-courses/', {
      headers: BaseService.getHeaders(),
    });
  }
  checkEnrollment(id: string | number): Observable<Enrollement> {
    return this.httpClient.get<Enrollement>(`${this.url}${this.prefix}/courses/${id}/enrollment/check`, {
      headers: BaseService.getHeaders(),
    });
  }
  subscribeToCourse(id:number):Observable<Course>{
    return this.httpClient.get<Course>(`${this.url}${this.prefix}/courses/${id}/subscribe`, {
      headers: BaseService.getHeaders(),
    });

  }
  quizzSubmit(courseId,quizzId,Answer):Observable<quiz_result>{
    return this.httpClient.post<quiz_result>(`${this.url}${this.prefix}/courses/${courseId}/quizzes/${quizzId}/submit`,{"answers":Answer});

  }
  saveProgress(courseId,sectionId,lessonId):Observable<course_progress>{
    return this.httpClient.post<course_progress>(`${this.url}${this.prefix}/courses/${courseId}/save-progress`,{
      "lesson_id":lessonId,    
        "section_id":sectionId

    },{headers:BaseService.getHeaders()})
  }
  getProgress(courseId):Observable<Restored_progress>{
        return this.httpClient.get<Restored_progress>(`${this.url}${this.prefix}/courses/${courseId}/restore-progress`,{
          headers:BaseService.getHeaders()
        })
  }
  getCertificates():Observable<Certificate[]>{

    return this.httpClient.get<Certificate[]>(`${this.url}${this.prefix}/certificates/`,{
      headers:BaseService.getHeaders()
    })
}

  
}
