import { NgModule } from '@angular/core';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbMenuModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTooltipModule,
} from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { PagesRoutingModule } from './pages-routing.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { HistoriqueModule } from '../components/historique/historique.module';
import { CommonModule } from '@angular/common';
import { SpinnerModule } from '../components/loading-spinner/spinner.module';
import { FormsModule } from '@angular/forms';
import { EditMunicipalityComponent } from '../components/edit-municipality/edit-municipality.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CourseLearningComponent } from './elearning/course-learning/course-learning.component';

@NgModule({
  declarations: [PagesComponent, EditMunicipalityComponent],
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    DashboardModule,
    MiscellaneousModule,
    HistoriqueModule,
    NbTooltipModule,
    CommonModule,
    NbButtonModule,
    SpinnerModule,
    NbCardModule,
    FormsModule,
    NbSpinnerModule,
    NbInputModule,
    NbIconModule,
    NgbModule,
    NbAccordionModule,
    NbStepperModule,
  ],
})
export class PagesModule { }
