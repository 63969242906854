import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as UserActions from './user.action';
import { UserState } from './user.reducer';
import { selectedAuth, selectLoggedInUser, selectUserIsloading } from '..';
import { LoginCredentials } from '../../../models/class/user';
import { clearItems, getItem, StorageItem } from '../../../lib/local-storage.utils';
import { IUserFacade } from '../../../models/interface/user.facade.interface';

@Injectable({
  providedIn: 'root',
})
export class UserFacade implements IUserFacade {
  authData$ = this.store.pipe(select(selectedAuth));
  isLoading$ = this.store.pipe(select(selectUserIsloading));
  loggedInUser$ = this.store.pipe(select(selectLoggedInUser));

  constructor(public store: Store<UserState>) {}

  startLogin(creds: LoginCredentials) {
    this.store.dispatch(new UserActions.LoginStart(creds));
  }

  loginFailure() {
    this.store.dispatch(new UserActions.LoginFailure());
  }

  loadUser() {
    if (this.isAuthenticated()) this.store.dispatch(new UserActions.LoadUser());
  }

  logout() {
    this.store.dispatch(new UserActions.LogoutUser());
    clearItems();
  }

  refresh() {
    this.store.dispatch(new UserActions.RefreshTokenStart());
  }

  isAuthenticated(): boolean {
    return UserFacade.getToken()?.length ? true : false;
  }

  static getToken(): string | null {
    return getItem(StorageItem.token);
  }
}
