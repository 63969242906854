import { Component, OnInit } from '@angular/core';
import * as ol from 'openlayers';
import { Router } from '@angular/router';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { Agency } from '../../../core/models/class/agency';
import { AgenciesService } from '../../../services/agencies.service';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateFeature<Agency> implements OnInit {
  item = new Agency();

  constructor(
    protected _agenciesService: AgenciesService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super(_agenciesService.prefix, _toastLuncher, _agenciesService, router);
  }

  ngOnInit() {}

  getCurrentPos() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.item.longitude = +pos.coords.longitude;
        this.item.latitude = +pos.coords.latitude;
      });
    }
  }

  onLocationSelect($event) {
    this.item.latitude = parseFloat(ol.proj.transform($event.coordinate, 'EPSG:3857', 'EPSG:4326')[1].toFixed(6));
    this.item.longitude = parseFloat(ol.proj.transform($event.coordinate, 'EPSG:3857', 'EPSG:4326')[0].toFixed(6));
  }
}
