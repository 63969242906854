import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TopicsService } from '../../../services/topics.service';
import { environment } from '../../../../environments/environment';
import { Topic } from '../../../core/models/class/topic';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { MunisFacade } from '../../../core/store';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Topic> implements OnInit {
  item = new Topic();
  topicStates = [
    {
      value: 'ACTIVATED',
      text: 'مفتوح للعموم',
    },
    {
      value: 'HIDDEN',
      text: 'مغلق',
    },
  ];

  constructor(
    public router: Router,
    public datePipe: DatePipe,
    public route: ActivatedRoute,
    protected _toastLuncher: ToastLuncherService,
    private _muniFacade: MunisFacade,
    private _topicsService: TopicsService,
  ) {
    super('id', _topicsService.prefix, _toastLuncher, _topicsService, router, route);
  }

  ngOnInit() {}

  protected normalizeAfterGetItem(item) {
    item.created_at = this.datePipe.transform(item.created_at, 'yyyy-MM-dd');
    item.created_at = new Date(Date.parse(item.created_at)).toString();
    return item;
  }

  copyFormToTopic() {
    if (this.item.state !== 'ACTIVATED') throw new Error('هذا التصنيف غير مفتوح للعموم');

    this._muniFacade.selectedMuni$.subscribe((data) => {
      const baseUrl = environment.production ? 'https://elbaladiya.tn/' : 'https://dev-citizen.elbaladiya.tn/';
      navigator.clipboard.writeText(baseUrl + data.route_name + '/nouveau-post/?topic=' + this.item.id);
      this._toastLuncher.success('تم نسخ الرابط لتصنيف: ' + this.item.label);
    });
  }
}
