import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareSingleViewComponent } from './share-single-view.component';
import { NbIconModule, NbToggleModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';

@NgModule({
  declarations: [ShareSingleViewComponent],
  imports: [CommonModule, FormsModule, NbEvaIconsModule, NbIconModule, NbToggleModule],
  exports: [ShareSingleViewComponent],
})
export class ShareSingleViewModule {}
