import { Component, OnInit } from '@angular/core';
import { MunisFacade } from '../../../core/store';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by"> Copyrights {{ currentYear }} Tounes Lina </span>
    <div *ngIf="partners?.length" class="socials">
      <p style="display: table-cell; vertical-align: middle">
        {{ partners }}
      </p>
    </div>
  `,
})
export class FooterComponent implements OnInit {
  currentYear: number;
  partners: string = null;

  constructor(protected _muniFacade: MunisFacade) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this._muniFacade.selectedMuni$.subscribe((data) => {
      if (data?.partner_associations?.length) {
        const names: string[] = data.partner_associations.map((a) => a.full_name_arabic);
        this.partners = `بالتعاون مع: ${names.join(',')}`;
      }
    });
  }
}
