import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { GenericCRUDService } from '../../services/generic.service';
import { ToastLuncherService } from '../../services/toast-luncher.nb.service';
import { IfeatureItem, IHasImage } from '../interface/IfeatureItem';
import { GenericSingleView } from './GenericSingleView';
import { Screenshot } from './screenshot';
import * as $ from 'jquery';
import { areValidImages } from '../../lib/functions';
import { ACCEPTED_IMAGES } from '../../config';

export class GenericEditFeature<T extends IfeatureItem> extends GenericSingleView<T> {
  ACCEPTED_IMAGES = ACCEPTED_IMAGES;

  constructor(
    public item_param_name: string,
    protected featureName: string,
    protected _toastLuncher: ToastLuncherService,
    protected _service: GenericCRUDService<T>,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
  ) {
    super(item_param_name, _service, activeRoute);
  }

  protected formIsValid(): boolean {
    return true;
  }

  protected beforeSubmit(item: T): T {
    return item;
  }

  protected normalizeAfterGetItem(item: T): T {
    return item;
  }

  getItem(): void {
    this.activeRoute.params.subscribe((params) => {
      this.item_id = params[this.item_param_name];
      this._service.getById(this.item_id.toString()).subscribe(
        (data) => {
          this.item = { ...this.item, ...this.normalizeAfterGetItem(data) };
          this.isLoading = false;
        },
        (error) => {
          throw new Error('لم نتمكن من العثور على المعلومات المطلوبة');
        },
      );
    });
  }

  submit(): void {
    if (!this.formIsValid()) return;
    this.isLoading = true;
    this._service.put(this.item.id, this.beforeSubmit(this.item)).subscribe(
      (data) => {
        this._toastLuncher.success('تم التعديل بنجاح');
        this.onSubmitSuccess();
      },
      (error) => this.onSubmitError(error),
    );
  }

  onSubmitSuccess() {
    this.router.navigate([`/pages${this.featureName}/liste`]);
  }

  onSubmitError(error): void {
    this.isLoading = false;
    if (error.status === 400) throw new Error('الرجاء التثبت من المعطيات المدرجة');
    if (error.status === 409) throw new Error('يوجد ملف اخر يحمل نفس المعطيات');
    if (error.status === 500) throw new Error('!لم نتمكن من الاتصال بالمنصة، ارجاء المحاولة لاحقاً.');
    else throw new Error('لم نتمكن من التعديل');
  }
  /**
   * creates a print optimized DOM
   */
  downloadScreenshot(): void {
    Screenshot.downloadScreenshot();
  }

  downloadAsDocX() {
    this._service.getDocX(parseInt(this.item_id)).subscribe((data) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = `${this.featureName}-${this.item_id}.docx`;
      link.click();
    });
  }
}

export class GenericEditWithImage<T extends IfeatureItem> extends GenericEditFeature<T> {
  hasImage = false;
  isFileSizePassed = true;

  constructor(
    public item_param_name: string,
    protected featureName: string,
    protected _toastLuncher: ToastLuncherService,
    protected _service: GenericCRUDService<T>,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected imageCompress: NgxImageCompressService,
  ) {
    super(item_param_name, featureName, _toastLuncher, _service, router, activeRoute);
  }

  onChangeImagefile({ target: { files } }, ...args) {
    const fileError = areValidImages(files);
    if (fileError.error) throw new Error(fileError.errorMessage);

    const base = this;
    [].forEach.call(files, function (file) {
      base.handelFile(file);
    });
  }

  handelFile(file: any, ...args) {
    this.hasImage = false;
    const reader2 = new FileReader();
    let src;
    reader2.onload = (e) => {
      src = reader2.result;
      this.imageCompress
        .compressFile(src, 1, 75, 75) // 75% ratio, 75% quality
        .then((compressedImage) => {
          this.onImageLoad(e, compressedImage, ...args);
        });
    };
    if (file.size < 5300000 * 2) {
      this.isFileSizePassed = true;
      reader2.readAsDataURL(file);
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = false;
      throw new Error('الرجاء ادخال صورة لا تتجاوز 10MB');
    }
  }

  import() {
    document.getElementById('file_to_upload').click();
  }

  onImageLoad($event, compressedImage, ...args) {
    undefined;
  }

  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    return canvas.toDataURL('image/jpeg');
  }
}

export class GenericHasImageUpload<T extends IHasImage> {
  FILE_UPLOAD_NAME = 'file_to_upload';
  hasImage = true;
  isFileSizePassed = true;
  fileName: string = null;

  constructor(protected _toastLuncher: ToastLuncherService, protected imageCompress: NgxImageCompressService) {}

  onChangeImagefile({ target: { files } }, ...args) {
    const fileError = areValidImages(files);
    if (fileError.error) throw new Error(fileError.errorMessage);

    const base = this;
    [].forEach.call(files, function (file) {
      base.handelFile(file);
    });
  }

  handelFile(file: File, ...args) {
    this.hasImage = false;
    const reader2 = new FileReader();
    let src;
    reader2.onload = (e) => {
      src = reader2.result;
      this.imageCompress
        .compressFile(src, 1, 75, 75) // 75% ratio, 75% quality
        .then((compressedImage) => {
          this.fileName = file.name;
          this.onImageLoad(e, compressedImage, ...args);
        });
    };
    if (file.size < 5300000 * 2) {
      this.isFileSizePassed = true;
      reader2.readAsDataURL(file);
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = false;
      throw new Error('الرجاء ادخال صورة لا تتجاوز 10MB');
    }
  }

  import() {
    document.getElementById(this.FILE_UPLOAD_NAME).click();
  }

  onImageLoad($event, compressedImage, ...args) {
    undefined;
  }

  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    return canvas.toDataURL('image/jpeg');
  }
}
