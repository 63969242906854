import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditComponent } from './edit/edit.component';
import { ListeComponent } from './liste/liste.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { ReportService } from '../../services/report.service';
import { CommitteeService } from '../../services/committee.service';
import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import { ProgressBarModule } from '../../components/progress-bar/progress-bar.module';
import { CreateComponent } from './create/create.component';

@NgModule({
  declarations: [EditComponent, ListeComponent, CreateComponent, ReportsComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    ThemeModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    SpinnerModule,
    ProgressBarModule,
    NbSpinnerModule,
  ],
  providers: [ReportService, CommitteeService],
})
export class ReportsModule {}
