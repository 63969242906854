import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { UpdateTypes } from '../core/models/types';
import { UpdateTypeToArabicPipe } from '../core/pipes/update-type-to-arabic.pipe';

const pipe = new UpdateTypeToArabicPipe();
@Injectable()
export class GeneralService {
  constructor(public http: HttpClient, public toastrService: NbToastrService) {}
  static filterLabels = Object.keys(UpdateTypes).map((updateType, id) => {
    return {
      id,
      value: updateType,
      title: pipe.transform(updateType),
    };
  });
  types: NbComponentStatus[] = ['danger', 'success'];

  distinct(ds, attribut) {
    return ds.map((item) => eval('item.' + attribut)).filter((value, index, self) => self.indexOf(value) === index);
  }

  dialogSuccess(msg: string) {
    this.toastrService.show('', msg, {
      status: 'success',
      destroyByClick: true,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      preventDuplicates: true,
    });
  }
}
