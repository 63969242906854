import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../../../services/courses.service';
import { Certificate } from '../../../core/models/class/course';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'ngx-achievement',
    templateUrl: 'achievement.component.html',
    styleUrls:['./achievement.component.css']
})

export class AchievementComponent implements OnInit {

    //TODO: add course name when backend changes done
    settings={
        actions: false,
  columns: {
    title: {
      title: 'التاريخ',
      filter: false,
      valuePrepareFunction: (cell, row) => {
        return this.datePipe.transform( row.created_at, 'yyyy-MM-dd') ;
      },
    },
    actions: {
      title: 'الشهادة',
      filter: false,
      type: 'html',
      valuePrepareFunction: (cell, row) => {
        return `<a download="certificate.pdf" href="${row.attachment}" style="color: white; text-decoration: none;">تحميل</a>`;
      },
    },
  }
    }
    certificates:Certificate[]
   
    constructor(protected _courseService:CoursesService,private datePipe: DatePipe) { }

    ngOnInit() {
        this._courseService.getCertificates().subscribe((data)=>this.certificates=data)
     }
}