import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '../../../services/report.service';
import { CommitteeService } from '../../../services/committee.service';

import * as $ from 'jquery';
import { Committee } from '../../../core/models/class/committee';
import { Report } from '../../../core/models/class/report';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { DatePipe } from '@angular/common';
import Backend from '../../../core/backend';
import { normalizedPDF } from '../../../core';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Report> implements OnInit {
  committees: Committee[] = [];
  item = new Report();
  baseUrl = Backend.baseUrl;
  selectedFileName: string;
  fileSize: number;
  isFilePDF: boolean = true;
  isFileSizeValid: boolean = true;
  isUploading = false;
  progress = 0;

  constructor(
    private datePipe: DatePipe,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    public _committeeService: CommitteeService,
    private _reportService: ReportService,
  ) {
    super('id', _reportService.prefix, _toastLuncher, _reportService, router, route);
    this.getCommittees();
  }

  ngOnInit() {}

  getCommittees() {
    this._committeeService.get().subscribe((data) => {
      this.committees = data;
    });
  }

  protected normalizeAfterGetItem(item) {
    item.date = new Date(Date.parse(item.date)).toString();
    return item;
  }

  protected beforeSubmit(item: Report): Report {
    if (!item?.attachment) delete item.attachment;
    item.id = parseInt(this.item_id, 10);
    item.date = this.datePipe.transform(item.date, 'yyyy-MM-dd');
    return item;
  }

  openSelectFileDialog() {
    document.getElementById('input_file').click();
  }

  onFileChange(fileInput) {
    const reader2 = new FileReader();
    reader2.onload = (e) => {
      this.item.attachment = normalizedPDF(reader2.result);
      this.selectedFileName = fileInput.target.files[0].name;
      this.fileSize = fileInput.target.files[0].size;
    };
    if (fileInput.target.files.length) {
      if (fileInput.target.files[0].size < 5300000 * 2 && fileInput.target.files[0].type.match('application/pdf')) {
        this.isFileSizeValid = true;
        this.isFilePDF = true;
        this.isLoading = false;
        reader2.readAsDataURL(fileInput.target.files[0]);
      } else if (!fileInput.target.files[0].type.match('application/pdf')) {
        this.isLoading = true;
        this.isFilePDF = false;
        this.isFileSizeValid = true;
        this.selectedFileName = 'Only PDF Files could be read';
        throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
      } else {
        $('#helpModal').modal('toggle');
        this.isLoading = true;
        this.isFileSizeValid = false;
        this.selectedFileName = 'Maximum file size is 10MB';
        throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
      }
    } else {
      this.selectedFileName = 'No files selected';
      this.isFilePDF = true;
    }
  }
}
