
import { firebaseConfig } from "./production.firebase";
export const environment = {
   apiBaseUrl: 'https://backend.elbaladiya.tn',
   apiUrl: 'https://backend.elbaladiya.tn/api',
   appName: 'elBaladiya.tn',
   nodeEnv: 'production',
   production: true,
   bugsnagKey: 'undefined',
   firebase: firebaseConfig,
};
