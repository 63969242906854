import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { NewsService } from '../../../services/news.service';
import { NbLayoutScrollService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import { News } from '../../../core/models/class/news';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<News> implements OnInit {
  constructor(
    public router: Router,
    public generalService: GeneralService,
    protected _toastLuncher: ToastLuncherService,
    public datePipe: DatePipe,
    protected scroll: NbLayoutScrollService,
    private _newsService: NewsService,
  ) {
    super(_newsService.prefix, scroll, _newsService, router, _toastLuncher);
  }

  settings = {
    mode: 'external',
    pager: { display: false },
    noDataMessage: 'لا يوجد مستجدات بلدية',

    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    columns: {
      title: {
        title: 'العنوان',
        type: 'string',
      },
      excerpt: {
        title: 'مقتطفات',
        type: 'html',
      },
      category: {
        title: 'النوع',
        type: 'string',
        filter: {
          type: 'list',
          config: {
            selectText: 'الكل',
            list: [],
          },
        },
      },
      tags: {
        title: 'المحاور',
        type: 'string',
      },
      published_at: {
        title: 'يوم تنزيل الخبر',
        type: 'date',
      },
    },
  };

  ngOnInit() {}

  preSavingList(data: News[]) {
    data = data.sort((item1, item2) => (item1.published_at > item2.published_at ? -1 : 1));
    data.forEach((item) => {
      item.published_at = this.datePipe.transform(item.published_at, 'yyyy-MM-dd');
      // item.published_at = GeneralService.timeAgo((Date.now() - Date.parse(item.published_at)) / 1000);
    });
    const categories = this.generalService.distinct(data, 'category');
    this.settings.columns.category.filter.config.list.forEach((item) => {
      if (categories.includes(item.value)) {
        categories.splice(categories.indexOf(item.value), 1);
      }
    });
    categories.forEach((element) => {
      this.settings.columns.category.filter.config.list.push({
        value: element,
        title: element,
        selected: true,
      });
    });
    return data;
  }
}
