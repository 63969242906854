import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DossiersService } from '../../../services/dossiers.service';
import { Dossier } from '../../../core/models/class/dossier';
import { UpdateModeTypes } from '../../../core/models/types';
import { dossierTypes, isValidPhoneNumber } from '../../../core';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Dossier> implements OnInit {
  modeInput = UpdateModeTypes.MODE_DOSSIER;
  types = dossierTypes;
  item = new Dossier();

  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected _dossiersService: DossiersService,
  ) {
    super('id', _dossiersService.prefix, _toastLuncher, _dossiersService, router, route);
  }

  ngOnInit() {}

  protected normalizeAfterGetItem(item: Dossier) {
    item.deposit_date = new Date(Date.parse(item.deposit_date as string)).toString();
    const arabicType = this.types.find((t) => t.name == item.type);
    if (arabicType !== undefined) item.type = arabicType.displayName;
    return item;
  }

  protected formIsValid() {
    if (!isValidPhoneNumber(this.item.phone_number)) {
      throw new Error('الرجاء إدخال رقم هاتف صحيح');
    }
    return true;
  }
}
