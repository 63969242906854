import { timeAgo, toArabicStatus } from '../../../core';

export default {
  mode: 'external',
  pager: { display: false },
  noDataMessage: 'لا يوجد خدمات حالياً',
  add: {
    addButtonContent: '<i class="nb-plus"></i>',
    createButtonContent: '<i class="nb-checkmark"></i>',
    cancelButtonContent: '<i class="nb-close"></i>',
  },
  edit: {
    editButtonContent: '<i class="nb-compose"></i>',
  },
  actions: {
    columnTitle: '',
    delete: true,
  },
  delete: {
    deleteButtonContent: '<i class="nb-trash"></i>',
    confirmDelete: true,
  },
  columns: {
    name: {
      title: 'الاسم',
      type: 'number',
      width: '10%',
    },
    description: {
      title: 'الوصف',
      type: 'number',
      width: '40%',
    },
    people_waiting: {
      title: 'مواطنون في الانتظار',
      type: 'string',
      width: '10%',
      valuePrepareFunction: (people_waiting) => {
        if (typeof people_waiting !== 'number') return 0;
        return people_waiting;
      },
    },
    current_ticket: {
      title: 'التذكرة الحالية',
      type: 'string',
      width: '10%',
    },
    is_active: {
      title: 'الحالة',
      type: 'string',
      width: '15%',
      valuePrepareFunction: (is_active) => {
        if (!is_active) return 'مغلق للعموم';
        return 'مفتوح للعموم';
      },
    },
    created_at: {
      title: 'أضيف في',
      type: 'string',
      width: '15%',
      valuePrepareFunction: (created_at) => {
        return timeAgo(created_at);
      },
    },
  },
  rowClassFunction: (row) => {
    return 'status-success';
  },
};
