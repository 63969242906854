export class TextService {
  text = {
    ltr: {
      id: 'ID',
      titre: 'Titre',
      matricule: 'Matricule',
      demandeur: 'Nom Demandeur',
      status: 'Status',
      remarque: 'remarque',
      type: 'Type',
      enregistrer: 'Enregistrer',
    },
    rtl: {
      id: 'المعرف',
      titre: 'العنوان',
      matricule: 'رَقْمُ تَسْجيلٍ',
      demandeur: 'الطالب',
      status: 'الوضع',
      remarque: 'ملاحظة',
      type: 'النوع',
      enregistrer: 'تسجيل',
    },
  };
}
