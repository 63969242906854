import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Dossier } from '../../../core/models/class/dossier';
import { proceduresCategories } from '../../../core';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { ProceduresService } from '../../../services/procedures.service';
import { Procedure } from '../../../core/models/class/procedure';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent extends GenericCreateFeature<Procedure> implements OnInit {
  item = new Procedure();
  proceduresCategories = proceduresCategories;

  constructor(
    public datePipe: DatePipe,
    private _proceduresService: ProceduresService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super(_proceduresService.prefix, _toastLuncher, _proceduresService, router);
    this.item.type = this.proceduresCategories[0].displayName as any;
  }

  ngOnInit() {}
}
