import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GenericCRUDService } from '../core/services/generic.service';
import { Choice } from '../core/models/class/polls';
import { BaseService } from '../core/services/base.service';
import { getItem, StorageItem } from '../core';
import { MunisFacade } from '../core/store';

@Injectable({
  providedIn: 'root',
})
export class PollsChoiceService extends GenericCRUDService<Choice> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/polls/';
    this.suffix = '/';
  }

  getUrl() {
    return (
      BaseService.url +
      '/municipalities/' +
      MunisFacade.getMunicipalityId() +
      this.prefix +
      getItem(StorageItem.poll_id) +
      '/choices'
    );
  }
}
