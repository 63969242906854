export enum logTypes {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
}

export interface IlogItem {
  type: logTypes;
  text: string;
  id: number;
}
