import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbLayoutScrollService } from '@nebular/theme';
import * as $ from 'jquery';

import { ReportService } from '../../../services/report.service';
import { CommitteeService } from '../../../services/committee.service';
import { Committee } from '../../../core/models/class/committee';
import { Report } from '../../../core/models/class/report';
import { GenericListFeature } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeature<Report> implements OnInit {
  constructor(
    public router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected scroll: NbLayoutScrollService,
    private _reportService: ReportService,
    private committeeService: CommitteeService,
  ) {
    super(_reportService.prefix, scroll, _reportService, router, _toastLuncher);
    this.getCommittees();
  }
  committees: Committee[] = [];
  settings = {
    mode: 'external',
    pager: { display: false },
    noDataMessage: 'لا يوجد تقارير',
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-compose"></i>',
    },
    actions: {
      columnTitle: '',
      delete: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'عدد التقرير',
        type: 'number',
      },
      committee: {
        title: 'اللجنة/الهيكل',
        type: 'string',
      },

      title: {
        title: 'العنوان',
        type: 'string',
      },
      date: {
        title: 'التاريخ',
        type: 'string',
      },
    },
  };

  ngOnInit() {}

  getCommittees() {
    this.committeeService.get().subscribe((data) => {
      this.committees = data;
      this.setCommittees();
    });
  }

  setCommittees() {
    this.data.map((item) => {
      this.committees.map((comittee) => {
        if (item.committee_id === comittee.id) {
          item.committee = comittee.title;
        }
        return comittee;
      });
      return item;
    });
    this.source.load(this.data).then(() => {
      setTimeout(() => {
        $('.ng2-smart-action-edit-edit').on('mouseup', function (e) {
          if (!e.ctrlKey && e.which !== 1) {
            e.target.click();
          }
          e.preventDefault();
        });
      }, 500);
    });
  }

  preSavingList(data: Report[]) {
    this.setCommittees();
    data = data.sort((item1, item2) => (item1.date > item2.date ? -1 : 1));
    return data;
  }
}
