import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Comment } from '../core/models/class/comment';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ForumService extends GenericCRUDService<Comment> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/forum/comments';
  }
}
