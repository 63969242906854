import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbLayoutDirection, NbLayoutDirectionService, NbMenuService, NbSidebarService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { defaultAnimation, getItem, navigateNotification, StorageItem } from '../../../core';
import Backend from '../../../core/backend';
import { Notification } from '../../../core/models/class/notification';
import { ManagerService } from '../../../core/services/manager.service';
import { NotificationService } from '../../../core/services/notification.service';
import { MunisFacade } from '../../../core/store';
import { ManagerFacade } from '../../../core/store/src/user/manager.facade';
import { notificationRoutes } from '../../../core';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  ...defaultAnimation,
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  userPictureOnly = false;

  user_name = '';

  Commune_nom: any;

  userMenu = [
    {
      title: 'مـعـطـيـات الـحـسـاب',
      icon: 'person-outline',
      onClick: () => {
        this.router.navigate(['/pages/account-data/edit']);
      },
    },
    {
      title: 'الخروج',
      icon: 'log-out-outline',
      onClick: () => {
        this._ManagerFacade.logout();
        this.router.navigate(['/login']);
      },
    },
  ];

  baseUrl = Backend.baseUrl;

  selectedMuni$ = this._munisFacade.selectedMuni$;

  notifications: Array<Notification> = new Array<Notification>();

  notReadNotifsLength;
  testAnimate;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private directionService: NbLayoutDirectionService,

    private _ManagerFacade: ManagerFacade,
    private _munisFacade: MunisFacade,
    private nbMenuService: NbMenuService,
    private router: Router,
    public notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.user_name = getItem(StorageItem.admin_name);
    this.setLanguage('RTL');

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => {
        this.userMenu.forEach((item) => {
          if (title === item.title) item.onClick();
        });
      });

    this.getNotifications();
  }

  readAllNotifications() {
    this.notifications.forEach((item) => (item.is_read = true));
    this.notReadNotifsLength = 0;
    this.notificationService.markAllAsRead().subscribe();
    this.testAnimate = false;
  }
  private getNotifications() {
    this.notificationService.getNotifications().subscribe((data) => {
      if (data.notifications) {
        this.notifications = data.notifications;

        this.notReadNotifsLength = this.notifications.filter((item) => item.is_read === false).length;
        this.testAnimate = this.notReadNotifsLength > 0 ? true : false;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  menuVisible = false;

  showMenu() {
    this.menuVisible = !this.menuVisible;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    this.toggleSidebar();
    return false;
  }

  get isRtl() {
    return this.directionService.isRtl();
  }

  setLanguage(oppositeDirection: string) {
    if (oppositeDirection === 'LTR') this.directionService.setDirection(NbLayoutDirection.LTR);
    else this.directionService.setDirection(NbLayoutDirection.RTL);
  }
  markAsRead(index: number, redirect: string) {
    this.notificationService.markAsRead(this.notifications[index].id.toString()).subscribe((data) => {
      if (!this.notifications[index].is_read) {
        this.notReadNotifsLength--;
        this.notifications[index].is_read = true;
      }

      if (typeof redirect === 'string') this.router.navigate([navigateNotification(redirect)]);
    });
    this.testAnimate = false;
  }
}
