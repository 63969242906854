import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { topicsRoutingModule } from './topics-routing.module';
import { TopicsComponent } from './topics.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import { ImageSliderModule } from '../../components/image-slider/image-slider.module';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { CreateComponent } from './create/create.component';

@NgModule({
  declarations: [ListeComponent, EditComponent, CreateComponent, TopicsComponent],
  imports: [
    CommonModule,
    topicsRoutingModule,
    ThemeModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    CKEditorModule,
    SpinnerModule,
    ImageSliderModule,
    Ng2CarouselamosModule,
  ],
  providers: [],
})
export class TopicsModule {}
