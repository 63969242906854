import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubjectAccessRequestService } from '../../../services/subject-access-request.service';
import { SubjectAccessRequest } from '../../../core/models/class/subject-access-request';

import * as $ from 'jquery';
import { UpdateModeTypes } from '../../../core/models/types';
import { normalizedPDF, toArabicDate } from '../../../core';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import Backend from '../../../core/backend';
@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<SubjectAccessRequest> implements OnInit {
  modeInput = UpdateModeTypes.MODE_SUBJECT_ACCESS_REQUEST;
  baseUrl = Backend.baseUrl;
  isFilePDF: boolean = true;
  isFileSizePassed: boolean = false;
  alertOnFileType = 'من فضلك ادخل الملفات من نوع PDF فقط';
  newFile = false;

  constructor(
    public route: ActivatedRoute,
    protected router: Router,
    protected _toastLuncher: ToastLuncherService,
    private _subjectAccessRequestService: SubjectAccessRequestService,
  ) {
    super('id', _subjectAccessRequestService.prefix, _toastLuncher, _subjectAccessRequestService, router, route);
    this.item = new SubjectAccessRequest();
  }

  ngOnInit() {}

  protected normalizeAfterGetItem(item) {
    item.created_at = toArabicDate(item.created_at);
    return item;
  }

  openSelectFileDialog() {
    document.getElementById('input_file').click();
  }

  onFileChange(fileInput) {
    const reader2 = new FileReader();
    reader2.onload = (e) => {
      this.item.attachment = normalizedPDF(reader2.result);
      this.submit();
    };
    if (fileInput.target.files[0].type.match('application/pdf') && fileInput.target.files[0].size < 5300000 * 2) {
      this.newFile = true;
      this.isFilePDF = true;
      this.isFileSizePassed = false;
      reader2.readAsDataURL(fileInput.target.files[0]);
    } else if (!fileInput.target.files[0].type.match('application/pdf')) {
      this.isFilePDF = false;
      this.isFileSizePassed = false;
      throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = true;
      throw new Error('حصل خطأ في العملية، صورة خير قابلة للتحميل');
    }
  }
}
