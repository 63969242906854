import { Component, OnInit } from '@angular/core';
import { defaultAnimation } from '../../core';

@Component({
  selector: 'ngx-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  ...defaultAnimation,
})
export class LoadingSpinnerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
