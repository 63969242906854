import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { GenericEditWithImage } from '../../core/models/class/GenericEditFeature';
import { Municipality } from '../../core/models/class/municipality';
import { ToastLuncherService } from '../../core/services/toast-luncher.nb.service';
import { MunisFacade } from '../../core/store';
import { MunicipalityService } from '../../services/municipality.service';

@Component({
  selector: 'ngx-edit-municiaplity',
  templateUrl: './edit-municipality.component.html',
  styleUrls: ['./edit-municipality.component.css'],
})
export class EditMunicipalityComponent extends GenericEditWithImage<Municipality> implements OnInit {
  item = new Municipality();
  didChangedLogo: boolean = false;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected _toastLuncher: ToastLuncherService,
    protected _municipalityService: MunicipalityService,
    protected imageCompress: NgxImageCompressService,
  ) {
    super('id', _municipalityService.prefix, _toastLuncher, _municipalityService, router, route, imageCompress);
    // PREVENT ROUTING TO ACCESS OTHER MUNICIPALITITES.
    if (this.item_id !== MunisFacade.getMunicipalityId()) this.onSubmitSuccess();
  }

  ngOnInit(): void {}

  protected beforeSubmit(item: Municipality): Municipality {
    if (!this.didChangedLogo) delete item.logo;
    return item;
  }

  normalizeAfterGetItem(item) {
    if (item?.logo.length) this.hasImage = true;
    return item;
  }

  onSubmitSuccess() {
    this.router.navigate([`/pages/dashboard`]);
  }

  onImageLoad($event, compressedImage, ...args) {
    this.didChangedLogo = true;
    this.item.logo = compressedImage;
  }
}
