import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListeComponent } from './liste/liste.component';
import { EditComponent } from './edit/edit.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { NewsRoutingModule } from './news-routing.module';
import { NewsComponent } from './news.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { CommitteeService } from '../../services/committee.service';
import { NewsService } from '../../services/news.service';
import { SpinnerModule } from '../../components/loading-spinner/spinner.module';
import { ImageSliderModule } from '../../components/image-slider/image-slider.module';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { CreateComponent } from './create/create.component';
import { ShareSingleViewModule } from '../../components/share-single-view/share-single-view.module';
@NgModule({
  declarations: [ListeComponent, EditComponent, NewsComponent, CreateComponent],
  imports: [
    CommonModule,
    NewsRoutingModule,
    ThemeModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    CKEditorModule,
    SpinnerModule,
    ImageSliderModule,
    Ng2CarouselamosModule,
    ShareSingleViewModule,
    NbSpinnerModule,
  ],
  providers: [CommitteeService, NewsService],
})
export class NewsModule {}
