import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import './ckeditor.loader';
import 'ckeditor';
import { CommitteeService } from '../../../services/committee.service';
import { Committee } from '../../../core/models/class/committee';
import { GenericEditFeature } from '../../../core/models/class/GenericEditFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';

@Component({
  selector: 'ngx-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends GenericEditFeature<Committee> implements OnInit {
  item = new Committee();

  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected route: ActivatedRoute,
    public _committeeService: CommitteeService,
  ) {
    super('id', _committeeService.prefix, _toastLuncher, _committeeService, router, route);
  }

  ngOnInit() {}
}
