import { NgModule } from '@angular/core';
import { OurCommonModule } from '../../core/OurCommon.module';

import { LoadingSpinnerComponent } from './loading-spinner.component';
@NgModule({
  declarations: [LoadingSpinnerComponent],
  imports: [OurCommonModule],
  exports: [LoadingSpinnerComponent],
  providers: [],
})
export class SpinnerModule {}
