import { GenericCRUDService } from '../core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Event } from '../core/models/class/event';
import { Committee } from '../core/models/class/committee';
@Injectable({
  providedIn: 'root',
})
export class EventsService extends GenericCRUDService<Event> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/events';
  }
}
