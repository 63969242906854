import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../../core/auth/auth-guard.service.nb';
import { JwtHelperService } from '@auth0/angular-jwt';

import { isValidPhoneNumber, setItem, slideAnimation, StorageItem } from '../../core';
import { ManagerService } from '../../core/services/manager.service';
import { ToastLuncherService } from '../../core/services/toast-luncher.nb.service';
import { ManagerFacade } from '../../core/store';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  ...slideAnimation,
})
export class ResetPasswordComponent implements OnInit {
  isLoading: boolean = false;
  didSendOTP: boolean = false;

  phone_number: string = '';
  new_password: string = '';
  new_passwordConfirm: string = '';

  OTP: number;

  constructor(
    public router: Router,
    public _toastLuncher: ToastLuncherService,
    public guard: AuthGuardService,
    public jwtHelper: JwtHelperService,
    public _managerService: ManagerService,
    public _managerFacade: ManagerFacade,
  ) {
    this._managerFacade.logout();
  }

  ngOnInit(): void {}

  sendOTP() {
    this.isLoading = true;
    if (isValidPhoneNumber(this.phone_number))
      this._managerService.getResetPasswordOTP(this.phone_number).subscribe((res) => {
        this.didSendOTP = true;
        this.isLoading = false;
        this.onSubmitSuccess('يرجى تقديم الرقم السري المرسل إلى هاتفك');
      });
    else throw new Error('صيغة رقم الهاتف غير صالحة');
  }

  verifyOTP() {
    if (this.new_password === this.new_passwordConfirm) {
      this.isLoading = true;
      // WORKAROUND
      // OTP verify currently takes a couple of tries to work properly
      // today: Tuesday, 25 October 2022
      for (let i = 0; i < 5; i++) {
        this._managerService.verifyResetPasswordOTP(this.phone_number, this.OTP).subscribe((res) => {
          setItem(StorageItem.token, res.access);
          setItem(StorageItem.connected_user_id, res.connected_user_id.toString());
          setItem(StorageItem.municipality, res.municipality_id.toString());
          this._managerService.resetPasswordManager({ new_password: this.new_password }).subscribe(
            () => {
              this.isLoading = false;
              this.onSubmitSuccess('تم تحديث كلمة المرور');
              this.router.navigate(['/login']);
            },
            () => {
              this.isLoading = false;
              throw new Error('حدث خطأ ، يرجى المحاولة مرة أخرى في وقت لاحق');
            },
          );
        });
      }
    } else throw new Error('كلمة المرور الجديدة غير متطابقة');
  }

  onSubmitSuccess(msg: string) {
    this._toastLuncher.success(msg);
  }
}
