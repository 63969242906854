import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { SubjectAccessRequestService } from '../../../services/subject-access-request.service';
import { NbLayoutScrollService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import tableSettings from './list.config';

import * as $ from 'jquery';
import { ItemIsOpenForProcessing, subjectAccessRemainingDays } from '../../../core';
import { SubjectAccessRequest } from '../../../core/models/class/subject-access-request';
import { GenericListFeatureWithStatus } from '../../../core/models/class/GenericListFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.scss'],
})
export class ListeComponent extends GenericListFeatureWithStatus<SubjectAccessRequest> implements OnInit {
  constructor(
    public router: Router,
    public datePipe: DatePipe,
    protected scroll: NbLayoutScrollService,
    protected _toastLuncher: ToastLuncherService,
    private subjectAccessRequestService: SubjectAccessRequestService,
    private modalService: NgbModal,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(
      subjectAccessRequestService.prefix,
      scroll,
      subjectAccessRequestService,
      router,
      _toastLuncher,
      activatedRoute,
    );
  }

  filteredData;
  filterClosed = false;
  settings = tableSettings;
  status = [];
  statusList = GeneralService.filterLabels;
  startDate: string | boolean = '';
  endDate: string | boolean = '';
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'value',
    textField: 'title',
    selectAllText: 'الكل',
    searchPlaceholderText: 'البحث',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  ngOnInit() {
    this.settings.columns.status.filter.config.list = GeneralService.filterLabels;
  }

  exportReport() {
    let status: string = '';
    if (this.status.length) status = this.status.map((s) => s.value).join();

    if (!this.startDate || this.startDate === true) this.startDate = '';
    if (!this.endDate || this.endDate === true) this.endDate = '';

    this._service.getReport(this.startDate, this.endDate, status);
    this.modalService.dismissAll();
    this.startDate = '';
    this.endDate = '';
  }

  openModal(modal) {
    this.modalService.open(modal, { size: 'md' });
  }

  preSavingList(data: SubjectAccessRequest[]) {
    data = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
    data.forEach((item) => {
      item.status = item.updates[0].status;
      item.format = '';
      if (item.on_spot_document) item.format = item.format + 'الإطلاع على الوثيقة على عين المكان،  ';
      if (item.printed_document) item.format = item.format + 'الحصول على نسخة ورقية، ';
      if (item.electronic_document) item.format = item.format + 'الحصول على نسخة ورقية، ';
      if (item.parts_of_document) item.format = item.format + 'الحصول على مقتطفات من المعلومة، ';
      item.format.substr(0, item.format.length - 2);
      item.created_at = this.datePipe.transform(item.created_at, 'yyyy-MM-dd');
      item.lastTrait = this.datePipe.transform(item.updates[0].date, 'yyyy-MM-dd');
      item.daysRemaining = subjectAccessRemainingDays(new Date(item.created_at));
    });
    return data;
  }

  handleFilterClosedItems(event) {
    if (this.filterClosed === true) {
      this.filteredData = this.data.filter(ItemIsOpenForProcessing);
      this.source.load(this.filteredData).then(() => {
        setTimeout(() => {
          $('.ng2-smart-action-edit-edit').on('mouseup', function (e) {
            if (!e.ctrlKey && e.which !== 1) {
              e.target.click();
            }
            e.preventDefault();
          });
        }, 500);
      });
    } else {
      this.source.load(this.data).then(() => {
        setTimeout(() => {
          $('.ng2-smart-action-edit-edit').on('mouseup', function (e) {
            if (!e.ctrlKey && e.which !== 1) {
              e.target.click();
            }
            e.preventDefault();
          });
        }, 500);
      });
    }
  }
}
