import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { defaultAnimation, getItem, isValidPhoneNumber, StorageItem, validateEmail } from '../../../core';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { Manager } from '../../../core/models/class/manager';
import { ManagersService } from '../../../services/managers.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
  ...defaultAnimation,
})
export class CreateComponent extends GenericCreateFeature<Manager> implements OnInit {
  item = new Manager();
  complaint_categories: string[] = getItem(StorageItem.complaint_categories)?.split(',');
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: true,
    idField: 'id',
    textField: 'title',
    selectAllText: 'الكل',
    searchPlaceholderText: 'البحث',
    unSelectAllText: 'الكل',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  constructor(
    public datePipe: DatePipe,
    private _managersService: ManagersService,
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
  ) {
    super(_managersService.prefix, _toastLuncher, _managersService, router);
  }

  ngOnInit() {}

  formIsValid(): boolean {
    const permissions =
      this.item.manage_committees ||
      this.item.manage_forum ||
      this.item.manage_polls ||
      this.item.manage_complaints ||
      this.item.manage_events ||
      this.item.manage_news ||
      this.item.manage_procedures ||
      this.item.manage_reports ||
      this.item.manage_subject_access_requests ||
      this.item.manage_permissions ||
      this.item.manage_eticket ||
      this.item.manage_dossiers;

    if (!this.item.phone_number || !isValidPhoneNumber(this.item.phone_number))
      throw new Error(' الرجاء إدخال رقم هاتف صحيح ');

    if (!validateEmail(this.item.email)) throw new Error('ارجاء إدخال بريد إلكتروني صالح');

    if (!this.item.title && !this.item.name) throw new Error('ارجاء إدخال إسم و صفة');
    if (!permissions) throw new Error('ارجاء منح صلاحية واحدة على أقل');

    return true;
  }

  onManagePermissionsClick() {
    if (this.item.manage_permissions) {
      if (window.confirm('هل تريد فعلا اعطاء حقوق التصرف في كل المتصرفين في المنصة ؟\n؟')) {
        this.item.manage_permissions = true;
      } else {
        this.item.manage_permissions = false;
      }
    }
  }
}
