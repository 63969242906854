// @ts-nocheck
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromMunis from './municipality/municipality.reducer';
import * as fromUi from './ui/ui.reducer';
import * as fromUser from './user/user.reducer';
export interface AppState {
  municipalities: fromMunis.MunisState;
  ui: fromUi.UiState;
  user: fromUser.UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  municipalities: fromMunis.munisReducer,
  ui: fromUi.UiReducer,
  user: fromUser.UserReducer,
};

// left selectors here so they can be composed if needed

// ------------------
// SELECTORS for select municipality / municipalities list
// ------------------
export const selectMunisState = createFeatureSelector<fromMunis.MunisState>('municipalities');

export const selectedMuni = createSelector(selectMunisState, (selectMunisState) => selectMunisState.selectedMuni);

export const selectAllMunis = createSelector(selectMunisState, (selectMunisState) => selectMunisState.munisList);

export const getLoadingStatus = createSelector(selectMunisState, (selectMunisState) => selectMunisState.isLoading);

// ------------------
// SELECTORS for ui
// ------------------
export const selectUiState = createFeatureSelector<fromUi.UiState>('ui');

export const selectedfeatureIsLoading = createSelector(
  selectUiState,
  (selectUiState) => selectUiState.featureIsLoading,
);

// ------------------
// SELECTORS for user
// ------------------

export const selectUser = createFeatureSelector<fromUser.UserState>('user');

export const selectedAuth = createSelector(selectUser, (selectUser) => selectUser.authData);

export const selectLoggedInUser = createSelector(selectUser, (selectUser) => selectUser.loggedIn);

export const selectUserIsloading = createSelector(selectUser, (selectUser) => selectUser.isLoading);
