import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDataComponent } from './account-data.component';
import { AccountDataRoutingModule } from './account-data-routing.module';
import { EditComponent } from './edit/edit.component';
import { ThemeModule } from '../../@theme/theme.module';
import {
  NbTreeGridModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbUserModule,
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { HistoriqueModule } from '../../components/historique/historique.module';
@NgModule({
  declarations: [AccountDataComponent, EditComponent],
  imports: [
    CommonModule,
    AccountDataRoutingModule,
    ThemeModule,
    NbTreeGridModule,
    Ng2SmartTableModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    FormsModule,
    HistoriqueModule,
  ],
})
export class AccountDataModule {}
