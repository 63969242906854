import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { UpdateTypes } from '../models/types';

@Pipe({
  name: 'updateTypeToArabic',
})
@Injectable({
  providedIn: 'root',
})
export class UpdateTypeToArabicPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case UpdateTypes.NOT_CLEAR:
        return 'غير واضح';
      case UpdateTypes.INVALID:
        return 'خارج نطاق عمل البلدية';
      case UpdateTypes.PROCESSING:
        return 'بصدد المعالجة';
      case UpdateTypes.REJECTED:
        return 'تعذر على البلدية الموافقة';
      case UpdateTypes.ACCEPTED:
        return 'تم الموافقة';
      // defaults to RECEIVED
      default:
        return 'تم الاستقبال';
    }
  }
}
