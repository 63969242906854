import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Agency } from '../core/models/class/agency';
import { BaseService } from '../core/services/base.service';

import { GenericCRUDService } from '../core/services/generic.service';
@Injectable({
  providedIn: 'root',
})
export class AgenciesService extends GenericCRUDService<Agency> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/agencies';
    this.suffix = '/';
  }

  public verify(agency_id: string, slug: string): Observable<boolean> {
    return (
      this.http.get(this.getUrl() + `/${agency_id}/reservations/${slug}/verify` + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<boolean>
    ).pipe(first());
  }
}
