import { IfeatureItem } from '../interface/IfeatureItem';
import { TopicStatesTypes } from '../types';

export class Topic implements IfeatureItem {
  id: number;
  label: string;
  state: TopicStatesTypes;
  description: string;
  created_at: string;
  municipality: number;
}
