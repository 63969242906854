import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule, NbCheckboxModule, NbInputModule, NbSelectModule } from '@nebular/theme';
import { UpdateOnCreationComponent } from './update-on-creation.component';
import { OurCommonModule } from '../../core';

@NgModule({
  declarations: [UpdateOnCreationComponent],
  imports: [
    CommonModule,
    NbButtonModule,
    NbCardModule,
    NbSelectModule,
    NbInputModule,
    NbCheckboxModule,
    OurCommonModule,
  ],
  exports: [UpdateOnCreationComponent],
})
export class UpdateOnCreationModule {}
